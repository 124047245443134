export const PAYMENT_SUCCESS = 'Оплата прошла успешно!';
export const CONFIRMATION_SUCCESS = 'E-mail успешно подтверждён!';
export const PAYMENT_WAITING = 'Платёж обрабатывается';
export const UNKNOWN_ERROR = 'Произошла непредвиденная ошибка';
export const PAYMENT_CANCELED = 'Ваш платёж был отменён';
export const SCORING_SUCCESS = 'Запрос на скоринг отправлен!';
export const SCROTING_RESULT_WILL_BE_SEND = 'Отчет по скорингу будет выслан на указанный E-mail';
export const FINE = 'Отлично';
export const EMAIL_CONFIRMATION_WRONG_CODE = 'Неверный код подтверждения';
export const EMAIL_CONFIRMATION_NOT_FOUND = 'E-mail не найден.';
export const USER_NOT_FOUND = 'Пользователь не найден.';
export const EMAIL_ERROR = 'Произошла ошибка. Запросите письмо повторно.';
export const RECOVERY_SENDED = 'Ссылка на восстановление пароля выслана на эл. почту';
export const NEW_PASSWORD_SETTED = 'Новый пароль успешно задан';
