import { observable, decorate, action } from 'mobx';
import api from '../api';
import logger from '../logger';

export default class InquiriesStore {
  constructor(context, dialog) {
    this.context = context;
    this.dialog = dialog;
  }

  signUpForm = {};

  isBusy = false;

  isBusyInquiries = false;

  errors = null;

  inquiries = [];

  hasMoreItems = true;

  deleteInquiries() {
    this.inquiries = [];
  }

  updateInquire(idx, payload) {
    this.inquiries = [
      ...this.inquiries.map(inq => (inq.id === idx ? { ...inq, ...payload } : inq)),
    ];
  }

  updateInquireReports(idx, payload) {
    this.inquiries = [
      ...this.inquiries.map(inq =>
        inq.id === idx
          ? {
              ...inq,
              discretion: payload,
            }
          : inq,
      ),
    ];
  }

  setInquiriesStore(state) {
    this.signUpForm = { ...this.signUpForm, ...state };
  }

  fetchInquiries = async ({ limit = 15, offset = 0 }, token) => {
    if (this.isBusyInquiries) {
      return this.inquiries;
    }
    this.isBusyInquiries = true;
    try {
      const response = await api.inquiriesCombined.getInquiriesDiscretionList(
        { limit, offset },
        token,
      );
      if (!response.message || !response.code) {
        if (response.result) {
          const newResult = await Promise.all(
            response.result.map(async item => {
              if (!item.entity || !item.entity.local) {
                const newEntity = await this.context.EntityStore.fetchEntity(item.initialCode1);
                return {
                  ...item,
                  entity: {
                    local: {
                      ru: {
                        инн:
                          !newEntity ||
                          !newEntity.local ||
                          !newEntity.local.ru ||
                          !newEntity.local.ru['инн']
                            ? ''
                            : newEntity.local.ru['инн'],
                        огрн:
                          !newEntity ||
                          !newEntity.local ||
                          !newEntity.local.ru ||
                          !newEntity.local.ru['огрн']
                            ? ''
                            : newEntity.local.ru['огрн'],
                      },
                    },
                    name: {
                      local: {
                        full:
                          (!newEntity ||
                          !newEntity.name ||
                          !newEntity.name.local ||
                          !newEntity.name.local.full
                            ? ''
                            : newEntity.name.local.full) ||
                          (!item ||
                          !item.entity ||
                          !item.entity.name ||
                          !item.entity.name.local ||
                          !item.entity.name.local.full
                            ? ''
                            : item.entity.name.local.full),
                        short:
                          (!newEntity ||
                          !newEntity.name ||
                          !newEntity.name.local ||
                          !newEntity.name.local.short
                            ? ''
                            : newEntity.name.local.short) ||
                          (!item ||
                          !item.entity ||
                          !item.entity.name ||
                          !item.entity.name.local ||
                          !item.entity.name.local.short
                            ? ''
                            : item.entity.name.local.short),
                      },
                    },
                  },
                };
              }
              return item;
            }),
          );
          if (!offset) {
            this.inquiries = [...newResult];
          } else {
            this.inquiries = [...this.inquiries, ...newResult];
          }
          this.hasMoreItems = newResult.length >= limit;
        }
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusyInquiries = false;
    }
    return this.inquiries;
  };

  signUp = async payload => {
    this.isBusy = true;
    this.errors = null;
    const data = [
      {
        codeType: 'ogrn',
        code: payload.ogrn,
        service: 'SCORING',
        email: `creport@rescore.online;${payload.email}`,
      },
    ];
    try {
      const response = await api.inquiries.getInquiries(
        data,
        this.context.AccountStore.accountValue.rescore_account_token,
      );
      if (response.alerts[0].message || response.code || response.code !== 200) {
        this.errors = response.alerts[0].message || response.code;
        throw new Error(response.alerts[0].message || response.code);
      } else {
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.errors;
  };
}

decorate(InquiriesStore, {
  errors: observable,
  hasMoreItems: observable,
  inquiries: observable,
  isBusy: observable,
  isBusyInquiries: observable,
  signUpForm: observable,
  updateInquire: action.bound,
  updateInquireReports: action.bound,
});
