import { COMMON_ERRORS } from '../constants/errors';

/**
 * Get Error text
 * @param {String} code - text code of error
 */
export const mapErrorsByCode = (code, errs = COMMON_ERRORS) => {
  if (typeof code === 'object') {
    return (
      errs[code[0].constraints.isPhoneNumber] ||
      errs[code[0].constraints.isEmail] ||
      'Произошла непредвиденная ошибка'
    );
  }
  return errs[code] || 'Произошла непредвиденная ошибка';
};
