import React from 'react';
import {
  compose,
  lifecycle,
  withState,
  withProps,
  withStateHandlers,
  withHandlers,
} from 'recompose';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { COLOR_WHITE, COLOR_ACTION_BLUE } from '../../constants/colors';
import Button from '../../components/Buttons/Button';
import history from '../../history';
import storeNames from '../../stores/storeNames';
import { getEntityStatus } from '../../utils/entityHelper';
import InputField from '../../components/Inputs/InputField';
import {
  checkIsEmailValid,
  hasValue,
  getEmailEqualMessage,
  toLowerCaseAndTrim,
} from '../../utils/validators';
import { withBoolState, withHandlePressEnter } from '../../utils/hocs';
import {
  Main,
  Wrapper,
  WrapperContainer,
  WrrapperBox,
  TitlePage,
  Paragraph,
  Card,
  Field,
  Value,
  Key,
  ButtonWrapper,
  AfterCardContainer,
  AfterCard,
  AfterCardHint,
  BottomCard,
  Text,
  TextLink,
  CheckBoxContrainer,
  ButtonWrapperWithError,
  ErrorTextTransformed,
} from './RescoreResponseStyled';
import CheckBox from '../../components/CheckBoxes/CheckBox';
import { getUrlObject } from '../../helpers/url';

const RescoreResponse = ({
  entity,
  isBusy,
  email,
  emailAgain,
  setEmail,
  setEmailAgain,
  emailValidation,
  emailAgainValidation,
  formHandler,
  succeededRescore,
  toggleSucceededRescore,
  confirmed,
  toggleConfirmed,
  handlePressEnter,
}) => {
  if (!entity || isBusy) {
    return <Main />;
  }
  const companyData = entity.local && entity.local.ru;

  return (
    <Main>
      <Wrapper>
        <WrapperContainer>
          <WrrapperBox>
            <TitlePage>Проверка компании с помощью искусственного интеллекта</TitlePage>
            <Paragraph>Это компания, по которой вы хотите получить отчет:</Paragraph>
            <Card>
              <Field>
                <Key>Полное юридическое наименование:</Key>
                <Value> {entity.description}</Value>
              </Field>
              {/* <Field>
                <Key>Руководитель:</Key>
                <Value>ИСПОЛНИТЕЛЬНЫЙ ДИРЕКТОР КИСЕЛЕВ МИХАИЛ ПАВЛОВИЧ</Value>
              </Field> */}
              {companyData && (
                <>
                  <Field>
                    <Key>ИНН / КПП:</Key>
                    <Value>
                      {companyData['инн']} / {companyData['кпп']}
                    </Value>
                  </Field>
                  <Field>
                    <Key>ОГРН:</Key>
                    <Value>{companyData['огрн']}</Value>
                  </Field>
                </>
              )}
              {entity.registration && (
                <Field>
                  <Key>Дата регистрации:</Key>
                  <Value>{moment(entity.registration.date).format('DD.MM.YYYY')}</Value>
                </Field>
              )}
              {entity.status && (
                <Field>
                  <Key>Статус:</Key>
                  <Value>{getEntityStatus(entity.status)}</Value>
                </Field>
              )}
            </Card>
            {!succeededRescore && (
              <ButtonWrapper>
                <Button
                  maxWidth="calc(50% - 34px)"
                  width="100%"
                  sizes={{ margin: '0 17px' }}
                  textColor={COLOR_ACTION_BLUE}
                  backgroundColor={COLOR_WHITE}
                  onClick={() => history.push('/')}
                >
                  Нет, новый запрос
                </Button>
                <Button
                  maxWidth="calc(50% - 34px)"
                  width="100%"
                  sizes={{ margin: '0 17px' }}
                  onClick={() => toggleSucceededRescore()}
                >
                  Да, всё верно, оплатить
                </Button>
              </ButtonWrapper>
            )}
            {succeededRescore && (
              <>
                <AfterCardContainer>
                  <AfterCard>
                    <InputField
                      placeholder="Эл. почта"
                      value={email}
                      name="email"
                      isValid={emailValidation.isValid}
                      onChange={setEmail}
                      onKeyDown={handlePressEnter}
                    />
                    <InputField
                      placeholder="Адрес эл. почты ещё раз"
                      value={emailAgain}
                      name="emailAgain"
                      errorMessage={emailAgainValidation.message}
                      isValid={emailAgainValidation.isValid}
                      onChange={setEmailAgain}
                      onKeyDown={handlePressEnter}
                    />
                  </AfterCard>
                  <AfterCardHint>
                    Укажите адрес электройнной почты, на которую будет выслан отчет по скорингу
                    данной компании или юрлица.
                  </AfterCardHint>
                </AfterCardContainer>
                <BottomCard>
                  <CheckBoxContrainer>
                    <CheckBox
                      testID="TEST_CHECKBOX"
                      checked={confirmed}
                      onChange={() => toggleConfirmed()}
                    >
                      <Text>
                        Я принимаю{' '}
                        <TextLink testID="TEST_TEXTLINK" to="/terms.pdf" target="_href">
                          условия Политики использования
                        </TextLink>{' '}
                        сайта и даю своё согласие сервису на обработку моих персональных данных, в
                        соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О
                        персональных данных», на условиях и для целей, определенных Политикой
                        оператора по обработке персональных данных
                      </Text>
                    </CheckBox>
                  </CheckBoxContrainer>
                  <ButtonWrapperWithError>
                    <Button
                      maxWidth="308px"
                      width="100%"
                      testID="TEST_BUTTON_PAYMENT"
                      disabled={
                        !emailAgainValidation.isValid || !emailValidation.isValid || !confirmed
                      }
                      changeByDisable
                      onClick={formHandler}
                    >
                      Перейти к оплате
                    </Button>
                    {!confirmed && (
                      <ErrorTextTransformed>Примите условия сервиса</ErrorTextTransformed>
                    )}
                  </ButtonWrapperWithError>
                </BottomCard>
              </>
            )}
          </WrrapperBox>
        </WrapperContainer>
      </Wrapper>
    </Main>
  );
};

RescoreResponse.propTypes = {
  entity: PropTypes.object,
  emailValidation: PropTypes.object,
  emailAgainValidation: PropTypes.object,
  email: PropTypes.string,
  emailAgain: PropTypes.string,
  handlePressEnter: PropTypes.func,
  isBusy: PropTypes.bool.isRequired,
  succeededRescore: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool.isRequired,
  setEmail: PropTypes.func.isRequired,
  setEmailAgain: PropTypes.func.isRequired,
  formHandler: PropTypes.func.isRequired,
  toggleSucceededRescore: PropTypes.func.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
};

RescoreResponse.defaultProps = {
  entity: undefined,
  email: '',
  emailAgain: '',
  emailValidation: {},
  emailAgainValidation: {},
  handlePressEnter: () => {},
};

const enhance = compose(
  inject(storeNames.EntityStore, storeNames.ScoringStore),
  observer,
  withProps(({ EntityStore }) => ({
    isBusy: EntityStore.isBusy,
  })),
  withBoolState('succeededRescore', false),
  withBoolState('confirmed', false),
  withState('entity', 'setEntity', undefined),
  withHandlers(() => ({
    validateEmails: () => ({ email, emailAgain, value }) => {
      const filteredValue = toLowerCaseAndTrim(value);
      const emailIsValid = checkIsEmailValid(filteredValue);
      const message =
        hasValue(emailAgain) &&
        getEmailEqualMessage({
          email,
          emailAgain,
        });
      return { isValid: !message && emailIsValid, message };
    },
  })),
  withStateHandlers(
    ({ validateEmails }) => ({
      email: undefined,
      emailAgain: undefined,
      emailValidation: { isValid: true },
      emailAgainValidation: { isValid: true },
      validateEmails,
    }),
    {
      setEmail: ({ emailAgain, validateEmails }) => value => {
        const filteredValue = toLowerCaseAndTrim(value);
        const filteredEmailAgain = toLowerCaseAndTrim(emailAgain);
        const { message, isValid } = validateEmails({
          email: filteredValue,
          emailAgain: filteredEmailAgain,
          value: filteredValue,
        });
        if (isValid) {
          return {
            email: filteredValue,
            emailAgain: filteredEmailAgain,
            emailValidation: { isValid, message },
            emailAgainValidation: { isValid, message },
          };
        }
        return { email: filteredValue, emailValidation: { isValid, message } };
      },
      setEmailAgain: ({ email, validateEmails }) => value => {
        const filteredValue = toLowerCaseAndTrim(value);
        const filteredEmail = toLowerCaseAndTrim(email);
        const { message, isValid } = validateEmails({
          emailAgain: filteredValue,
          email: filteredEmail,
          value: filteredValue,
        });
        if (isValid) {
          return {
            emailAgain: filteredValue,
            email: filteredEmail,
            emailValidation: { isValid, message },
            emailAgainValidation: { isValid, message },
          };
        }
        return { emailAgain: filteredValue, emailAgainValidation: { isValid, message } };
      },
    },
  ),
  withHandlers(({ ScoringStore, EntityStore }) => ({
    formHandler: ({
      email,
      emailAgain,
      setEmail,
      setEmailAgain,
      emailAgainValidation,
      emailValidation,
      confirmed,
    }) => async () => {
      if (
        !emailAgainValidation.isValid ||
        !emailValidation.isValid ||
        !confirmed ||
        ScoringStore.isBusy
      )
        return false;
      const filteredEmail = toLowerCaseAndTrim(email);
      const filteredEmailAgain = toLowerCaseAndTrim(emailAgain);
      if (!hasValue(filteredEmail) || !hasValue(filteredEmailAgain)) {
        setEmail(undefined);
        setEmailAgain(undefined);
        return false;
      }
      const { entity } = EntityStore;
      const companyData = entity.local && entity.local.ru;
      await ScoringStore.successPayment({ email: filteredEmail, psrn: companyData['огрн'] });
      if (!ScoringStore.confirmationUrl) {
        return false;
      }
      window.location.replace(ScoringStore.confirmationUrl);
      return true;
    },
  })),
  withHandlePressEnter,
  lifecycle({
    async componentDidMount() {
      const { EntityStore, setEntity } = this.props;
      const { entity } = EntityStore;
      if (entity) setEntity(entity);
      if (!entity) {
        const url = getUrlObject();
        const id = url.searchParams.get('data');
        if (id) {
          const result = await EntityStore.fetchEntity(id);
          const { errors } = EntityStore;
          if (errors && errors.length) {
            history.push('/');
          } else {
            setEntity(result);
          }
        } else {
          history.push('/');
        }
      }
    },
  }),
);

export default enhance(RescoreResponse);
