import { API_URL_BILLING, API_URL_LEGACY_CORE, API_URL_LK } from '../constants/env';
import AccountsApi from './accounts';
import AuthApi from './auth';
import BalanceApi from './balance';
import CustomersApi from './customers';
import DisclosureApi from './disclosure';
import DiscretionApi from './discretion';
import EntityApi from './entity';
import InquiriesApi from './inquiries';
import InquiriesCombinedApi from './inquiries-combined';
import InquiryStatusApi from './inquiry-status';
import ProfileApi from './profile';
import ScoringApi from './scoring';
import ServicesApi from './services';
import UsersApi from './users';
import DashboardApi from './dashboard';

const discretion = new DiscretionApi(API_URL_LK);
const inquiries = new InquiriesApi(API_URL_LEGACY_CORE);

export default {
  accounts: new AccountsApi(API_URL_LK),
  auth: new AuthApi(API_URL_LK),
  balance: new BalanceApi(API_URL_LK),
  customers: new CustomersApi(API_URL_LK),
  disclosure: new DisclosureApi(API_URL_LK),
  discretion,
  entity: new EntityApi(API_URL_LEGACY_CORE),
  inquiries,
  inquiriesCombined: new InquiriesCombinedApi(inquiries, discretion),
  inquiryStatus: new InquiryStatusApi(API_URL_LK),
  profile: new ProfileApi(API_URL_LK),
  scoring: new ScoringApi(API_URL_BILLING),
  services: new ServicesApi(API_URL_LK),
  users: new UsersApi(API_URL_LK),
  dashboard: new DashboardApi(API_URL_LK),
};
