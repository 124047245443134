export const COMMON_ERRORS = {
  USER_NOT_FOUND: 'Пользователь не существует',
  WRONG_LOGIN_OR_PASSWORD: 'Неверный логин или пароль',
  'old password is wrong': 'Текущий пароль указан не верно',
  'email must be an email': 'Введите валидный E-mail',
  'phone must be a valid phone number': 'Неверный номер телефона',
  INN_ALREADY_USED: 'ИНН уже занят',
  LOGIN_ALREADY_USED: 'Логин уже занят',
  PHONE_ALREADY_USED: 'Телефон уже занят',
  OGRN_ALREADY_USED: 'ОГРН уже занят',
  KPP_ALREADY_USED: 'КПП уже занят',
  EMAIL_ALREADY_USED: 'E-mail уже занят',
  EMAIL_SEND_ERROR: 'Нужно ввести существующий E-mail',
  EMAIL_ALREADY_CONFIRMED: 'E-mail уже подтверждён',
  PAYMENT_WITHOUT_CONTRACT: 'Оплата без договора',
  PAYMENT_CONTRACT_ALREADY_SUCCEED: 'Договор уже завершен',
  PAYMENT_NOT_FOUND: 'Договор не найден',
  TARIFF_NOT_FOUND: 'Тариф не найден',
};

export const RECOVERY_ERRORS = {
  USER_NOT_FOUND: 'Пользователь с таким E-mail не найден',
  PASSWORD_RESET_ALREADY_SUCCEED: 'Ссылка на изменение пароля устарела',
  SUBSCRIPTIONS_LIMIT_EXCEEDED:
    'Нет доступных скорингов. Перейдите в раздел услуг для их приобретения.',
  SUBSCRIPTION_ALREADY_EXISTS: 'Данная компания уже была поставлена на проверку сегодня.',
};
