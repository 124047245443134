import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers, withState, lifecycle } from 'recompose';
import * as moment from 'moment';
import InputPhone from '../../../../components/Inputs/InputPhone';
import storeNames from '../../../../stores/storeNames';
import Popup from '../../../../components/Popup/Popup';
import InputField from '../../../../components/Inputs/InputField';
import Button from '../../../../components/Buttons/Button';
import {
  hasValue,
  trimValue,
  toLowerCaseAndTrim,
  checkPhoneNumber,
} from '../../../../utils/validators';
import { ICON_SHAPE } from '../../../../constants/icons';
import {
  Title,
  Wrapper,
  FormWrapper,
  InputWrapper,
  TopText,
  DiscText,
  TextWrapper,
  DateInputWrap,
  DateWrapper,
  ButtonWrapper,
  ImgShape,
  DateContent,
} from '../UsersPopupStyled';
import ParagraphError from '../../../../components/Typography/ParagraphError';
import {
  withInputSetStore,
  withSurnameState,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withDateFromState,
  withDateToState,
  withNewPasswordState,
  withConfirmPasswordState,
  withLoginState,
} from '../../../../utils/FormHocs';
import { getAccessStatus } from '../../../../helpers/roles';
import { RoleRender } from '../../../../components/RoleComponents';
import { withUserData, withPasswordChangeType, withAccounts } from '../../../../utils/hocs';
import { COLOR_ACTION_BLUE } from '../../../../constants/colors';
import Paragraph from '../../../../components/Typography/Paragraph';
import Select from '../../../../components/Select/Select';
import {
  CUSTOMER_ROLES,
  CUSTOMER_USER,
  SUPER_ADMIN_ROLE,
  SUPER_ADMINS_ROLES,
  CUSTOMER_ADMIN_ROLE,
} from '../../../../constants/roles';
import { merge2RoleArrays } from '../../../../helpers/array';
import InputDate from '../../../../components/Inputs/InputDate';
import { InputColumn } from '../../../Registration/RegistrationUsers/RegistrationUsersStyled';

const EditUserPopup = ({
  nameValid,
  name,
  nameError,
  inputHandlerName,
  dateFrom,
  dateTo,
  email,
  inputHandlerEmail,
  emailError,
  emailValid,
  phoneValid,
  phone,
  inputHandlerPhone,
  phoneError,
  patronymic,
  inputHandlerPatronymic,
  surname,
  inputHandlerSurname,
  inputSetStore,
  toggleEditUserPopup,
  roleSelectValue,
  setRoleSelectValue,
  setPersonalAccountValue,
  personalAccountValue,
  formHandler,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  personalAccountValueError,
  dateValueError,
  newPassword,
  confirmPassword,
  showNewPassword,
  inputHandlerNewPassword,
  newPasswordError,
  confirmPasswordError,
  toggleNewPasswordType,
  toggleConfirmPasswordType,
  inputHandlerConfirmPassword,
  showConfirmPassword,
  accounts,
  login,
  loginValid,
  inputHandlerLogin,
  loginError,
  userRoles,
  userData,
}) => (
  <Popup closePopup={toggleEditUserPopup}>
    <Wrapper>
      <Title as="h2">Редактирование пользователя</Title>
      <Paragraph fontSize="14px" textColor="#334D6E" textAlign="center">
        На указанный E-mail будет выслано приглашение с логином и паролем.
      </Paragraph>
      <FormWrapper onSubmit={formHandler}>
        <InputColumn>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="1"
              placeholder="Фамилия"
              value={surname}
              name="sname"
              onChange={value => {
                inputSetStore(value, inputHandlerSurname, 'sname');
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="2"
              placeholder="Имя"
              type="text"
              name="fname"
              value={name}
              isValid={nameValid}
              onChange={value => {
                inputSetStore(value, inputHandlerName, 'fname');
              }}
            />
            <ParagraphError>{nameError}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="3"
              placeholder="Отчество"
              name="mname"
              value={patronymic}
              onChange={value => {
                inputSetStore(value, inputHandlerPatronymic, 'mname');
              }}
            />
          </InputWrapper>

          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="4"
              placeholder="E-mail"
              name="email"
              value={email}
              isValid={emailValid}
              onChange={value => {
                inputSetStore(value, inputHandlerEmail, 'email');
              }}
            />
            <ParagraphError>{emailError}</ParagraphError>
          </InputWrapper>

          <InputWrapper>
            <InputPhone
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="5"
              placeholder="Телефон"
              name="phone"
              value={phone}
              isValid={phoneValid}
              onChange={value => {
                inputSetStore(value, inputHandlerPhone, 'phone');
              }}
            />
            <ParagraphError>{phoneError}</ParagraphError>
          </InputWrapper>
          <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE]}>
            <InputWrapper>
              <Select
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="6"
                multiply
                value={roleSelectValue}
                setValue={setRoleSelectValue}
                placeholder="Выберите роль"
                name="roleSelectValue"
                options={
                  userData.customer_id === 0 &&
                  getAccessStatus({
                    userRoles,
                    allowedRoles: [CUSTOMER_ADMIN_ROLE],
                  })
                    ? SUPER_ADMINS_ROLES
                    : CUSTOMER_ROLES
                }
              />
            </InputWrapper>
          </RoleRender>
          <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE]}>
            <InputWrapper>
              <Select
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex="7"
                value={personalAccountValue}
                setValue={setPersonalAccountValue}
                placeholder="Выберите лицевой счет"
                name="personalAccountValue"
                options={accounts && accounts}
              />
              <ParagraphError>{personalAccountValueError}</ParagraphError>
            </InputWrapper>
          </RoleRender>
        </InputColumn>
        <InputColumn>
          <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE]}>
            <TextWrapper>
              <TopText>Период действия пользователя</TopText>
              <DiscText>(необязательно)</DiscText>
            </TextWrapper>
            <DateWrapper>
              <DateInputWrap>
                <DateContent>
                  <InputDate
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="8"
                    name="dateFrom"
                    value={dateFrom}
                    onChange={value => {
                      inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                    }}
                  />
                </DateContent>
                —
                <DateContent>
                  <InputDate
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex="9"
                    name="dateTo"
                    value={dateTo}
                    onChange={value => {
                      inputSetStore(value, inputHandlerDateTo, 'dateTo');
                    }}
                  />
                </DateContent>
              </DateInputWrap>
              <ParagraphError>{dateValueError}</ParagraphError>
            </DateWrapper>
          </RoleRender>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="10"
              placeholder="Логин"
              name="login"
              value={login}
              isValid={loginValid}
              onChange={value => {
                inputSetStore(value, inputHandlerLogin, 'login');
              }}
            />
            <ParagraphError>{loginError}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="11"
              placeholder="Новый пароль"
              value={newPassword}
              type={showNewPassword}
              name="confirmPassword"
              onChange={value => {
                inputSetStore(value, inputHandlerNewPassword, 'newPassword');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} value={newPassword} />
            <ParagraphError>{newPasswordError}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="12"
              placeholder="Новый пароль еще раз"
              value={confirmPassword}
              type={showConfirmPassword}
              name="confirmPassword"
              onChange={value => {
                inputSetStore(value, inputHandlerConfirmPassword, 'confirmPassword');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
            <ParagraphError>{confirmPasswordError}</ParagraphError>
          </InputWrapper>
        </InputColumn>
        <ButtonWrapper>
          <Button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="12"
            width="303px"
            testID="TEST_BUTTON_SAVE"
            type="submit"
            sizes={{ margin: '0 20px' }}
          >
            Сохранить
          </Button>
          <Button
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="13"
            width="303px"
            testID="TEST_BUTTON_CANCEL"
            textColor={COLOR_ACTION_BLUE}
            boxShadow="none"
            backgroundColor="#fff"
            sizes={{ margin: '0 20px' }}
            onClick={() => toggleEditUserPopup()}
          >
            Отмена
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  </Popup>
);

EditUserPopup.propTypes = {
  toggleEditUserPopup: PropTypes.func.isRequired,
  roleSelectValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  personalAccountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmPassword: PropTypes.string,
  setRoleSelectValue: PropTypes.func.isRequired,
  setPersonalAccountValue: PropTypes.func.isRequired,
  inputSetStore: PropTypes.func,
  surname: PropTypes.string,
  newPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  newPassword: PropTypes.string,
  inputHandlerSurname: PropTypes.func,
  formHandler: PropTypes.func,
  name: PropTypes.string,
  nameValid: PropTypes.bool,
  nameError: PropTypes.string,
  showNewPassword: PropTypes.string,
  email: PropTypes.string,
  inputHandlerName: PropTypes.func,
  inputHandlerEmail: PropTypes.func,
  emailError: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  emailValid: PropTypes.bool,
  phone: PropTypes.string,
  phoneValid: PropTypes.bool,
  inputHandlerPhone: PropTypes.func,
  patronymic: PropTypes.string,
  phoneError: PropTypes.string,
  inputHandlerPatronymic: PropTypes.func,
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  userRoles: PropTypes.array,
  inputHandlerDateFrom: PropTypes.func,
  personalAccountValueError: PropTypes.string,
  dateValueError: PropTypes.string,
  inputHandlerDateTo: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
  accounts: PropTypes.array,
  login: PropTypes.string,
  loginValid: PropTypes.bool,
  inputHandlerLogin: PropTypes.func,
  loginError: PropTypes.string,
  userData: PropTypes.object,
};

EditUserPopup.defaultProps = {
  surname: '',
  dateTo: undefined,
  name: '',
  confirmPassword: '',
  nameError: '',
  email: '',
  personalAccountValueError: '',
  newPasswordError: '',
  confirmPasswordError: '',
  emailError: '',
  showNewPassword: '',
  login: '',
  loginError: '',
  showConfirmPassword: '',
  patronymic: '',
  phoneError: '',
  roleSelectValue: '',
  dateValueError: '',
  newPassword: '',
  dateFrom: undefined,
  phone: '',
  nameValid: false,
  emailValid: false,
  loginValid: false,
  phoneValid: false,
  userData: {},
  accounts: [],
  userRoles: [],
  personalAccountValue: [],
  inputSetStore: () => {},
  inputHandlerName: () => {},
  formHandler: () => {},
  inputHandlerEmail: () => {},
  inputHandlerSurname: () => {},
  inputHandlerPhone: () => {},
  inputHandlerPatronymic: () => {},
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
  inputHandlerNewPassword: () => {},
  inputHandlerConfirmPassword: () => {},
  toggleNewPasswordType: () => {},
  toggleConfirmPasswordType: () => {},
  inputHandlerLogin: () => {},
};

const enchance = compose(
  inject(
    storeNames.UserStore,
    storeNames.AccountStore,
    storeNames.ProfileStore,
    storeNames.IndicatorsStore,
    storeNames.ServicesStore,
  ),
  withUserData,
  observer,
  withSurnameState,
  withInputSetStore,
  withPhoneState,
  withPatronymicState,
  withNameState,
  withEmailState,
  withDateFromState,
  withDateToState,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withLoginState,
  withAccounts,
  withUserData,
  withState('roleSelectValueError', 'setRoleSelectValueError', ''),
  withState('personalAccountValueError', 'setPersonalAccountValueError', ''),
  withState('dateValueError', 'setDateValueError', ''),
  withState('dateValid', 'setDateValid', true),
  withState('newPasswordError', 'setNewPasswordError', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  withHandlers(({ dateValid, dateValueError, phoneError }) => ({
    formHandler: ({
      ServicesStore,
      ProfileStore,
      userData,
      surname,
      name,
      personalAccountValue,
      IndicatorsStore,
      AccountStore,
      roleSelectValue,
      email,
      phone,
      patronymic,
      UserStore,
      toggleEditUserPopup,
      setRoleSelectValueError,
      setRoleSelectValue,
      setPersonalAccountValue,
      dateFrom,
      dateTo,
      setDateValueError,
      setDateValid,
      emailValid,
      id,
      login,
      confirmPassword,
      newPassword,
      setNewPasswordError,
      setConfirmPasswordError,
      setPersonalAccountValueError,
      setNameError,
      setEmailError,
      accounts,
      setOneOffset,
      phoneValid,
    }) => async e => {
      e.preventDefault();
      // ВАЛИДАЦИЯ ПАРОЛЯ //
      let passwordIsValid = true;
      let accountError = false;
      const regexValid = /^[a-zA-Z0-9]+$/;
      if (!newPassword && confirmPassword) {
        passwordIsValid = false;
        setNewPasswordError('Введите пароль');
      } else {
        setNewPasswordError('');
        if (newPassword && newPassword.length < 8) {
          passwordIsValid = false;
          setNewPasswordError('Пароль должен быть не меньше 8 символов');
        } else {
          setNewPasswordError('');
          if (!regexValid.test(newPassword)) {
            passwordIsValid = false;
            setNewPasswordError('Пароль должен состоять из цифр и символов латинского алфавита');
            setConfirmPasswordError('');
          } else {
            setNewPasswordError('');
            if (newPassword !== confirmPassword) {
              setConfirmPasswordError('Пароли не совпадают');
              passwordIsValid = false;
            } else {
              setConfirmPasswordError('');
              passwordIsValid = true;
            }
          }
        }
      }
      if (!newPassword && !confirmPassword) {
        setConfirmPasswordError('');
        passwordIsValid = true;
      }

      // ВАЛИДАЦИЯ ПАРОЛЯ //

      const dateFromValue = dateFrom && moment(dateFrom).format('YYYY-MM-DD');
      const dateToValue = dateTo && moment(dateTo).format('YYYY-MM-DD');

      const dateFromSec = new Date(dateFrom).getTime();
      const dateToSec = new Date(dateTo).getTime();

      if ((dateFrom && !dateTo) || (!dateFrom && dateTo)) {
        setDateValid(false);
        setDateValueError('Введите обе даты');
      } else if (dateFromSec > dateToSec) {
        setDateValid(false);
        setDateValueError('Дата после должны быть больше даты до');
      } else {
        setDateValueError('');
        setDateValid(true);
      }

      const roleValues = roleSelectValue.filter(item => item.checked);
      if (!roleValues.length) {
        setRoleSelectValueError('Укажите роль.');
      } else {
        setRoleSelectValueError('');
        // roleValues.forEach(item => {
        //   if (item.value === CUSTOMER_USER) {
        //     setPersonalAccountValueError('Укажите счет');
        //     accountError = true;
        //   }
        // });
        if (personalAccountValue) {
          setPersonalAccountValueError('');
          accountError = false;
        }
      }

      if (!name) {
        setNameError('Укажите имя');
      } else {
        setNameError('');
      }

      if (!email) {
        setEmailError('Укажите почту');
      } else {
        setEmailError('');
      }

      if (
        passwordIsValid &&
        dateValid &&
        hasValue(name) &&
        hasValue(email) &&
        hasValue(login) &&
        emailValid &&
        !dateValueError &&
        !accountError &&
        phoneValid &&
        !phoneError
      ) {
        const accountValue = personalAccountValue.value;
        const sname = trimValue(surname || '');
        const fname = trimValue(name || '');
        const mname = trimValue(patronymic || '');
        const phoneValue = trimValue(phone || '');
        const emailValue = toLowerCaseAndTrim(email || '');
        const newUser = {
          account_id: accountValue || (accounts && accounts[0].id),
          id,
          sname,
          fname,
          mname,
          login,
          account: {
            full_name: personalAccountValue.full_name || '',
          },
        };
        if (phoneValue) {
          newUser.phone = phoneValue;
        }
        if (emailValue) {
          newUser.email = emailValue;
        }
        if (roleValues.length) {
          newUser.roles = roleValues.map(item => ({
            role_name: item.value,
            account_id: accountValue || (accounts && accounts[0].id),
          }));
        }
        if (!roleValues.length) {
          newUser.roles = [{ role_name: CUSTOMER_USER }];
        }
        if (dateFromValue) {
          newUser.from_time = dateFromValue;
        } else {
          newUser.from_time = null;
        }
        if (dateToValue) {
          newUser.to_time = dateToValue;
        } else {
          newUser.to_time = null;
        }
        if (newPassword) {
          newUser.password = newPassword;
        }
        const { error } = await UserStore.editCustomerUser(newUser, id);
        if (!error) {
          if (newUser.id !== userData.id) {
            toggleEditUserPopup();
            return;
          }
          const user = await ProfileStore.hardFetchUser();

          const userRole =
            (user && user.roles && user.roles.map(item => item.role_name)) || undefined;

          const hasUser = !getAccessStatus({
            userRoles: userRole,
            allowedRoles: [CUSTOMER_ADMIN_ROLE, SUPER_ADMIN_ROLE],
          });
          if ((user.customer_id === 0 && hasUser) || hasUser) {
            if (user.id !== null) {
              await UserStore.getCustomer(id);
              await AccountStore.fetchMyAccount(user.account_id);
              await AccountStore.addAccountValue(personalAccountValue);
              await ServicesStore.fetchStats(personalAccountValue.value);
              setOneOffset(1);
            }
          } else {
            await UserStore.fetchMyUsers({ offset: 0 });
            await AccountStore.fetchMyAccounts({ qnt: 100000, offset: 0 });
            await AccountStore.addAccountValue(personalAccountValue);
            await ServicesStore.fetchStats(personalAccountValue.value);
            setOneOffset(0);
          }
          setRoleSelectValue('');
          setPersonalAccountValue('');
          toggleEditUserPopup();
        }
      } else {
        IndicatorsStore.addErrorIndicators({
          message: 'Проверьте правильность полей.',
          type: 'error',
        });
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const {
        setPersonalAccountValue,
        setRoleSelectValue,
        UserStore,
        AccountStore,
        id,
        inputHandlerSurname,
        inputHandlerPatronymic,
        inputHandlerName,
        inputHandlerEmail,
        inputHandlerPhone,
        inputHandlerDateFrom,
        inputHandlerDateTo,
        inputHandlerLogin,
        accounts,
        account,
        userRoles,
        ProfileStore,
      } = this.props;
      const { fetchMyAccounts, fetchMyAccount } = AccountStore;
      await fetchMyAccounts({ qnt: 100000, offset: 0 });
      const user = UserStore.users.find(item => item.id === id)
        ? UserStore.users.find(item => item.id === id)
        : UserStore.user.find(item => item.id === id);
      if (!UserStore.users.find(item => item.id === id)) {
        await fetchMyAccount(user.account_id);
      }
      const accountCurrent = accounts.find(item => item.id === user.account_id)
        ? accounts.find(item => item.id === user.account_id)
        : account.find(item => item.id === user.account_id);
      const parsedRoles = user.roles
        ? user.roles.map(item => ({
            ...item,
            value: item.role_name,
            checked: true,
          }))
        : [];
      const rolesValue = merge2RoleArrays(
        ProfileStore.userData.customer_id === 0 &&
          getAccessStatus({
            userRoles,
            allowedRoles: [CUSTOMER_ADMIN_ROLE],
          })
          ? [...SUPER_ADMINS_ROLES, ...parsedRoles]
          : [...CUSTOMER_ROLES, ...parsedRoles],
      );
      setPersonalAccountValue(accountCurrent || '');
      setRoleSelectValue(rolesValue || '');
      inputHandlerSurname(user.sname || '');
      inputHandlerName(user.fname || '');
      inputHandlerPatronymic(user.mname || '');
      inputHandlerEmail(user.email || '');
      inputHandlerLogin(user.login || '');
      inputHandlerPhone(checkPhoneNumber(user.phone) || '');
      inputHandlerDateTo(user.to_time ? new Date(user.to_time) : '');
      inputHandlerDateFrom(user.from_time ? new Date(user.from_time) : '');
    },
  }),
);

export default enchance(EditUserPopup);
