import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withHandlers, withState, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import {
  Wrapper,
  Content,
  InputsWrapper,
  CurrentPassword,
  InputWrapper,
  ImgShape,
  TextLink,
  RadioButtonsWrapper,
  ParagraphError,
} from './PasswordSectionStyled';
import {
  withCurrentPasswordState,
  withNewPasswordState,
  withConfirmPasswordState,
} from '../../../../utils/FormHocs';
import Typography from '../../../../components/Typography/Paragraph';
import InputField from '../../../../components/Inputs/InputField';
import storeNames from '../../../../stores/storeNames';
import { withPasswordChangeType, withUserData } from '../../../../utils/hocs';
import { ICON_SHAPE } from '../../../../constants/icons';
import history from '../../../../history';
import { getUrlObject, getOrigin } from '../../../../helpers/url';
import { STATUS_RECOVERY_TOKEN_SENDED } from '../../../../constants/statusPageUrls';
import { TEST_BUTTON_RESET_PASSWORD } from '../../../../constants/testids';

const PasswordSection = ({
  // checkedRadioButton,
  // inputSetStore,
  // smsConfirmed,
  startRecoveryPassword,
  // emailConfirmed,
  currentPassword,
  newPassword,
  confirmPassword,
  inputPasswordStore,
  inputHandlerCurrentPassword,
  inputHandlerConfirmPassword,
  inputHandlerNewPassword,
  showCurrentPassword,
  showNewPassword,
  showConfirmPassword,
  toggleCurrentPasswordType,
  toggleNewPasswordType,
  toggleConfirmPasswordType,
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
  setCurrentPasswordError,
  inputPasswordHandlerToStore, // Send handler to store, i want remove input value
}) => (
  <Wrapper>
    <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
      Безопасность
    </Typography>
    <Content>
      <Typography textColor="#707683" fontSize="16px" lineHeight="24px">
        Смена пароля
      </Typography>
      <InputsWrapper>
        <CurrentPassword>
          <InputWrapper>
            <InputField
              placeholder="Текущий пароль"
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="8"
              margin="0 0 -8px 0"
              value={currentPassword}
              autoComplete="current-password"
              name="currentPassword"
              type={showCurrentPassword}
              onChange={value => {
                inputPasswordStore(value, inputHandlerCurrentPassword, 'currentPassword');
                inputPasswordHandlerToStore(
                  inputHandlerCurrentPassword,
                  'inputHandlerCurrentPassword',
                );
                inputPasswordHandlerToStore(setCurrentPasswordError, 'setCurrentPasswordError');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleCurrentPasswordType} />
            <ParagraphError>{currentPasswordError}</ParagraphError>
          </InputWrapper>
          <TextLink
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="9"
            type="button"
            data-test-id={TEST_BUTTON_RESET_PASSWORD}
            onClick={startRecoveryPassword}
          >
            Забыли пароль?
          </TextLink>
        </CurrentPassword>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="10"
            placeholder="Новый пароль"
            margin="24px 0"
            name="newPassword"
            value={newPassword}
            autoComplete="new-password"
            type={showNewPassword}
            onChange={value => {
              inputPasswordStore(value, inputHandlerNewPassword, 'newPassword');
              inputPasswordHandlerToStore(inputHandlerNewPassword, 'inputHandlerNewPassword');
            }}
          />
          <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} />
          <ParagraphError>{newPasswordError}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <InputField
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex="11"
            placeholder="Повторите новый пароль"
            margin="24px 0"
            name="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            type={showConfirmPassword}
            onChange={value => {
              inputPasswordStore(value, inputHandlerConfirmPassword, 'confirmPassword');
              inputPasswordHandlerToStore(
                inputHandlerConfirmPassword,
                'inputHandlerConfirmPassword',
              );
            }}
          />
          <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
          <ParagraphError>{confirmPasswordError}</ParagraphError>
        </InputWrapper>
      </InputsWrapper>
      {/* <Typography textColor="#707683" fontSize="16px" lineHeight="24px">
        Подтверждение
      </Typography> */}
    </Content>
    <RadioButtonsWrapper>
      {/* <RadioButtonWrap>
        <RadioButton
          name="radio"
          testID="RADIO1"
          value={smsConfirmed}
          onClick={() => {
            inputSetStore('sms');
          }}
          checked={checkedRadioButton === 'sms'}
        >
          По SMS
        </RadioButton>
      </RadioButtonWrap> */}
      {/* <RadioButtonWrap>
        <RadioButton
          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
          tabIndex="12"
          name="radio"
          testID="RADIO2"
          value={emailConfirmed}
          onClick={() => {
            inputSetStore('email');
          }}
          checked={checkedRadioButton === 'email'}
        >
          На email
        </RadioButton>
      </RadioButtonWrap> */}
    </RadioButtonsWrapper>
  </Wrapper>
);

PasswordSection.propTypes = {
  // checkedRadioButton: PropTypes.string,
  currentPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  newPasswordError: PropTypes.string,
  showCurrentPassword: PropTypes.string,
  showNewPassword: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  // smsConfirmed: PropTypes.string,
  currentPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  newPassword: PropTypes.string,
  // emailConfirmed: PropTypes.string,
  // inputSetStore: PropTypes.func,
  toggleCurrentPasswordType: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
  inputPasswordStore: PropTypes.func,
  inputHandlerCurrentPassword: PropTypes.func,
  inputHandlerNewPassword: PropTypes.func,
  inputHandlerConfirmPassword: PropTypes.func,
  inputPasswordHandlerToStore: PropTypes.func,
  setCurrentPasswordError: PropTypes.func,
  startRecoveryPassword: PropTypes.func.isRequired,
};

PasswordSection.defaultProps = {
  // smsConfirmed: '',
  // emailConfirmed: '',
  currentPassword: '',
  confirmPasswordError: '',
  showCurrentPassword: '',
  currentPasswordError: '',
  newPasswordError: '',
  showNewPassword: '',
  showConfirmPassword: '',
  confirmPassword: '',
  newPassword: '',
  // checkedRadioButton: 'email',
  // inputSetStore: () => {},
  toggleCurrentPasswordType: () => {},
  toggleConfirmPasswordType: () => {},
  toggleNewPasswordType: () => {},
  inputPasswordStore: () => {},
  inputHandlerCurrentPassword: () => {},
  inputHandlerConfirmPassword: () => {},
  inputHandlerNewPassword: () => {},
  inputPasswordHandlerToStore: () => {},
  setCurrentPasswordError: () => {},
};

const enhancer = compose(
  inject(storeNames.ProfileStore, storeNames.RecoveryStore),
  observer,
  withUserData,
  withCurrentPasswordState,
  withConfirmPasswordState,
  withNewPasswordState,
  withPasswordChangeType,
  withProps(({ RecoveryStore, ProfileStore }) => ({
    prepareRecovery: RecoveryStore.prepareRecovery,
    isSaveBusy: ProfileStore.isSaveBusy,
  })),
  withState('checkedRadioButton', 'setCheckedRadioButton', 'email'),
  withHandlers({
    inputSetStore: ({ ProfileStore, setCheckedRadioButton }) => value => {
      setCheckedRadioButton(value);
      ProfileStore.setProfileStore({ [`confirmed`]: value });
    },
    inputPasswordStore: ({ ProfileStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      ProfileStore.setProfileStore({ [`${name}`]: value });
    },
    inputPasswordHandlerToStore: ({ ProfileStore }) => (inputHandler, name) => {
      ProfileStore.setProfileStoreHandlers({ [`${name}`]: inputHandler });
    },
    startRecoveryPassword: ({ prepareRecovery, userData }) => async () => {
      const collbackUrl = getUrlObject();
      const statusUrl = STATUS_RECOVERY_TOKEN_SENDED(collbackUrl.href);
      const { email } = userData;
      await prepareRecovery({ email, returnUrl: `${getOrigin()}/recovery` }, () => {
        history.replace(statusUrl);
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { ProfileStore } = this.props;
      ProfileStore.setProfileStore({ [`confirmed`]: 'email' });
    },
  }),
);
export default enhancer(PasswordSection);
