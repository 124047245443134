import React /* , { useRef, useState } */ from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose, /* withHandlers, withState, */ withProps, lifecycle } from 'recompose';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
// import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles /* withStyles */ } from '@material-ui/core/styles';
import styled, { keyframes } from 'styled-components';
import storeNames from '../../stores/storeNames';
// import { Title } from './PopupsStyled';
import Title from '../../components/Typography/Title';

// import Typography from '@material-ui/core/Typography';
import Typography from '../../components/Typography/Paragraph';
import {
  COLOR_ACTION_BLUE,
  COLOR_WHITE,
  /* , COLOR_RED, COLOR_TITLE_BLACK */
} from '../../constants/colors';
import Button from '../../components/Buttons/Button';
// import Popup from '../../components/Popup/Popup';
import CheckBox from '../../components/CheckBoxes/CheckBox';

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Fader = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  height: 160px;
  width: 160px;
  border: 1em solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: ${COLOR_ACTION_BLUE};
  animation: ${spin} 1s linear infinite;
  -webkit-animation: ${spin} 1s linear infinite;
`;

const useStyles = makeStyles(theme => ({
  modal: {
    // background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  paper: {
    zIndex: 2,
    // position: 'relative',
    position: 'absolute',
    // display: 'flex',
    // left: '50%',
    // top: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 800,
    // backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    // margin: theme.spacing(1),
  },
}));

const DialogPopup = ({
  isWait,
  persistent,
  isShow,
  isFade,
  hide,
  fadeOut,
  title,
  progress,
  width,
  model,
  buttons,
  changeField,
  toggleButton,
  buttonsRedraw,
  // colors,
}) => {
  const colors = {
    REGULAR: { textColor: COLOR_WHITE, backgroundColor: '', border: '' },
    WARNING: { textColor: COLOR_WHITE, backgroundColor: '#f51', border: '1px solid #f40' },
    CANCEL: { textColor: COLOR_ACTION_BLUE, backgroundColor: '#fff', border: '' },
  };
  const classes = useStyles();

  return (
    <>
      {isWait && (
        <Fader>
          <Spinner />
        </Fader>
      )}
      {isShow === true ? (
        <div>
          <Modal
            className={classes.modal}
            open={isFade}
            onClose={!persistent ? fadeOut : () => {}}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 200,
            }}
          >
            {/* <Popup width={width} closePopup={!persistent ? fadeOut : () => {}} borderRadius="5px"> */}
            <Grow in={isFade} onExited={hide} timeout={200}>
              <Paper elevation={4} className={classes.paper} style={{ width }}>
                <Box px={4} py={3}>
                  <Box display="flex" justifyContent="flex-start" mb="20px">
                    <Title as="h4" align="left" textColor="#03273F">
                      {title}
                    </Title>
                    {/* <Typography fontSize="24px" lineHeight="26px" textColor={COLOR_TITLE_BLACK}>
                      {title}
                    </Typography> */}
                  </Box>
                  {progress >= 0 ? progress : ''}

                  {model.length !== 0 &&
                    model.map((item, index) => (
                      <Box key={index} py="10px" style={{ cursor: 'pointer' }}>
                        {/* <Box>
                          <Typography textColor="#334D6E" fontSize="18px" lineHeight="32px">
                            {item.title}
                          </Typography>
                        </Box> */}

                        {item.type === 'LABEL' && (
                          <Typography fontSize="18px" lineHeight="32px">
                            {item.label}
                          </Typography>
                        )}

                        {item.type === 'CHECKBOX' && (
                          <CheckBox
                            size={30}
                            justifyContent="left"
                            checked={item.value}
                            onChange={() => {
                              const newValue = !item.value;
                              changeField(index, newValue);
                            }}
                          >
                            <Typography fontSize="16px" lineHeight="32px">
                              {item.label}
                            </Typography>
                          </CheckBox>
                        )}

                        {(item.type === 'TEXT' || item.type === 'DATE') && (
                          <TextField
                            style={{ width: '100%' }}
                            variant="outlined"
                            multiline={item.lines > 1}
                            rows={item.lines}
                            label={item.label}
                            type="text"
                            value={'value' in item ? item.value : ''}
                            onChange={event => {
                              changeField(index, event.target.value);
                            }}
                          />
                        )}
                      </Box>
                    ))}

                  <Box
                    key={buttonsRedraw}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    pt={2}
                  >
                    {buttons.length !== 0 &&
                      buttons.map((item, index) => (
                        <Button
                          key={index}
                          // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                          tabIndex={`TEST_DIALOG_INDEX_${index}`}
                          testID="TEST_BUTTON_"
                          width={'width' in item ? item.width : '20%'}
                          textColor={
                            'textColor' in item ? item.textColor : colors[item.color].textColor
                          }
                          boxShadow="none"
                          // eslint-disable-next-line
                          backgroundColor={'backgroundColor' in item ? item.backgroundColor : colors[item.color].backgroundColor}
                          // eslint-disable-next-line
                          border={'border' in item ? item.border : colors[item.color].border}
                          sizes={{ padding: '0 20px', margin: '0 0 0 20px' }}
                          onClick={() => {
                            if ('callback' in item) item.callback();
                            if ('toggle' in item) toggleButton(index);
                            if ('event' in item /* && item.close === true */) fadeOut();
                          }}
                        >
                          {'toggle' in item ? item.label[item.toggle] : item.label}
                        </Button>
                      ))}
                  </Box>
                </Box>
              </Paper>
            </Grow>
            {/* </Popup> */}
          </Modal>
        </div>
      ) : null}
    </>
  );
};

DialogPopup.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
  // items: PropTypes.array,
  isWait: PropTypes.bool.isRequired,
  isShow: PropTypes.bool.isRequired,
  isFade: PropTypes.bool.isRequired,
  persistent: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  fadeOut: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  model: PropTypes.array,
  buttons: PropTypes.array,
  changeField: PropTypes.func.isRequired,
  toggleButton: PropTypes.func.isRequired,
  buttonsRedraw: PropTypes.number.isRequired,
  // colors: PropTypes.object,
};

DialogPopup.defaultProps = {
  title: '',
  progress: 0,
  model: [],
  buttons: [],
  // colors: {},
};

const enchance = compose(
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DialogStore }) => ({
    isWait: DialogStore.isWait,
    isShow: DialogStore.isShow,
    isFade: DialogStore.isFade,
    persistent: DialogStore.persistent,
    hide: DialogStore.hide,
    fadeOut: DialogStore.fadeOut,
    title: DialogStore.title,
    progress: DialogStore.progress,
    width: DialogStore.width,
    model: DialogStore.model,
    buttons: DialogStore.buttons,
    changeField: DialogStore.changeField,
    toggleButton: DialogStore.toggleButton,
    buttonsRedraw: DialogStore.buttonsRedraw,
    // colors: DialogStore.colors,
  })),
  // withBoolState('progressPopup', false),
  lifecycle({
    async componentDidMount() {
      const { DialogStore } = this.props;
      DialogStore.hide();
    },
  }),
);

export default enchance(DialogPopup);
