import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers } from 'recompose';
import { useMedia } from 'use-media';
import Popup from '../../../components/Popup/Popup';
import storeNames from '../../../stores/storeNames';
import { Wrapper, ButtonWrapperCreate, FormWrapper } from './PopupsStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import { COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';

const DeleteTariffPopup = ({ toggleOpenDeletePopup, formHandler }) => {
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Popup width="600px" closePopup={toggleOpenDeletePopup}>
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
          textAlign="center"
        >
          Вы действительно хотите удалить тариф?
        </Paragraph>
        <FormWrapper onSubmit={formHandler}>
          <ButtonWrapperCreate>
            <Button
              testID="TEST_BUTTON_POPUP_DELETE"
              width="303px"
              type="submit"
              backgroundColor="#f7685B"
              box-shadow="none"
              border="none"
              sizes={{ margin: '0 20px' }}
            >
              Удалить
            </Button>
            <Button
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              type="submit"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleOpenDeletePopup()}
            >
              Отмена
            </Button>
          </ButtonWrapperCreate>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

DeleteTariffPopup.propTypes = {
  toggleOpenDeletePopup: PropTypes.func,
  formHandler: PropTypes.func,
};

DeleteTariffPopup.defaultProps = {
  toggleOpenDeletePopup: () => {},
  formHandler: () => {},
};

const enhancer = compose(
  inject(storeNames.ServicesStore),
  observer,
  withHandlers(() => ({
    formHandler: ({ ServicesStore, tariffId, toggleOpenDeletePopup }) => async e => {
      e.preventDefault();

      await ServicesStore.deleteTariff(tariffId);
      toggleOpenDeletePopup();
    },
  })),
);

export default enhancer(DeleteTariffPopup);
