const inviteText = ({
  theirOrganization = '',
  theirOgrn = '',
  ourOrganization = '',
  ourOgrn = '',
}) => `Уважаемый Контрагент ${theirOrganization} ОГРН ${theirOgrn}

Для заключения договорных отношений с компанией ${ourOrganization}, ОГРН ${ourOgrn} Вам необходимо:
    1.  Зарегистрироваться на портале https://service01.rescore.online/profile
    2.  Заполнить формы раскрытия информации.
    3.  Загрузить и сохранить все необходимые подтверждающие документы.
    4.  Подписать формы и документы электронно-цифровой подписью предприятия.
    5.  Предоставить доступ к данным документам компании ${ourOrganization}.
    
Подробную инструкцию вы найдете http://rescore.online/wp-content/uploads/2021/04/INSTRUCTIONS.pdf

С уважением,
${ourOrganization}

Обращаем Ваше внимание на то, что отказавшись от информационной рассылки, осуществляемой Сервисом Rescore, Вы утратите возможность получать сообщения о запросах на предоставление дополнительных сведений, необходимых для проверки. Непредоставление дополнительных сведений может повлиять на решение  потенциальных контрагентов заключать сделки с Вашей компанией.`;

export default inviteText;
