import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import {
  withDateFromState,
  withDateToState,
  withProfileInputSetStore,
} from '../../../utils/FormHocs';
import { withActiveValue } from '../../../utils/hocs';
import {
  Top,
  RightContainer,
  WrapperDate,
  DateInputWrap,
  ButtonWrapper,
  TableWrapper,
  TableContainer,
  Thead,
  TrTop,
  Th,
  Tbody,
  Tr,
  Td,
  TdName,
  TdCount,
  SpanLogin,
} from './TableStyled';
import Button from '../../../components/Buttons/Button';
import Paragraph from '../../../components/Typography/Paragraph';
import storeNames from '../../../stores/storeNames';
import InputDate from '../../../components/Inputs/InputDate';

const Table = ({
  dateFrom,
  dateTo,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  inputSetStore,
  handlerButton,
  fetchMoreData,
  hasMoreItems,
  history,
}) => {
  return (
    <>
      <Top>
        <RightContainer>
          <Paragraph fontSize="16px" textColor="#2E3238">
            Период
          </Paragraph>
          <WrapperDate>
            <DateInputWrap>
              <InputDate
                padding="12px 19px"
                name="dateFrom"
                margin="0 8px 0 0"
                value={dateFrom}
                onChange={value => {
                  inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                }}
              />
              —
              <InputDate
                padding="12px 19px"
                name="dateTo"
                value={dateTo}
                margin="0 0 0 8px"
                onChange={value => {
                  inputSetStore(value, inputHandlerDateTo, 'dateTo');
                }}
              />
            </DateInputWrap>
          </WrapperDate>
          <ButtonWrapper>
            <Button
              minWidth="127px"
              height="42px"
              testID="TEST__BUTTON_SHOW_SERVICES"
              lineHeight="15px"
              onClick={handlerButton}
            >
              Показать
            </Button>
          </ButtonWrapper>
        </RightContainer>
      </Top>
      {history.length > 0 ? (
        <TableWrapper>
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => fetchMoreData()}
            hasMore={hasMoreItems}
            style={{ overflow: 'auto' }}
          >
            <TableContainer>
              <Thead>
                <TrTop>
                  <Th width="200px">Дата - время</Th>
                  <Th>Пользователь</Th>
                  <Th>Использовал</Th>
                </TrTop>
              </Thead>
              <Tbody>
                {history &&
                  history.map(item => (
                    <Tr key={item.id}>
                      <Td>{`${moment(item.datetime).format('DD.MM.YYYY - H:mm:ss')}`}</Td>
                      <TdName>
                        {`${item.user.sname || ''} ${item.user.fname || ''} ${item.user.mname ||
                          ''} `}
                        <SpanLogin>({item.user.login})</SpanLogin>
                      </TdName>
                      <TdCount width="150px">
                        {item.scores ? `${item.scores} Скоринг` : `${item.monitors} Монитор`}
                      </TdCount>
                    </Tr>
                  ))}
              </Tbody>
            </TableContainer>
          </InfiniteScroll>
        </TableWrapper>
      ) : (
        'Нет истории использования услуг'
      )}
    </>
  );
};

Table.propTypes = {
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  inputHandlerDateTo: PropTypes.func,
  inputHandlerDateFrom: PropTypes.func,
  inputSetStore: PropTypes.func,
  handlerButton: PropTypes.func,
  fetchMoreData: PropTypes.func,
  history: PropTypes.array,
  hasMoreItems: PropTypes.bool,
};

Table.defaultProps = {
  dateFrom: undefined,
  dateTo: undefined,
  hasMoreItems: undefined,
  history: [],
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
  inputSetStore: () => {},
  handlerButton: () => {},
  fetchMoreData: () => {},
};

const enhancer = compose(
  inject(storeNames.ServicesStore),
  observer,
  withActiveValue(false, false, true, false, false, false),
  withProfileInputSetStore,
  withDateFromState,
  withDateToState,
  withHandlers(() => ({
    handlerButton: ({ dateFrom, dateTo, ServicesStore, accountValue }) => async () => {
      ServicesStore.addDateServices({ dateFrom, dateTo });
      await ServicesStore.fetchHistory(
        { dateFrom: ServicesStore.dateFromServices, dateTo: ServicesStore.dateToServices },
        accountValue.id,
      );
    },
    fetchMoreData: ({ ServicesStore, setOffset, offset, accountValue }) => async () => {
      const items = await ServicesStore.fetchHistory(
        { dateFrom: ServicesStore.dateFromServices, dateTo: ServicesStore.dateToServices, offset },
        accountValue.id,
      );
      setOffset(items.length);
    },
  })),
);

export default enhancer(Table);
