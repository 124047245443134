import { createQueryString } from '../helpers/url';
import API from './base';

export default class InquiriesApi extends API {
  getInquiries(data, accountToken) {
    return this.post(`subscriptions?input=json&service=scoring`, data, accountToken, false);
  }

  getInquiriesList({ limit = 15, offset = 0 }, accountToken) {
    return this.get(`subscriptions?${createQueryString({ limit, offset })}`, accountToken, false);
  }
}
