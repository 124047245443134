import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { compose, withProps } from 'recompose';

import api from '../../../api';
import storeNames from '../../../stores/storeNames';

const NewExtraDiscretionButton = ({ item, updateInquireReports }) => {
  const [hasError, setHasError] = useState(false);
  const onErrorMessageClose = () => {
    setHasError(false);
  };

  const { discretion, id } = item;

  useEffect(() => {
    if (discretion) {
      setHasError(discretion.has_error);
    }
  }, [discretion]);

  if (!discretion) return null;

  const { allow_report_creation } = discretion;

  const newDiscretionClickHandler = async () => {
    const report = await api.discretion.getNewDiscretion({
      discretionId: discretion.discretion_id,
    });

    updateInquireReports(id, report);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={hasError}
        onClose={onErrorMessageClose}
        autoHideDuration={6000}
        message="При формировании отчёта ДО произошла ошибка. Повторите попытку позже"
      />
      <Button
        variant="outlined"
        size="small"
        onClick={newDiscretionClickHandler}
        disabled={!allow_report_creation}
      >
        {allow_report_creation ? 'Сформировать отчет ДО' : 'Формируется отчёт ДО'}
      </Button>
    </>
  );
};

NewExtraDiscretionButton.propTypes = {
  item: PropTypes.object.isRequired,
  updateInquireReports: PropTypes.func.isRequired,
};

export default compose(
  inject(storeNames.InquiriesStore),
  observer,
  withProps(({ InquiriesStore }) => ({
    updateInquireReports: InquiriesStore.updateInquireReports,
  })),
)(NewExtraDiscretionButton);
