import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withState, withProps, lifecycle } from 'recompose';
import { useParams } from 'react-router-dom';
import * as moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
// import { spacing } from '@material-ui/system';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import logger from '../../logger';
import {
  /* Wrapper, */
  Title,
  TopWrapper,
  /* SelectWrapper, */
  // Tabs,
  // TabsItem,
} from '../Balance/BalanceStyled';

import storeNames from '../../stores/storeNames';
import { COLOR_ACTION_BLUE, COLOR_WHITE } from '../../constants/colors';
// import logger from '../../logger';

const useStyles = makeStyles(theme => ({
  palette: {
    primary: {
      main: '#03a9f4',
    },
    secondary: {
      main: '#f44336',
    },
  },
  root: {
    maxWidth: '50%',
    borderRadius: '6px',
    /* flexGrow: 1,
    backgroundColor: theme.palette.background.paper, */
  },
  title: {
    backgroundColor: COLOR_ACTION_BLUE,
    color: '#FFF',
  },
  titleFullName: {
    color: '#EEE',
  },
  body: {
    backgroundColor: '#FBFBFB',
    // padding: theme.spacing(2),
  },
  indent: {
    padding: theme.spacing(1, 0),
  },
  tableHeader: {
    fontWeight: 'bold',
    // fontStyle: 'italic',
    paddingBottom: 0,
    marginBottom: 0,
    color: 'grey',
  },
  tableBodyRoot: {
    // fontWeight: 'bold',
    color: 'grey',
  },
  tableBody: {
    color: '#999',
    // backgroundColor: '#EEE',
  },
  tableBodyLast: {
    color: '#88B',
  },
}));

const keyList = [
  { key: 'main_status', title: 'Статус', format: a => a },
  { key: 'ogrn', title: 'ОГРН', format: a => a },
  { key: 'inn', title: 'ИНН', format: a => a },
  { key: 'kpp', title: 'КПП', format: a => a },
  {
    key: 'registration_date',
    title: 'Дата регистрации',
    format: a => moment.utc(a).format('DD.MM.YYYY'),
  },
  { key: 'okved_code', title: 'Код ведения деятельности', format: a => a },
  { key: 'authorized_capital', title: 'Уставный капитал', format: a => `${a} руб` },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
    style: {
      minWidth: '18%',
      maxWidth: '18%',
      backgroundColor: '#eee',
      borderRadius: '8px',
      margin: '1%',
    },
  };
}

const EntityCard = ({ /* entity, */ entityDetail }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardActionArea>
        <CardContent className={classes.title}>
          <Typography gutterBottom variant="h5" component="h2">
            {entityDetail.name_short}
          </Typography>
          <Typography className={classes.titleFullName} variant="caption" component="div">
            {entityDetail.name_full}
          </Typography>
        </CardContent>
        <Divider />
        <CardContent className={classes.body}>
          {keyList.map((item, index) => (
            <Grid
              key={index}
              className={classes.indent}
              container
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="body1" color="textSecondary" component="div">
                {item.title}:
              </Typography>
              <Typography variant="body1" color="textSecondary" component="div">
                {item.format(entityDetail[item.key])}
              </Typography>
            </Grid>
          ))}
        </CardContent>
      </CardActionArea>
      {/* <CardActions className={classes.body}>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
          </CardActions> */}
    </Card>
  );
};

EntityCard.propTypes = {
  // entity: PropTypes.object.isRequired,
  entityDetail: PropTypes.object.isRequired,
};

const ButtonGroupSwitch = ({ array, value, setValue }) => {
  return (
    <ButtonGroup aria-label="button group">
      {array.map(item => (
        <Button
          key={item.value}
          color=""
          style={{
            color: value === item.value ? COLOR_WHITE : 'grey',
            backgroundColor: value === item.value ? COLOR_ACTION_BLUE : '',
          }}
          onClick={() => {
            setValue(item.value);
          }}
          aria-label={item.label}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
ButtonGroupSwitch.propTypes = {
  array: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

const YearIntervalSelector = ({ yearArray, yearFrom, setYearFrom, yearTo, setYearTo, change }) => {
  // const [yearFrom, setYearFrom] = React.useState(yearArray[0]);
  // const [yearTo, setYearTo] = React.useState('');
  const handleChangeFrom = event => {
    setYearFrom(event.target.value);
    change(event.target.value, yearTo);
  };
  const handleChangeTo = event => {
    setYearTo(event.target.value);
    change(yearFrom, event.target.value);
  };
  logger.debug('====>', yearArray, yearFrom, yearTo);
  return (
    <div>
      <FormControl
        variant="outlined"
        style={{ marginBottom: '10px', width: '50%', paddingRight: '10px' }}
      >
        <InputLabel id="demo-simple-select-standard-label1">Начало периода</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label1"
          id="demo-simple-select-standard1"
          value={yearFrom !== 0 ? yearFrom : ''}
          onChange={handleChangeFrom}
          label="Начало периода"
        >
          {yearArray.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ marginBottom: '10px', width: '50%' }}>
        <InputLabel id="demo-simple-select-filled-label2">Конец периода</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label2"
          id="demo-simple-select-filled2"
          value={yearTo !== 0 ? yearTo : ''}
          onChange={handleChangeTo}
          label="Конец периода"
        >
          {yearArray.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
YearIntervalSelector.propTypes = {
  yearArray: PropTypes.array.isRequired,
  yearFrom: PropTypes.number.isRequired,
  setYearFrom: PropTypes.func.isRequired,
  yearTo: PropTypes.number.isRequired,
  setYearTo: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const FinanceTable = ({ data, yearFrom, yearTo, depth }) => {
  const classes = useStyles();
  const [openObj, setOpenObj] = React.useState({});
  const rowStyle = d => {
    if (d === 0) return classes.tableBodyRoot;
    if (d === 1) return classes.tableBody;
    return classes.tableBodyLast;
  };
  /* Object.fromEntries(Object.entries(data).map(([key, value]) => [key, false])),
  ); */
  if (typeof data !== 'object') return false;
  const years = [];
  for (let year = yearFrom; year <= yearTo; year += 1) years.push(year);
  // logger.debug('---->', Object.entries(data));
  // logger.debug('entry');
  const switcher = key => {
    setOpenObj({
      ...openObj,
      [key]: !openObj[key],
    });
    // logger.debug('switched', key);
  };
  return (
    <Box ml={depth !== 0 ? '50px' : 0}>
      {Object.entries(data).map((item, index) => {
        // logger.debug(item);
        const nextDepthLength = Object.keys(item[1]).length;
        const nextDepth =
          typeof item[1] !== 'number' &&
          (('values' in item[1] && nextDepthLength > 2) ||
            (!('values' in item[1]) && nextDepthLength !== 0));
        return (
          <Box key={item[0]}>
            {/* !('values' in data) && depth < 1 && !('values' in item[1]) && <>{item[0]}</> */}
            {item[0] !== 'values' && item[0] !== 'rowNum' && (
              <>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {depth < 1 && index === 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} style={{ width: '60px' }}>
                          №
                        </TableCell>
                        <TableCell className={classes.tableHeader}>Наименование</TableCell>
                        {years.map(year => (
                          <TableCell
                            className={classes.tableHeader}
                            key={year}
                            align="right"
                            style={{ width: '160px' }}
                          >
                            {year}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => switcher(item[0])}
                    >
                      <TableCell
                        className={rowStyle(depth)}
                        style={{ width: '60px' }}
                        component="th"
                        scope="row"
                      >
                        {'rowNum' in item[1] ? item[1].rowNum : ''}
                      </TableCell>
                      <TableCell className={rowStyle(depth)} component="th" scope="row">
                        <div style={{ display: 'flex', paddingTop: nextDepth ? '9px' : 0 }}>
                          <div style={{ display: 'inline-block' }}>
                            {typeof item[0] !== 'undefined' ? item[0] : '???'}
                          </div>
                          {nextDepth && (
                            <div style={{ paddingLeft: '10px' }}>
                              {openObj[item[0]] ? <ExpandLess /> : <ExpandMore />}
                            </div>
                          )}
                        </div>
                      </TableCell>
                      {'values' in item[1]
                        ? years.map(year => {
                            // logger.debug(item[1].values);
                            let item2 = item[1].values.find(it => Number(it.year) === year);
                            if (!item2) item2 = { year, rowNum: null, value: '-' };
                            // logger.debug(item2);
                            return (
                              <TableCell
                                key={item2.year}
                                align="right"
                                style={{ width: '160px' }}
                                className={rowStyle(depth)}
                              >
                                {item2.value}
                              </TableCell>
                            );
                          })
                        : years.map(year => (
                            <TableCell key={year} align="right" style={{ width: '160px' }} />
                          ))}
                      {/* 'values' in item[1]
                        ? item[1].values.map(item2 => (
                            <TableCell
                              key={item2.year}
                              align="right"
                              style={{ width: '160px' }}
                              className={rowStyle(depth)}
                            >
                              {item2.value}
                            </TableCell>
                          ))
                        : years.map(year => (
                            <TableCell key={year} align="right" style={{ width: '160px' }} />
                        )) */}
                    </TableRow>
                  </TableBody>
                </Table>
                {Object.keys(item[1]).length !== 0 && (
                  <Collapse in={openObj[item[0]]} timeout="auto" unmountOnExit>
                    <FinanceTable
                      data={item[1]}
                      yearFrom={yearFrom}
                      yearTo={yearTo}
                      depth={depth + 1}
                    />
                  </Collapse>
                )}
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
FinanceTable.propTypes = {
  data: PropTypes.object,
  yearFrom: PropTypes.number.isRequired,
  yearTo: PropTypes.number.isRequired,
  depth: PropTypes.number,
};
FinanceTable.defaultProps = {
  data: {},
  depth: 0,
};

const Dashboard = ({
  loader,
  tabSelector,
  setTabSelector,
  financeSelector,
  setFinanceSelector,
  ispolnitelSelector,
  setIspolnitelSelector,
  entity,
  entityDetail,
  getFinance,
  finance,
  setFinance,
  financeTypesList,
  financeYearList,
  yearFrom,
  setYearFrom,
  yearTo,
  setYearTo,
  getFinanceFNS,
  setFinanceFNS,
  financeFNS,
}) => {
  // logger.debug(entity);
  // const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTabSelector(newValue);
  };

  return (
    <>
      <TopWrapper style={{ marginBottom: '30px' }}>
        <Title as="h2">Дашборд</Title>
      </TopWrapper>

      <EntityCard entity={entity} entityDetail={entityDetail} />

      <Tabs
        style={{ marginTop: '30px', paddingBottom: '3px', borderBottom: '1px solid #ddd' }}
        value={tabSelector}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        indicatorColor="secondary"
        textColor="primary"
      >
        <Tab value="bankrot" label="Вероятность банкротства" {...a11yProps('bankrot')} />
        <Tab value="finance" label="Финансовая информация" {...a11yProps('finance')} />
        <Tab value="sud" label="Судебные дела" {...a11yProps('sud')} />
        <Tab value="ispolnitel" label="Исполнительные производства" {...a11yProps('ispolnitel')} />
        <Tab value="register" label="Регистрационные данные" {...a11yProps('register')} />
      </Tabs>

      <TabPanel value={tabSelector} index="bankrot">
        Under construction
      </TabPanel>

      <TabPanel value={tabSelector} index="finance">
        <YearIntervalSelector
          yearArray={financeYearList}
          yearFrom={yearFrom}
          setYearFrom={setYearFrom}
          yearTo={yearTo}
          setYearTo={setYearTo}
          change={async (_yearFrom, _yearTo) => {
            if (_yearTo > _yearFrom) {
              loader(true);
              setFinance(await getFinance(_yearFrom, _yearTo));
              setFinanceFNS(await getFinanceFNS(_yearFrom, _yearTo));
              loader(false);
            }
          }}
        />
        {'title' in financeTypesList[0] && (
          <>
            <ButtonGroupSwitch
              array={[
                ...financeTypesList.map(item => {
                  return { label: item.title, value: item.id };
                }),
                { label: 'Данные от ФНС', value: 'fns' },
              ]}
              value={financeSelector}
              setValue={setFinanceSelector}
            />

            <FinanceTable
              data={financeSelector === 'fns' ? financeFNS : finance[financeSelector]}
              yearFrom={yearFrom}
              yearTo={yearTo}
            />
          </>
        )}
      </TabPanel>

      <TabPanel value={tabSelector} index="sud">
        Under construction
      </TabPanel>

      <TabPanel value={tabSelector} index="ispolnitel">
        <ButtonGroupSwitch
          array={[{ label: 'Открытые', value: 'opened' }, { label: 'Закрытые', value: 'closed' }]}
          value={ispolnitelSelector}
          setValue={setIspolnitelSelector}
        />
      </TabPanel>

      <TabPanel value={tabSelector} index="register">
        Under construction
      </TabPanel>
    </>
  );
};
Dashboard.propTypes = {
  // dashId: PropTypes.string.isRequired,
  loader: PropTypes.func.isRequired,
  tabSelector: PropTypes.string.isRequired,
  setTabSelector: PropTypes.func.isRequired,
  financeSelector: PropTypes.string.isRequired,
  setFinanceSelector: PropTypes.func.isRequired,
  ispolnitelSelector: PropTypes.string.isRequired,
  setIspolnitelSelector: PropTypes.func.isRequired,
  entity: PropTypes.object,
  entityDetail: PropTypes.object,
  getFinance: PropTypes.func.isRequired,
  finance: PropTypes.object.isRequired,
  setFinance: PropTypes.func.isRequired,
  financeTypesList: PropTypes.array.isRequired,
  financeYearList: PropTypes.array.isRequired,
  yearFrom: PropTypes.number.isRequired,
  setYearFrom: PropTypes.func.isRequired,
  yearTo: PropTypes.number.isRequired,
  setYearTo: PropTypes.func.isRequired,
  financeFNS: PropTypes.object.isRequired,
  getFinanceFNS: PropTypes.func.isRequired,
  setFinanceFNS: PropTypes.func.isRequired,
};
Dashboard.defaultProps = {
  entity: {},
  entityDetail: {},
};

const enhance = compose(
  inject(storeNames.DashboardStore),
  inject(storeNames.DialogStore),
  observer,
  withProps(({ DashboardStore, DialogStore }) => ({
    loader: DialogStore.loader,
    entity: DashboardStore.entity,
    getEntity: DashboardStore.getEntity,
    entityDetail: DashboardStore.entityDetail,
    getEntityDetail: DashboardStore.getEntityDetail,
    subscribeId: useParams().id,
    getFinance: DashboardStore.getFinance,
    getFinanceFNS: DashboardStore.getFinanceFNS,
    financeTypesList: DashboardStore.getFinanceTypes(),
    financeYearList: DashboardStore.financeYearList,
  })),
  withState('tabSelector', 'setTabSelector', 'finance'),
  withState('yearFrom', 'setYearFrom', 0),
  withState('yearTo', 'setYearTo', 0),
  withState('finance', 'setFinance', {}),
  withState('financeFNS', 'setFinanceFNS', {}),
  withState('financeSelector', 'setFinanceSelector', ''),
  withState('ispolnitelSelector', 'setIspolnitelSelector', 'opened'),
  // withProps(({ ServicesStore }) => ({})),
  // withUserData,
  lifecycle({
    async componentDidMount() {
      const {
        DashboardStore,
        DialogStore,
        subscribeId,
        financeTypesList,
        setFinanceSelector,
        // financeYearList,
        setYearFrom,
        setYearTo,
        setFinance,
        setFinanceFNS,
      } = this.props;
      DashboardStore.clear();
      DialogStore.loader(true);
      // logger.debug('!!!!!!!!!!!!!!!', financeTypesList);
      await DashboardStore.getEntity(subscribeId);
      await DashboardStore.getEntityDetail();
      const yearList = await DashboardStore.syncFinance();
      setFinanceSelector(financeTypesList[0].id);
      const _yearFrom = yearList[yearList.length >= 3 ? yearList.length - 3 : 0];
      const _yearTo = yearList[yearList.length - 1];
      setYearFrom(_yearFrom);
      setYearTo(_yearTo);
      setFinance(await DashboardStore.getFinance(_yearFrom, _yearTo));
      setFinanceFNS(await DashboardStore.getFinanceFNS(_yearFrom, _yearTo));
      DialogStore.loader(false);
    },
  }),
);

export default enhance(Dashboard);
