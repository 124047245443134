import React from 'react';
import { compose, withState, withHandlers } from 'recompose';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  PopupBody,
  Title,
  Container,
  InputWrapper,
  ImgShape,
  Column,
} from './ChangePasswordStyled';
import { getOrigin } from '../../helpers/url';
import Button from '../../components/Buttons/Button';
import storeNames from '../../stores/storeNames';
import InputField from '../../components/Inputs/InputField';
import { ICON_SHAPE } from '../../constants/icons';
import ParagraphError from '../../components/Typography/ParagraphError';
import { withPasswordChangeType, withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import history from '../../history';
import { STATUS_NEW_PASSWORD_SETTED } from '../../constants/statusPageUrls';
import {
  withNewPasswordState,
  withConfirmPasswordState,
  withInputSetStore,
} from '../../utils/FormHocs';
import { prepareValidatePassword } from '../../helpers/validatePassword';

const Change = ({
  newPassword,
  showNewPassword,
  inputHandlerNewPassword,
  inputSetStore,
  toggleNewPasswordType,
  newPasswordError,
  confirmPassword,
  showConfirmPassword,
  inputHandlerConfirmPassword,
  toggleConfirmPasswordType,
  confirmPasswordError,
  formHandler,
  userData,
  userIsLoading,
}) => (
  <>
    {userData && userData.id && !userIsLoading && userData.need_password_change && (
      <PopupBody onSubmit={formHandler}>
        <Title>Задайте Ваш новый пароль</Title>
        <Column>
          <InputWrapper>
            <InputField
              placeholder="Новый пароль"
              value={newPassword}
              type={showNewPassword}
              name="confirmPassword"
              onChange={value => {
                inputSetStore(value, inputHandlerNewPassword, 'newPassword');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleNewPasswordType} value={newPassword} />
            <ParagraphError>{newPasswordError}</ParagraphError>
          </InputWrapper>
          <InputWrapper>
            <InputField
              placeholder="Новый пароль еще раз"
              value={confirmPassword}
              type={showConfirmPassword}
              name="confirmPassword"
              onChange={value => {
                inputSetStore(value, inputHandlerConfirmPassword, 'confirmPassword');
              }}
            />
            <ImgShape src={ICON_SHAPE} onClick={toggleConfirmPasswordType} />
            <ParagraphError>{confirmPasswordError}</ParagraphError>
          </InputWrapper>
        </Column>
        <Container>
          <Button type="submit" width="233px" testID="TEST_BUTTON_CHANGE">
            Сменить пароль
          </Button>
        </Container>
      </PopupBody>
    )}
    {userData &&
      userData.id &&
      !userIsLoading &&
      !userData.need_password_change &&
      history.replace('/profile')}
  </>
);

export const ChangePassword = compose(
  inject(storeNames.ProfileStore, storeNames.UserStore, storeNames.IndicatorsStore),
  observer,
  withNewPasswordState,
  withConfirmPasswordState,
  withPasswordChangeType,
  withInputSetStore,
  withFetchUserInDidMount,
  withUserData,
  withState('newPasswordError', 'setNewPasswordError', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  withHandlers(() => ({
    formHandler: ({
      IndicatorsStore,
      userId,
      UserStore,
      confirmPassword,
      newPassword,
      setNewPasswordError,
      setConfirmPasswordError,
    }) => async e => {
      e.preventDefault();
      const passwordIsValid = prepareValidatePassword({
        newPassword,
        setNewPasswordError,
        setConfirmPasswordError,
        confirmPassword,
      });

      if (passwordIsValid) {
        const { error } = await UserStore.changePasswordUser(newPassword, userId);
        if (!error) {
          const collbackUrl = `${getOrigin()}/`;
          const statusUrl = STATUS_NEW_PASSWORD_SETTED(`${collbackUrl}profile`);
          history.replace(statusUrl);
        } else {
          IndicatorsStore.addErrorIndicators({
            message: 'Что-то пошло не так, попробуйте еще раз.',
            type: 'error',
          });
        }
      }
    },
  })),
)(Change);

Change.propTypes = {
  newPassword: PropTypes.string,
  showNewPassword: PropTypes.string,
  inputHandlerNewPassword: PropTypes.func,
  inputSetStore: PropTypes.func,
  toggleNewPasswordType: PropTypes.func,
  formHandler: PropTypes.func,
  newPasswordError: PropTypes.string,
  userData: PropTypes.object.isRequired,
  userIsLoading: PropTypes.bool,
  confirmPassword: PropTypes.string,
  showConfirmPassword: PropTypes.string,
  inputHandlerConfirmPassword: PropTypes.func,
  toggleConfirmPasswordType: PropTypes.func,
  confirmPasswordError: PropTypes.string,
};

Change.defaultProps = {
  newPassword: '',
  showNewPassword: '',
  userIsLoading: false,
  inputHandlerNewPassword: () => {},
  inputSetStore: () => {},
  toggleNewPasswordType: () => {},
  newPasswordError: '',
  confirmPassword: '',
  showConfirmPassword: '',
  inputHandlerConfirmPassword: () => {},
  toggleConfirmPasswordType: () => {},
  formHandler: () => {},
  confirmPasswordError: '',
};
