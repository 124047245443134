import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers } from 'recompose';
import { useMedia } from 'use-media';
import Popup from '../../../components/Popup/Popup';
import storeNames from '../../../stores/storeNames';
import { Wrapper, ButtonWrapperCreate, InputWrapperCreate, FormWrapper } from './PopupsStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Paragraph from '../../../components/Typography/Paragraph';
import InputField from '../../../components/Inputs/InputField';
import { hasValue } from '../../../utils/validators';
import { COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import {
  withBalanceInputSetStore,
  withMoneyState,
  withAgreementNumberState,
} from '../../../utils/FormHocs';
import Button from '../../../components/Buttons/Button';

const ReplenishmentAgreementPopup = ({
  toggleOpenReplenishmentAgreementPopup,
  formHandler,
  inputSetStore,
  agreementNumber,
  agreementNumberValid,
  inputHandlerAgreementNumber,
  agreementNumberError,
  money,
  moneyValid,
  inputHandlerMoney,
  moneyError,
}) => {
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Popup width="600px" closePopup={toggleOpenReplenishmentAgreementPopup}>
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
          textAlign="center"
          margin="0 0 20px 0"
        >
          Пополнение баланса по договору
        </Paragraph>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapperCreate>
            <InputField
              placeholder="Номер договора"
              value={agreementNumber}
              name="agreementNumber"
              isValid={agreementNumberValid}
              onChange={value => {
                inputSetStore(value, inputHandlerAgreementNumber, 'agreementNumber');
              }}
            />
            <ParagraphError>{agreementNumberError}</ParagraphError>
          </InputWrapperCreate>
          <InputWrapperCreate>
            <InputField
              placeholder="Сумма"
              type="text"
              name="money"
              value={money}
              isValid={moneyValid}
              onChange={value => {
                inputSetStore(value, inputHandlerMoney, 'money');
              }}
            />
            <ParagraphError>{moneyError}</ParagraphError>
          </InputWrapperCreate>
          <ButtonWrapperCreate>
            <Button
              testID="TEST_BUTTON_ADD"
              width="303px"
              type="submit"
              sizes={{ margin: '0 20px' }}
            >
              Сохранить
            </Button>
            <Button
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              type="submit"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleOpenReplenishmentAgreementPopup()}
            >
              Отмена
            </Button>
          </ButtonWrapperCreate>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

ReplenishmentAgreementPopup.propTypes = {
  toggleOpenReplenishmentAgreementPopup: PropTypes.func,
  formHandler: PropTypes.func,
  inputSetStore: PropTypes.func,
  agreementNumber: PropTypes.string,
  agreementNumberValid: PropTypes.bool,
  inputHandlerAgreementNumber: PropTypes.func,
  agreementNumberError: PropTypes.string,
  money: PropTypes.string,
  moneyValid: PropTypes.bool,
  inputHandlerMoney: PropTypes.func,
  moneyError: PropTypes.string,
};

ReplenishmentAgreementPopup.defaultProps = {
  toggleOpenReplenishmentAgreementPopup: () => {},
  formHandler: () => {},
  inputSetStore: () => {},
  agreementNumber: '',
  agreementNumberValid: false,
  inputHandlerAgreementNumber: () => {},
  agreementNumberError: '',
  money: '',
  moneyValid: false,
  inputHandlerMoney: () => {},
  moneyError: '',
};

const enhancer = compose(
  inject(storeNames.BalanceStore),
  observer,
  withAgreementNumberState,
  withMoneyState,
  withBalanceInputSetStore,
  withHandlers(() => ({
    formHandler: ({
      BalanceStore,
      toggleOpenReplenishmentAgreementPopup,
      agreementNumber,
      money,
      setAgreementNumberError,
      setAgreementNumberValid,
      setMoneyValid,
      setMoneyError,
      agreementNumberValid,
      moneyValid,
    }) => async e => {
      e.preventDefault();

      if (!agreementNumber) {
        setAgreementNumberError('Укажите Номер Договора.');
        setAgreementNumberValid(false);
      } else {
        setAgreementNumberValid(true);
        setAgreementNumberError('');
      }
      if (!money) {
        setMoneyError('Укажите сумму');
        setMoneyValid(false);
      } else {
        setMoneyValid(true);
        setMoneyError('');
      }

      if (hasValue(agreementNumber) && hasValue(money) && agreementNumberValid && moneyValid) {
        const data = {
          amount: Number(money),
          contract_succeed: true,
        };
        await BalanceStore.balanceAgreement(agreementNumber, data);
        if (!BalanceStore.errors) {
          toggleOpenReplenishmentAgreementPopup();
        }
      }
    },
  })),
);

export default enhancer(ReplenishmentAgreementPopup);
