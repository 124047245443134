import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers, withState, withProps } from 'recompose';
import storeNames from '../../stores/storeNames';
import Popup from '../../components/Popup/Popup';
import InputField from '../../components/Inputs/InputField';
import Paragraph from '../../components/Typography/Paragraph';
import { hasValue } from '../../utils/validators';
import { getUrlObject } from '../../helpers/url';
import {
  Wrapper,
  InputWrapper,
  FormWrapper,
  Title,
  ButtonWrapper,
  MethodsWrapper,
  CardMethod,
  Img,
} from './PopupsStyled';
import { STATUS_AFTER_PAYMENT, getUrl } from '../../constants/statusPageUrls';
import ParagraphError from '../../components/Typography/ParagraphError';
import { COLOR_BLACK_LOGO, COLOR_ACTION_BLUE } from '../../constants/colors';
import { withBalanceInputSetStore, withMoneyState, withReasonState } from '../../utils/FormHocs';
import Button from '../../components/Buttons/Button';
import { ICON_CARD, ICON_SCORE, ICON_SCORE_ACTIVE, ICON_CARD_ACTIVE } from '../../constants/icons';
import { hasRefillByCard } from '../../constants/env';

const ReplenishmentPopup = ({
  money,
  toggleOpenReplenishmentBalancePopup,
  inputSetStore,
  formHandler,
  moneyError,
  inputHandlerMoney,
  setCardMethod,
  cardMethod,
}) => (
  <Popup
    width="600px"
    boxShadow="0px 7px 48px rgba(0, 0, 0, 0.08);"
    borderRadius="4px"
    closePopup={toggleOpenReplenishmentBalancePopup}
  >
    <Wrapper>
      <Title as="h2">Пополнение баланса</Title>
      <FormWrapper onSubmit={formHandler}>
        <InputWrapper>
          <Paragraph
            textColor={COLOR_BLACK_LOGO}
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Укажите сумму
          </Paragraph>
          <InputField
            placeholder="Сумма"
            name="money"
            value={money}
            onChange={value => {
              inputSetStore(value, inputHandlerMoney, 'money');
            }}
          />
          <ParagraphError>{moneyError}</ParagraphError>
        </InputWrapper>
        <InputWrapper>
          <Paragraph
            textColor={COLOR_BLACK_LOGO}
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Выберите способ пополнения
          </Paragraph>
          <MethodsWrapper>
            <CardMethod
              disabled={!hasRefillByCard}
              className={cardMethod === 'Card' ? 'active' : null}
              onClick={() => hasRefillByCard && setCardMethod('Card')}
              data-test-id="TEST_BUTTON_CARD"
            >
              <Img src={cardMethod === 'Card' ? ICON_CARD_ACTIVE : ICON_CARD} />
              <Paragraph
                textColor={COLOR_BLACK_LOGO}
                fontSize="16px"
                fontWeight="500"
                lineHeight="24px"
                textAlign="center"
              >
                Банковские карты
              </Paragraph>
            </CardMethod>
            <CardMethod
              className={cardMethod === 'Counter' ? 'active' : null}
              onClick={() => setCardMethod('Counter')}
              data-test-id="TEST_BUTTON_COUNTER"
            >
              <Img src={cardMethod === 'Counter' ? ICON_SCORE_ACTIVE : ICON_SCORE} />
              <Paragraph
                textColor={COLOR_BLACK_LOGO}
                fontSize="16px"
                fontWeight="500"
                lineHeight="24px"
                textAlign="center"
              >
                Счет для оплаты
              </Paragraph>
            </CardMethod>
          </MethodsWrapper>
        </InputWrapper>
        <ButtonWrapper>
          <Button testID="TEST_BUTTON_SEND" width="233px" type="submit">
            Оплатить
          </Button>
          <Button
            testID="TEST_BUTTON_CANCEL"
            width="233px"
            textColor={COLOR_ACTION_BLUE}
            boxShadow="none"
            backgroundColor="#fff"
            type="submit"
            sizes={{ margin: '0 0 0 20px' }}
            onClick={() => toggleOpenReplenishmentBalancePopup()}
          >
            Отмена
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  </Popup>
);

ReplenishmentPopup.propTypes = {
  inputSetStore: PropTypes.func,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  formHandler: PropTypes.func,
  money: PropTypes.string,
  cardMethod: PropTypes.string,
  moneyError: PropTypes.string,
  inputHandlerMoney: PropTypes.func,
  setCardMethod: PropTypes.func,
};

ReplenishmentPopup.defaultProps = {
  money: '',
  moneyError: '',
  cardMethod: '',
  inputSetStore: () => {},
  formHandler: () => {},
  inputHandlerMoney: () => {},
  setCardMethod: () => {},
};

const enchance = compose(
  inject(storeNames.BalanceStore, storeNames.IndicatorsStore),
  observer,
  withBalanceInputSetStore,
  withMoneyState,
  withReasonState,
  withState('cardMethod', 'setCardMethod', 'Counter'),
  withProps(({ BalanceStore }) => ({
    contract: BalanceStore.contract,
  })),
  withHandlers(() => ({
    formHandler: ({
      toggleOpenReplenishmentBalancePopup,
      money,
      moneyValid,
      setMoneyError,
      IndicatorsStore,
      cardMethod,
      BalanceStore,
      accountValue,
    }) => async e => {
      e.preventDefault();

      if (!accountValue.id) {
        IndicatorsStore.addErrorIndicators({
          message: 'У вас нет доступных счетов.',
          type: 'error',
        });
        return;
      }

      if (moneyValid && !hasValue(money)) {
        setMoneyError('Введите сумму');
        IndicatorsStore.addErrorIndicators({
          message: 'Проверьте правильность полей.',
          type: 'error',
        });
      }

      if (!cardMethod) {
        IndicatorsStore.addErrorIndicators({
          message: 'Выберите способ оплаты.',
          type: 'error',
        });
      }

      if (moneyValid && hasValue(money) && cardMethod === 'Card') {
        const collbackUrl = getUrlObject();
        const data = {
          amount: Number(money),
          is_return: false,
          redirect_url: `${getUrl()}${STATUS_AFTER_PAYMENT(collbackUrl.href, 'balance')}`,
          returned_payment_id: null,
        };

        localStorage.setItem('ACCOUNT_ID', accountValue.id);
        await BalanceStore.buyCard(accountValue.id, data);
        window.location.href = BalanceStore.buyUrl.yk_confirmation_url;
      }
      if (moneyValid && hasValue(money) && cardMethod === 'Counter') {
        const data = {
          amount: Number(money),
          is_return: false,
          redirect_url: ``,
          returned_payment_id: null,
          with_contract: true,
        };

        await BalanceStore.buyCard(accountValue.id, data);
        if (BalanceStore.contract !== '') {
          const link = document.createElement('a');
          link.href = BalanceStore.contract;
          link.download = 'contract.docx';
          link.click();
          await BalanceStore.deleteContract();
          IndicatorsStore.addErrorIndicators({
            message: 'Счет скачан, проверьте загрузки.',
            type: 'success',
          });
          toggleOpenReplenishmentBalancePopup();
        } else {
          IndicatorsStore.addErrorIndicators({
            message: 'Произошла ошибка.',
            type: 'error',
          });
        }
      }
    },
  })),
);

export default enchance(ReplenishmentPopup);
