import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Badge from '@material-ui/core/Badge';
import {
  Wrapper,
  WrapperSecond,
  RouterButton,
  InquiriesItem,
  ServicesItem,
  BalanceItem,
  // DocumentsItem,
  ProfileItem,
  AccountsItem,
  SharedInfoItem,
  UsersItem,
} from './NavigationStyled';
import { RoleRender } from '../../../../components/RoleComponents';
import {
  withFetchUserInDidMount,
  withUserData,
  withActiveValue,
  withFetchAccessRequestCountInDidMount,
} from '../../../../utils/hocs';
import {
  SUPER_ADMIN_ROLE,
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_ACCOUNTANT,
  CUSTOMER_USER,
} from '../../../../constants/roles';
import {
  CUSTOMER_SOLE_PROPRIETOR,
  CUSTOMER_LEGAL_ENTITY,
} from '../../../../constants/customerTypes';
import { hasDisclosure } from '../../../../constants/env';

const Navigation = ({
  confirmed,
  isAdmin,
  userIsLoading,
  userData,
  accountValue,
  accessRequestCount,
}) => {
  return (
    <>
      {userData && userData.id && !userIsLoading && (
        <Wrapper>
          {accountValue.id && (
            <RoleRender allowedRoles={[CUSTOMER_USER]}>
              <InquiriesItem>
                <RouterButton
                  className={confirmed ? 'hide' : null}
                  activeClassName="active"
                  to="/requests"
                  data-test-id="TEST_BUTTON_REQUESTS"
                >
                  Запросы
                </RouterButton>
              </InquiriesItem>
            </RoleRender>
          )}
          <RoleRender allowedRoles={[CUSTOMER_ACCOUNTANT, CUSTOMER_USER, SUPER_ADMIN_ROLE]}>
            <ServicesItem>
              <RouterButton
                className={confirmed ? 'hide' : null}
                data-test-id="TEST_BUTTON_SERVICES"
                activeClassName="active"
                to="/services"
              >
                Услуги
              </RouterButton>
            </ServicesItem>
          </RoleRender>
          <RoleRender allowedRoles={[CUSTOMER_ACCOUNTANT, CUSTOMER_USER, SUPER_ADMIN_ROLE]}>
            <BalanceItem>
              <RouterButton
                className={confirmed ? 'hide' : null}
                data-test-id="TEST_BUTTON_BALANCE"
                activeClassName="active"
                to="/balance"
              >
                Баланс
              </RouterButton>
            </BalanceItem>
          </RoleRender>
          {/* <RoleRender allowedRoles={[ALLOW_ALL_ROLE]}>
            <DocumentsItem>
              <RouterButton
                className={confirmed ? 'hide' : null}
                data-test-id="TEST_BUTTON_DOCUMENTS"
                activeClassName="active"
                to="/documents"
              >
                Документы
              </RouterButton>
            </DocumentsItem>
          </RoleRender> */}
        </Wrapper>
      )}
      <WrapperSecond>
        <RoleRender>
          <ProfileItem>
            <RouterButton
              className={confirmed ? 'hide' : null}
              activeClassName="active"
              to="/profile"
              data-test-id="TEST_BUTTON_PROFILE"
            >
              Профиль
            </RouterButton>
          </ProfileItem>
        </RoleRender>
        {isAdmin && (
          <>
            <RoleRender allowedRoles={[SUPER_ADMIN_ROLE]}>
              <ProfileItem>
                <RouterButton
                  className={confirmed ? 'hide' : null}
                  activeClassName="active"
                  data-test-id="TEST_BUTTON_COMPANIES"
                  to="/companies"
                >
                  Клиенты
                </RouterButton>
              </ProfileItem>
            </RoleRender>
            <RoleRender allowedRoles={[SUPER_ADMIN_ROLE]}>
              <ProfileItem>
                <RouterButton
                  className={confirmed ? 'hide' : null}
                  activeClassName="active"
                  data-test-id="TEST_BUTTON_COMPANIES"
                  to="/superaccounts"
                >
                  Все счета
                </RouterButton>
              </ProfileItem>
            </RoleRender>
          </>
        )}
        <>
          <RoleRender
            checkType
            allowedRoles={[
              CUSTOMER_ADMIN_ROLE,
              SUPER_ADMIN_ROLE,
              CUSTOMER_ACCOUNTANT,
              CUSTOMER_USER,
            ]}
            allowedTypes={[CUSTOMER_LEGAL_ENTITY, CUSTOMER_SOLE_PROPRIETOR]}
          >
            <UsersItem>
              <RouterButton
                className={confirmed ? 'hide' : null}
                activeClassName="active"
                to="/users"
                data-test-id="TEST_BUTTON_USERS"
              >
                Пользователи
              </RouterButton>
            </UsersItem>
          </RoleRender>

          {hasDisclosure && (
            <RoleRender
              allowedRoles={[
                CUSTOMER_ADMIN_ROLE,
                CUSTOMER_ACCOUNTANT,
                SUPER_ADMIN_ROLE,
                CUSTOMER_USER,
              ]}
            >
              <SharedInfoItem>
                <RouterButton
                  className={confirmed ? 'hide' : null}
                  activeClassName="active"
                  to="/disclosure"
                  data-test-id="TEST_BUTTON_SHAREDINFO"
                >
                  Раскрытие информации
                  <Badge
                    style={{ padding: '0px 7px 32px' }}
                    color="secondary"
                    badgeContent={accessRequestCount}
                  />
                </RouterButton>
              </SharedInfoItem>
            </RoleRender>
          )}

          <RoleRender
            allowedRoles={[
              CUSTOMER_ADMIN_ROLE,
              CUSTOMER_ACCOUNTANT,
              SUPER_ADMIN_ROLE,
              CUSTOMER_USER,
            ]}
          >
            <AccountsItem>
              <RouterButton
                className={confirmed ? 'hide' : null}
                activeClassName="active"
                to="/accounts"
                data-test-id="TEST_BUTTON_ACCOUNTS"
              >
                Лицевые счета
              </RouterButton>
            </AccountsItem>
          </RoleRender>
        </>
      </WrapperSecond>
    </>
  );
};

Navigation.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  userIsLoading: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  accountValue: PropTypes.object,
  accessRequestCount: PropTypes.number,
};

Navigation.defaultProps = {
  userIsLoading: false,
  accountValue: {},
  accessRequestCount: 0,
};

const enhance = compose(
  withFetchUserInDidMount,
  withFetchAccessRequestCountInDidMount,
  withUserData,
  withActiveValue(false, true, false, false, false, false),
);

export default enhance(Navigation);
