// import logger from '../logger';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withProps, lifecycle } from 'recompose';
import Badge from '@material-ui/core/Badge';
import { withBoolState, withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import Typography from '../../components/Typography/Paragraph';
import { Wrapper, Title, Button, Top } from './SharedInfoStyled';
import Table from './Table/Table';
import storeNames from '../../stores/storeNames';
// import { CUSTOMER_LEGAL_ENTITY } from '../../constants/customerTypes';
// import AddSharedInfoPopup from './Popups/AddSharedInfoPopup/AddSharedInfoPopup';
// import EditSharedInfoPopup from './Popups/EditSharedInfoPopup/EditSharedInfoPopup';
import EditQuestionaryPopup from './Popups/EditQuestionaryPopup/EditQuestionaryPopup';
import AccessPopup from './Popups/AccessPopup/AccessPopup';
// import DialogPopup from '../Popups/DialogPopup';

const SharedInfo = ({
  /* addSharedInfoPopup,
  toggleAddSharedInfoPopup,
  editSharedInfoPopup,
  toggleEditSharedInfoPopup, */
  //  offset,
  //  itemId,
  editQuestionaryPopup,
  toggleEditQuestionaryPopup,
  accessPopup,
  toggleAccessPopup,
  setFormId,
  // setFormTitle,
  /* confirmed,
  toggleConfirmed,
  setItemId,
  
  userId,
  userType,
  isCustomerAdmin,
  
  setOffset, */
  // cryptoPlaginFlag,
  cryptoReady,
  cryptoIsPluginInstalled,
  cryptoIsProviderInstalled,
  accessRequestCount,
  // cryptoGetInfo,
}) => {
  //  logger.debug('Rendering SharedInfo');
  return (
    <>
      <Wrapper>
        <Top>
          <Title as="h2">Раскрытие информации</Title>
          <Badge
            color="secondary"
            badgeContent={accessRequestCount}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Button
              data-test-id="TEST_BUTTON_ACCESS_SHARED_INFO"
              onClick={() => {
                setFormId('');
                // setFormTitle('');
                toggleAccessPopup();
              }}
            >
              Управление доступом
            </Button>
          </Badge>
          {/* (userType === CUSTOMER_LEGAL_ENTITY && isCustomerAdmin && (
          <Button data-test-id="TEST_BUTTON_ADD_SHARED_INFO" onClick={() => toggleAddSharedInfoPopup()}>
            Добавить счет
          </Button>
        )) ||
        null */}
        </Top>
        {cryptoIsPluginInstalled ? (
          <Typography
            margin="0px 0px 10px 0px"
            textColor="#0ABA0A"
            fontSize="14px"
            lineHeight="14px"
            textAlign="right"
          >
            Плагин цифровой подписи доступен
          </Typography>
        ) : (
          cryptoReady && (
            <Typography
              margin="0px 0px 20px 0px"
              textColor="#EA0A0A"
              fontSize="14px"
              lineHeight="14px"
              textAlign="right"
            >
              Плагин цифровой подписи не установлен &nbsp;
              <a
                href="https://www.cryptopro.ru/products/cades/plugin"
                rel="noopener noreferrer"
                target="_blank"
              >
                (установить)
              </a>
            </Typography>
          )
        )}
        {cryptoIsPluginInstalled && cryptoIsProviderInstalled && (
          <Typography
            margin="0px 0px 10px 0px"
            textColor="#0ABA0A"
            fontSize="14px"
            lineHeight="14px"
            textAlign="right"
          >
            Крипто-провайдер доступен
          </Typography>
        )}
        {cryptoIsPluginInstalled && !cryptoIsProviderInstalled && (
          <Typography
            margin="0px 0px 20px 0px"
            textColor="#EA0A0A"
            fontSize="18px"
            lineHeight="18px"
            textAlign="right"
          >
            Крипто-провайдер не установлен &nbsp;
            <a
              href="https://www.cryptopro.ru/products/cades/plugin"
              rel="noopener noreferrer"
              target="_blank"
            >
              (установить)
            </a>
          </Typography>
        )}
        <Table
          editQuestionaryPopup={editQuestionaryPopup}
          toggleEditQuestionaryPopup={toggleEditQuestionaryPopup}
          toggleAccessPopup={toggleAccessPopup}
        />
        {/*
        setOffset={setOffset}
        confirmed={confirmed}
        toggleConfirmed={toggleConfirmed}
        editSharedInfoPopup={editSharedInfoPopup}
        toggleEditSharedInfoPopup={toggleEditSharedInfoPopup}
        setItemId={setItemId}
        userId={userId}
        addSharedInfoPopup={addSharedInfoPopup}
        offset={offset}
        setOffset={setOffset}
        /> */}
      </Wrapper>
      {/* addSharedInfoPopup === true ? (
      <AddSharedInfoPopup toggleAddSharedInfoPopup={toggleAddSharedInfoPopup} offset={offset} />
    ) : null}
    {editSharedInfoPopup === true ? (
      <EditSharedInfoPopup toggleEditSharedInfoPopup={toggleEditSharedInfoPopup} id={itemId} />
    ) : null */}
      {editQuestionaryPopup === true ? (
        <EditQuestionaryPopup toggleEditQuestionaryPopup={toggleEditQuestionaryPopup} />
      ) : null}
      {accessPopup === true ? <AccessPopup toggleAccessPopup={toggleAccessPopup} /> : null}

      {/* <DialogPopup /> */}
    </>
  );
};

SharedInfo.propTypes = {
  /* addSharedInfoPopup: PropTypes.bool.isRequired,
  editSharedInfoPopup: PropTypes.bool.isRequired,
  toggleAddSharedInfoPopup: PropTypes.func.isRequired,
  toggleEditSharedInfoPopup: PropTypes.func.isRequired, */
  //  itemId: PropTypes.number,
  // offset: PropTypes.number,
  editQuestionaryPopup: PropTypes.bool,
  toggleEditQuestionaryPopup: PropTypes.func,
  accessPopup: PropTypes.bool,
  toggleAccessPopup: PropTypes.func,
  setFormId: PropTypes.func.isRequired,
  // setFormTitle: PropTypes.func.isRequired,
  /* confirmed: PropTypes.bool,
  isCustomerAdmin: PropTypes.bool.isRequired,
  setItemId: PropTypes.func.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  userId: PropTypes.number,
  setOffset: PropTypes.func, */
  // cryptoPlaginFlag: PropTypes.bool,
  cryptoReady: PropTypes.bool.isRequired,
  cryptoIsPluginInstalled: PropTypes.bool.isRequired,
  cryptoIsProviderInstalled: PropTypes.bool.isRequired,
  accessRequestCount: PropTypes.number.isRequired,
};

SharedInfo.defaultProps = {
  /* addSharedInfoPopup: false,
  editSharedInfoPopup: false,
  toggleAddSharedInfoPopup: () => {},
  toggleEditSharedInfoPopup: () => {}, */
  editQuestionaryPopup: false,
  toggleEditQuestionaryPopup: () => {},
  accessPopup: false,
  toggleAccessPopup: () => {},
  /* confirmed: false,
  itemId: undefined,
  userId: undefined,
  offset: undefined,
  setOffset: () => {}, */
  // cryptoPlaginFlag: false,
};

/* const enhance = function(...args) {
  console.log('----------------')
  //console.log(args)
  //console.log('----------------')
  return withFetchUserInDidMount(
    withUserData(
      withBoolState('addSharedInfoPopup', false)(
        withBoolState('confirmed', false)(
          withBoolState('editSharedInfoPopup', false)(
            withState('itemId', 'setItemId', undefined)(
              withState('offset', 'setOffset', 0)(...args)
            )
          )
        )
      )
    )
  )
  //console.log(a)
  //return a
} */
const enhance = compose(
  inject(storeNames.SharedInfoStore),
  observer,
  withProps(({ SharedInfoStore }) => ({
    setFormId: SharedInfoStore.setFormId,
    cryptoReady: SharedInfoStore.cryptoReady,
    cryptoGetInfo: SharedInfoStore.cryptoGetInfo,
    cryptoIsPluginInstalled: SharedInfoStore.cryptoIsPluginInstalled,
    cryptoIsProviderInstalled: SharedInfoStore.cryptoIsProviderInstalled,
    accessRequestCount: SharedInfoStore.accessRequestCount,
    // setFormTitle: SharedInfoStore.setFormTitle,
  })),
  withFetchUserInDidMount,
  withUserData,
  /* withBoolState('addSharedInfoPopup', false),
  withBoolState('confirmed', false),
  withBoolState('editSharedInfoPopup', false), */
  withBoolState('editQuestionaryPopup', false),
  withBoolState('accessPopup', false),
  // withBoolState('cryptoPlaginFlag', false),
  /* withState('itemId', 'setItemId', undefined),
  withState('offset', 'setOffset', 0), */
  lifecycle({
    async componentDidMount() {
      const { SharedInfoStore } = this.props;
      // let { cryptoPlaginFlag } = this.props;

      await SharedInfoStore.fetchAccessRequestCount();
      await SharedInfoStore.cryptoGetInfo();
    },
  }),
);

export default enhance(SharedInfo);
