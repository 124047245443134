import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle, withProps, withHandlers } from 'recompose';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Wrapper,
  TableContainer,
  Tr,
  TrTop,
  Th,
  Td,
  Thead,
  Tbody,
  TdName,
  TdButton,
  ButtonEdit,
  CheckBoxWrapper,
  Text,
  Img,
} from './TableStyled';
import { withUserData, withFetchUserInDidMount } from '../../../utils/hocs';
import storeNames from '../../../stores/storeNames';
import CheckboxTable from '../../../components/CheckBoxes/CheckBoxTable';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import { RoleRedirect } from '../../../components/RedirectComponents';
import {
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_ACCOUNTANT,
  SUPER_ADMIN_ROLE,
  CUSTOMER_USER,
} from '../../../constants/roles';
import { ICON_EMAIL_NOVERIFID, ICON_EMAIL_VERIFID } from '../../../constants/icons';
import { RoleRender } from '../../../components/RoleComponents';
import Paragraph from '../../../components/Typography/Paragraph';
import { CUSTOMER_LEGAL_ENTITY, CUSTOMER_SOLE_PROPRIETOR } from '../../../constants/customerTypes';
import { getDateForShow } from '../../../helpers/formatDate';
import { checkPhoneNumber } from '../../../utils/validators';
import { getAccessStatus } from '../../../helpers/roles';

const Table = ({
  users,
  toggleEditUserPopup,
  setItemId,
  isLoading,
  fetchUsers,
  confirmed,
  toggleConfirmed,
  deleteUser,
  hasMoreItems,
  addFilter,
  isCustomerAdmin,
}) => {
  let usersActive = [];
  let items = [];
  if (users.length > 0) {
    usersActive = users.filter(item => !item.blocked);
    items = confirmed ? usersActive : users;
  }
  const checkRoles = role => {
    if (role === 'CUSTOMER_ACCOUNTANT') {
      return 'Бухгалтер';
    }
    if (role === 'CUSTOMER_USER') {
      return 'Пользователь';
    }
    if (role === 'SUPER_ADMIN') {
      return 'Супер Администратор';
    }
    return 'Администратор';
  };
  return (
    <Wrapper>
      {(items && items.length && (
        <RoleRedirect
          checkType
          allowedRoles={[CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT, CUSTOMER_USER, SUPER_ADMIN_ROLE]}
          allowedTypes={[CUSTOMER_LEGAL_ENTITY, CUSTOMER_SOLE_PROPRIETOR]}
        />
      )) || <></>}
      <RoleRender allowedRoles={[CUSTOMER_ADMIN_ROLE]}>
        <CheckBoxWrapper>
          <CheckBox testID="TEST_CHECKBOX" checked={confirmed} onChange={() => toggleConfirmed()}>
            <Text>Показывать только активных клиентов</Text>
          </CheckBox>
        </CheckBoxWrapper>
      </RoleRender>
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetchUsers()}
        hasMore={hasMoreItems}
        style={{ overflow: 'auto' }}
      >
        <TableContainer>
          <Thead>
            <TrTop>
              <Th
                className={items.length > 1 ? 'active' : ''}
                onClick={() => (items.length > 1 ? addFilter('sname') : null)}
              >
                ФИО
              </Th>
              <Th>Роль</Th>
              <Th
                className={items.length > 1 ? 'active' : ''}
                onClick={() => (items.length > 1 ? addFilter('phone') : null)}
              >
                Телефон
              </Th>
              <Th
                className={items.length > 1 ? 'active' : ''}
                onClick={() => (items.length > 1 ? addFilter('email') : null)}
              >
                E-mail
              </Th>
              <Th>Лицевой счёт</Th>
              <Th
                className={items.length > 1 ? 'active' : ''}
                onClick={() => (items.length > 1 ? addFilter('created_at') : null)}
              >
                Период действия{' '}
              </Th>
              <Th />
              {(isCustomerAdmin && (
                <>
                  <Th
                    className={items.length > 1 ? 'active' : ''}
                    onClick={() => (items.length > 1 ? addFilter('blocked') : null)}
                  >
                    Статус
                  </Th>
                </>
              )) ||
                null}
            </TrTop>
          </Thead>
          <Tbody>
            {items &&
              items.map(item => (
                <Tr key={item.id}>
                  <TdName>{`${item.sname} ${item.fname} ${item.mname}`}</TdName>
                  <Td>
                    {item.roles
                      ? item.roles.map((role, idx) => (
                          <Paragraph key={idx}>{checkRoles(role.role_name)}</Paragraph>
                        ))
                      : null}
                  </Td>
                  <Td>{checkPhoneNumber(item.phone)}</Td>
                  <Td>
                    <Img src={item.email_confirmed ? ICON_EMAIL_VERIFID : ICON_EMAIL_NOVERIFID} />
                    {item.email}
                  </Td>
                  <Td>{item.account && item.account.full_name ? item.account.full_name : ''}</Td>
                  <Td>
                    {item.from_time && item.to_time && (
                      <>
                        {getDateForShow(item.from_time)} &#8212; {getDateForShow(item.to_time)}
                      </>
                    )}
                    {!item.from_time && item.to_time && <>&#8212; {getDateForShow(item.to_time)}</>}
                    {!item.to_time && item.from_time && (
                      <>{getDateForShow(item.from_time)} &#8212;</>
                    )}
                    {!item.to_time && !item.from_time && <>&#8212;</>}
                  </Td>
                  <RoleRender allowedRoles={[CUSTOMER_ADMIN_ROLE]}>
                    <TdButton>
                      <ButtonEdit
                        data-test-id="TEST_BUTTON_EDIT"
                        onClick={() => {
                          setItemId(item.id);
                          toggleEditUserPopup();
                        }}
                      >
                        edit
                      </ButtonEdit>
                    </TdButton>
                  </RoleRender>
                  {(isCustomerAdmin && (
                    <Td width="130px">
                      <CheckboxTable checked={!item.blocked} onChange={() => deleteUser(item)}>
                        {!item.blocked ? 'Активный' : 'Неактивный'}
                      </CheckboxTable>
                    </Td>
                  )) ||
                    null}
                </Tr>
              ))}
          </Tbody>
        </TableContainer>
      </InfiniteScroll>
      {isLoading && <div>Идёт обновление данных</div>}
    </Wrapper>
  );
};

Table.propTypes = {
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  confirmed: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  toggleEditUserPopup: PropTypes.func.isRequired,
  setItemId: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  fetchUsers: PropTypes.func,
  deleteUser: PropTypes.func,
  addFilter: PropTypes.func,
  isCustomerAdmin: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  users: [],
  isLoading: false,
  hasMoreItems: undefined,
  fetchUsers: () => {},
  deleteUser: () => {},
  addFilter: () => {},
};

const enhance = compose(
  inject(storeNames.UserStore, storeNames.ProfileStore),
  withFetchUserInDidMount,
  withUserData,
  observer,
  withState('offset', 'setOffset', 0),
  withProps(({ UserStore }) => ({
    users: UserStore.users,
    isLoading: UserStore.isBusy,
    fetchMyUsers: UserStore.fetchMyUsers,
    getCustomer: UserStore.getCustomer,
    blockUser: UserStore.blockUser,
    hasMoreItems: UserStore.hasMoreItems,
    addSortAndOrder: UserStore.addSortAndOrder,
  })),
  withHandlers(({ fetchMyUsers, blockUser }) => ({
    addFilter: ({ addSortAndOrder, offset, setOffset }) => async value => {
      addSortAndOrder(value);
      const qnt = offset >= 15 ? offset : 15;
      const users = await fetchMyUsers({ qnt, offset: 0 });
      setOffset(users.length);
    },
    fetchUsers: ({ setOffset, oneOffset, offset, init }) => async () => {
      if (!init || oneOffset) return;
      const users = await fetchMyUsers({ offset });
      setOffset(users.length);
    },
    deleteUser: ({ setOffset, offset }) => async item => {
      await blockUser(item.id, !item.blocked);
      const qnt = offset >= 15 ? offset : 15;
      const users = await fetchMyUsers({ qnt, offset: 0 });
      setOffset(users.length);
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { UserStore, offset, setOffset, ProfileStore, setOneOffset, setInit } = this.props;
      const { fetchMyUsers, getCustomer } = UserStore;
      const user = await ProfileStore.fetchUser();
      const userRoles = (user && user.roles && user.roles.map(item => item.role_name)) || undefined;
      const hasAdmin = !getAccessStatus({ userRoles, allowedRoles: [CUSTOMER_ADMIN_ROLE] });
      const hasUser = !getAccessStatus({
        userRoles,
        allowedRoles: [CUSTOMER_ADMIN_ROLE, SUPER_ADMIN_ROLE],
      });
      if ((user.customer_id === 0 && hasAdmin) || hasUser) {
        if (user.id !== null) {
          await getCustomer(user.id);
          setOneOffset(1);
        }
      } else {
        const users = await fetchMyUsers({ offset });
        setOffset(users.length);
      }
      setInit(true);
    },
    componentWillUnmount() {
      const { UserStore } = this.props;
      UserStore.addSortAndOrder('');
      UserStore.deleteOrder();
    },
  }),
);

export default enhance(Table);
