import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withProps, withHandlers, withState } from 'recompose';
import { useTranslation } from 'react-i18next';
import { withBoolState, withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import { validateRs, validateKs } from '../../utils/entity-validators';
import { ProfileForm, Title, ProfileContent, Button } from './ProfileStyled';
import BottomContent from './BottomContent/BottomContent';
import LegalEntity from './ProfileUsers/LegalEntity';
import IndividualEntrepreneur from './ProfileUsers/IndividualEntrepreneur';
import Individual from './ProfileUsers/Individual';
import storeNames from '../../stores/storeNames';
import { RoleRender } from '../../components/RoleComponents';
import { CUSTOMER_ADMIN_ROLE } from '../../constants/roles';
import {
  CUSTOMER_INDIVIDUAL,
  CUSTOMER_SOLE_PROPRIETOR,
  CUSTOMER_LEGAL_ENTITY,
} from '../../constants/customerTypes';

const Profile = ({
  formHandler,
  setIsValidIndividual,
  isValidIndividual,
  setIsValidIndividualEntrepreneur,
  setIsValidLegalEntity,
  isValidIndividualEntrepreneur,
  isValidLegalEntity,
  rsError,
  ksError,
  setCurrentPasswordError,
  currentPasswordError,
  newPasswordError,
  confirmPasswordError,
  userData,
  userIsLoading,
  userType,
}) => {
  const { t } = useTranslation();
  return (
    <ProfileForm onSubmit={formHandler}>
      {userData && userData.id && !userIsLoading && (
        <>
          <Title as="h2">{t('profile.title')}</Title>
          <>
            <ProfileContent>
              {(userType === CUSTOMER_LEGAL_ENTITY && (
                <LegalEntity
                  isValidLegalEntity={isValidLegalEntity}
                  setIsValidLegalEntity={setIsValidLegalEntity}
                  rsError={rsError.message}
                  ksError={ksError.message}
                />
              )) ||
                null}
              {(userType === CUSTOMER_INDIVIDUAL && (
                <Individual
                  setIsValidIndividual={setIsValidIndividual}
                  isValidIndividual={isValidIndividual}
                />
              )) ||
                null}
              {(userType === CUSTOMER_SOLE_PROPRIETOR && (
                <IndividualEntrepreneur
                  isValidIndividualEntrepreneur={isValidIndividualEntrepreneur}
                  setIsValidIndividualEntrepreneur={setIsValidIndividualEntrepreneur}
                  rsError={rsError.message}
                  ksError={ksError.message}
                />
              )) ||
                null}
            </ProfileContent>
          </>
          {userType === CUSTOMER_INDIVIDUAL ? (
            <BottomContent
              setCurrentPasswordError={setCurrentPasswordError}
              currentPasswordError={currentPasswordError}
              newPasswordError={newPasswordError}
              confirmPasswordError={confirmPasswordError}
            />
          ) : null}
          <RoleRender allowedRoles={[CUSTOMER_ADMIN_ROLE]}>
            <Button
              width="192px"
              type="submit"
              data-test-id="TEST_BUTTON_SAVE_ID"
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex="99"
            >
              Сохранить
            </Button>
          </RoleRender>
        </>
      )}
    </ProfileForm>
  );
};

Profile.propTypes = {
  rsError: PropTypes.object,
  ksError: PropTypes.object,
  formHandler: PropTypes.func,
  currentPasswordError: PropTypes.string,
  newPasswordError: PropTypes.string,
  confirmPasswordError: PropTypes.string,
  setIsValidIndividual: PropTypes.func.isRequired,
  isValidIndividual: PropTypes.bool.isRequired,
  isValidIndividualEntrepreneur: PropTypes.bool.isRequired,
  setIsValidIndividualEntrepreneur: PropTypes.func.isRequired,
  isValidLegalEntity: PropTypes.bool.isRequired,
  setIsValidLegalEntity: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userIsLoading: PropTypes.bool,
  userType: PropTypes.string.isRequired,
  setCurrentPasswordError: PropTypes.func,
};

Profile.defaultProps = {
  formHandler: () => {},
  setCurrentPasswordError: () => {},
  currentPasswordError: '',
  newPasswordError: '',
  confirmPasswordError: '',
  rsError: undefined,
  ksError: undefined,
  userIsLoading: false,
};

const enhancer = compose(
  inject(
    storeNames.ProfileStore,
    storeNames.AccountStore,
    storeNames.UserStore,
    storeNames.IndicatorsStore,
  ),
  observer,
  withFetchUserInDidMount,
  withUserData,
  withProps(({ ProfileStore }) => ({
    saveUser: ProfileStore.saveUser,
    saveIndividualUser: ProfileStore.saveIndividualUser,
  })),
  withBoolState('isValidIndividual', false),
  withBoolState('isValidIndividualEntrepreneur', false),
  withBoolState('isValidLegalEntity', false),
  withState('rsError', 'setRsError', { code: '', message: '' }),
  withState('ksError', 'setKsError', { code: '', message: '' }),
  withState('currentPasswordError', 'setCurrentPasswordError', ''),
  withState('newPasswordError', 'setNewPasswordError', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  withHandlers(() => ({
    formHandler: ({
      isValidIndividualEntrepreneur,
      ProfileStore,
      IndicatorsStore,
      AccountStore,
      saveUser,
      rsError,
      setCurrentPasswordError,
      setNewPasswordError,
      setConfirmPasswordError,
      setRsError,
      setKsError,
      ksError,
      userType,
      userData,
      isValidLegalEntity,
      isValidIndividual,
      saveIndividualUser,
    }) => async e => {
      e.preventDefault();
      // ВАЛИДАЦИЯ ПАРОЛЯ //
      let passwordIsValid = true;
      const regexValid = /^[a-zA-Z0-9]+$/;
      if (ProfileStore.userData.currentPassword) {
        setCurrentPasswordError('');
        if (!ProfileStore.userData.newPassword) {
          passwordIsValid = false;
          setNewPasswordError('Введите новый пароль');
        } else {
          setNewPasswordError('');
          if (ProfileStore.userData.newPassword.length < 8) {
            passwordIsValid = false;
            setNewPasswordError('Пароль должен быть не меньше 8 символов');
          } else {
            setNewPasswordError('');
            if (!ProfileStore.userData.confirmPassword) {
              passwordIsValid = false;
              setConfirmPasswordError('Повторите новый пароль');
            } else {
              setNewPasswordError('');
              if (ProfileStore.userData.newPassword !== ProfileStore.userData.confirmPassword) {
                passwordIsValid = false;
                setConfirmPasswordError('Пароли не совпадают');
              } else {
                setConfirmPasswordError('');
                if (!regexValid.test(ProfileStore.userData.newPassword)) {
                  passwordIsValid = false;
                  setNewPasswordError(
                    'Пароль должен состоять из цифр и символов латинского алфавита',
                  );
                } else {
                  ProfileStore.setProfileStore({ [`password`]: ProfileStore.userData.newPassword });
                  passwordIsValid = true;
                }
              }
            }
          }
        }
      }
      if (ProfileStore.userData.newPassword && !ProfileStore.userData.currentPassword) {
        passwordIsValid = false;
        setCurrentPasswordError('Введите текущий пароль');
      }
      if (ProfileStore.userData.confirmPassword && !ProfileStore.userData.currentPassword) {
        passwordIsValid = false;
        setCurrentPasswordError('Введите текущий пароль');
      }
      // ВАЛИДАЦИЯ ПАРОЛЯ //
      if (
        userType === CUSTOMER_LEGAL_ENTITY &&
        (!isValidLegalEntity || passwordIsValid === false)
      ) {
        IndicatorsStore.addErrorIndicators({
          message: 'Проверьте правильность полей.',
          type: 'error',
        });
      }
      if (userType === CUSTOMER_INDIVIDUAL && (!isValidIndividual || passwordIsValid === false)) {
        IndicatorsStore.addErrorIndicators({
          message: 'Проверьте правильность полей.',
          type: 'error',
        });
      }
      if (
        userType === CUSTOMER_SOLE_PROPRIETOR &&
        (!isValidIndividualEntrepreneur || passwordIsValid === false)
      ) {
        IndicatorsStore.addErrorIndicators({
          message: 'Проверьте правильность полей.',
          type: 'error',
        });
      }
      if (userType === CUSTOMER_LEGAL_ENTITY) {
        const resultRs = validateRs(ProfileStore.userData.rs, ProfileStore.userData.bik, rsError);
        const resultKs = validateKs(ProfileStore.userData.ks, ProfileStore.userData.bik, ksError);
        setRsError({ code: '', message: rsError.message });
        if (resultRs === true) {
          setRsError({ code: '', message: '' });
        }

        setKsError({ code: '', message: ksError.message });
        if (resultKs === true) {
          setKsError({ code: '', message: '' });
        }
        if (
          userType === CUSTOMER_LEGAL_ENTITY &&
          isValidLegalEntity &&
          // resultKs === true &&
          // resultRs === true &&
          passwordIsValid === true
        ) {
          saveUser(
            {
              fname: ProfileStore.userData.customer.contact_person_fname,
              sname: ProfileStore.userData.customer.contact_person_sname,
              customerEmail: ProfileStore.userData.customer.email,
              email: ProfileStore.userData.email,
              ogrn: ProfileStore.userData.ogrn,
              phone:
                ProfileStore.userData.customer.phone === ''
                  ? null
                  : ProfileStore.userData.customer.phone,
              login: ProfileStore.userData.login,
              full_name: ProfileStore.userData.full_name,
              post_address: ProfileStore.userData.customer.post_address,
              inn: ProfileStore.userData.inn,
              kpp: ProfileStore.userData.kpp,
              bank: ProfileStore.userData.bank_name,
              bik: ProfileStore.userData.bik,
              rs: ProfileStore.userData.rs,
              ks: ProfileStore.userData.ks,
              confirmed: ProfileStore.userData.confirmed,
              // orderConfirmed: ProfileStore.userData.orderConfirmed
              //   ? ProfileStore.userData.orderConfirmed
              //   : false,
              // systemNotificationsConfirmed: ProfileStore.userData.systemNotificationsConfirmed
              //   ? ProfileStore.userData.systemNotificationsConfirmed
              //   : false,
              // smsConfirmed: ProfileStore.userData.smsConfirmed
              //   ? ProfileStore.userData.smsConfirmed
              //   : false,
              // emailConfirmed: ProfileStore.userData.emailConfirmed
              //   ? ProfileStore.userData.emailConfirmed
              //   : false,
              // depositInformationConfirmed: ProfileStore.userData.depositInformationConfirmed
              //   ? ProfileStore.userData.depositInformationConfirmed
              //   : false,
              // newsConfirmed: ProfileStore.userData.newsConfirmed
              //   ? ProfileStore.userData.newsConfirmed
              //   : false,
              // scheduledNotificationsConfirmed: ProfileStore.userData.scheduledNotificationsConfirmed
              //   ? ProfileStore.userData.scheduledNotificationsConfirmed
              //   : false,
              old_password: ProfileStore.userData.currentPassword
                ? ProfileStore.userData.currentPassword
                : null,
              new_password: ProfileStore.userData.newPassword
                ? ProfileStore.userData.newPassword
                : null,
              personalAccounts: AccountStore.accounts ? AccountStore.accounts : null,
            },
            userData.id,
          );
        }
      }
      if (userType === CUSTOMER_INDIVIDUAL && isValidIndividual && passwordIsValid === true) {
        saveIndividualUser(
          {
            sname: ProfileStore.userData.sname,
            fname: ProfileStore.userData.fname,
            mname: ProfileStore.userData.mname,
            email: ProfileStore.userData.email,
            login: ProfileStore.userData.login,
            phone: ProfileStore.userData.phone === '' ? null : ProfileStore.userData.phone,
            post_address: ProfileStore.userData.post_address,
            confirmed: ProfileStore.userData.confirmed,
            birth_date: userData.birth_date,
            // orderConfirmed: ProfileStore.userData.orderConfirmed
            //   ? ProfileStore.userData.orderConfirmed
            //   : false,
            // systemNotificationsConfirmed: ProfileStore.userData.systemNotificationsConfirmed
            //   ? ProfileStore.userData.systemNotificationsConfirmed
            //   : false,
            // smsConfirmed: ProfileStore.userData.smsConfirmed
            //   ? ProfileStore.userData.smsConfirmed
            //   : false,
            // emailConfirmed: ProfileStore.userData.emailConfirmed
            //   ? ProfileStore.userData.emailConfirmed
            //   : false,
            // depositInformationConfirmed: ProfileStore.userData.depositInformationConfirmed
            //   ? ProfileStore.userData.depositInformationConfirmed
            //   : false,
            // newsConfirmed: ProfileStore.userData.newsConfirmed
            //   ? ProfileStore.userData.newsConfirmed
            //   : false,
            // scheduledNotificationsConfirmed: ProfileStore.userData.scheduledNotificationsConfirmed
            //   ? ProfileStore.userData.scheduledNotificationsConfirmed
            //   : false,
            old_password: ProfileStore.userData.currentPassword
              ? ProfileStore.userData.currentPassword
              : null,
            new_password: ProfileStore.userData.newPassword
              ? ProfileStore.userData.newPassword
              : null,
          },
          userData.id,
        );
      }
      if (userType === CUSTOMER_SOLE_PROPRIETOR) {
        const resultRs = validateRs(ProfileStore.userData.rs, ProfileStore.userData.bik, rsError);
        const resultKs = validateKs(ProfileStore.userData.ks, ProfileStore.userData.bik, ksError);
        setRsError({ code: '', message: rsError.message });
        if (resultRs === true) {
          setRsError({ code: '', message: '' });
        }

        setKsError({ code: '', message: ksError.message });
        if (resultKs === true) {
          setKsError({ code: '', message: '' });
        }
        if (
          userType === CUSTOMER_SOLE_PROPRIETOR &&
          isValidIndividualEntrepreneur &&
          // resultKs === true &&
          // resultRs === true &&
          passwordIsValid === true
        ) {
          saveUser(
            {
              fname: ProfileStore.userData.customer.contact_person_fname,
              sname: ProfileStore.userData.customer.contact_person_sname,
              mname: ProfileStore.userData.customer.contact_person_mname,
              customerEmail: ProfileStore.userData.customer.email,
              bank: ProfileStore.userData.bank_name,
              full_name: `${ProfileStore.userData.customer.contact_person_sname} ${ProfileStore.userData.customer.contact_person_fname}`,
              ogrn: ProfileStore.userData.ogrn,
              inn: ProfileStore.userData.inn,
              bik: ProfileStore.userData.bik,
              rs: ProfileStore.userData.rs,
              ks: ProfileStore.userData.ks,
              confirmed: ProfileStore.userData.confirmed,
              post_address: ProfileStore.userData.customer.post_address,
              email: ProfileStore.userData.email,
              phone:
                ProfileStore.userData.customer.phone === ''
                  ? null
                  : ProfileStore.userData.customer.phone,
              login: ProfileStore.userData.login,
              // orderConfirmed: ProfileStore.userData.orderConfirmed
              //   ? ProfileStore.userData.orderConfirmed
              //   : false,
              // systemNotificationsConfirmed: ProfileStore.userData.systemNotificationsConfirmed
              //   ? ProfileStore.userData.systemNotificationsConfirmed
              //   : false,
              // smsConfirmed: ProfileStore.userData.smsConfirmed
              //   ? ProfileStore.userData.smsConfirmed
              //   : false,
              // emailConfirmed: ProfileStore.userData.emailConfirmed
              //   ? ProfileStore.userData.emailConfirmed
              //   : false,
              // depositInformationConfirmed: ProfileStore.userData.depositInformationConfirmed
              //   ? ProfileStore.userData.depositInformationConfirmed
              //   : false,
              // newsConfirmed: ProfileStore.userData.newsConfirmed
              //   ? ProfileStore.userData.newsConfirmed
              //   : false,
              // scheduledNotificationsConfirmed: ProfileStore.userData.scheduledNotificationsConfirmed
              //   ? ProfileStore.userData.scheduledNotificationsConfirmed
              //   : false,
              old_password: ProfileStore.userData.currentPassword
                ? ProfileStore.userData.currentPassword
                : null,
              new_password: ProfileStore.userData.newPassword
                ? ProfileStore.userData.newPassword
                : null,
              personalAccounts: AccountStore.accounts ? AccountStore.accounts : null,
            },
            userData.id,
          );
        }
      }
    },
  })),
);

export default enhancer(Profile);
