import { observable, decorate } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';
import { mapErrorsByCode } from '../helpers/errors';

export default class BalanceStore {
  constructor(context) {
    this.context = context;
  }

  balanceOperations = [];

  balanceData = {};

  buyUrl = undefined;

  purchases = [];

  payments = [];

  paymentStatus = undefined;

  hasMoreItemsReplenishment = true;

  hasMoreItemsPurchases = true;

  dateFromReplenishment = undefined;

  dateToReplenishment = undefined;

  dateFromPurchases = undefined;

  dateToPurchases = undefined;

  sortReplenishment = '';

  orderReplenishment = '';

  sortPurchases = 'id';

  orderPurchases = '';

  contract = '';

  errors = null;

  act = '';

  addSortAndOrderPurchases = value => {
    if (value === this.sortPurchases && this.orderPurchases === 'ASC') {
      this.orderPurchases = 'DESC';
    } else {
      this.sortPurchases = value;
      this.orderPurchases = 'ASC';
    }
  };

  deleteOrderPurchases = () => {
    this.orderPurchases = '';
  };

  addSortAndOrderReplenishment = value => {
    if (value === this.sortReplenishment && this.orderReplenishment === 'ASC') {
      this.orderReplenishment = 'DESC';
    } else {
      this.sortReplenishment = value;
      this.orderReplenishment = 'ASC';
    }
  };

  deleteOrderReplenishment = () => {
    this.orderReplenishment = '';
  };

  deleteContract = () => {
    this.contract = '';
  };

  addDateReplenishment = ({ dateFrom, dateTo }) => {
    this.dateFromReplenishment = dateFrom;
    this.dateToReplenishment = dateTo;
  };

  addDatePurchases = ({ dateFrom, dateTo }) => {
    this.dateFromPurchases = dateFrom;
    this.dateToPurchases = dateTo;
  };

  balanceAgreement = async (paymentId, data) => {
    this.errors = null;
    try {
      const response = await api.balance.balanceAgreement(paymentId, data, getToken());
      if (response.message || response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(response.code, response.message),
          type: 'error',
        });
        this.errors = response.message || response.code;
        logger.info(response);
      } else {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'Баланс пополнен.',
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    }
    return this.errors;
  };

  getStatus = async (accountId, paymentId) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.balance.getPaymentStatus(
        {
          customerId,
          accountId,
          paymentId,
        },
        getToken(),
      );
      if (!response.message || !response.code) {
        logger.info(response);
        this.paymentStatus = response.yk_payment_status;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.paymentStatus;
  };

  buyCard = async (accountId, data) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.services.buyCard(customerId, accountId, data, getToken());
      if (!response.message || !response.code) {
        logger.info(response);
        if (response.contract_download_url) {
          this.contract = response.contract_download_url;
        }
        this.buyUrl = response;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.contract;
  };

  setBalanceOperationsStore(state) {
    this.balanceOperations = [...this.balanceOperations, ...state];
  }

  setBalanceStore(state) {
    this.balanceData = { ...this.balanceData, ...state };
  }

  fetchPurchases = async ({ qnt = 15, offset = 0, dateFrom, dateTo }, accountId) => {
    if (this.isBusy) {
      return this.purchases;
    }
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.balance.getPurchases(
        {
          customerId,
          qnt,
          offset,
          dateFrom,
          dateTo,
          sort: this.sortPurchases,
          order: this.orderPurchases,
        },
        accountId,
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.purchases = response;
        } else {
          this.purchases = [...this.purchases, ...response];
        }
        if (response.length < qnt || !response.length) {
          this.hasMoreItemsPurchases = false;
        } else {
          this.hasMoreItemsPurchases = true;
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.purchases;
  };

  getActs = async (accountId, purchasesId) => {
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.balance.getActs(customerId, accountId, purchasesId, getToken());
      if (response.message || response.code) {
        this.act = '';
      } else {
        this.act = response;
      }
    } catch (ex) {
      logger.error(ex);
    }
    return this.act;
  };

  fetchPayments = async ({ qnt = 15, offset = 0, dateFrom, dateTo }, accountId) => {
    if (this.isBusy) {
      return this.payments;
    }
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.balance.getPayments(
        {
          customerId,
          qnt,
          offset,
          dateFrom,
          dateTo,
          sort: this.sortReplenishment,
          order: this.orderReplenishment,
        },
        accountId,
        getToken(),
      );
      if (!response.message || !response.code) {
        if (!offset) {
          this.payments = response;
        } else {
          this.payments = [...this.payments, ...response];
        }
        if (response.length < qnt || !response.length) {
          this.hasMoreItemsReplenishment = false;
        } else {
          this.hasMoreItemsReplenishment = true;
        }
        logger.info(response);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.payments;
  };
}
decorate(BalanceStore, {
  sortReplenishment: observable,
  sortPurchases: observable,
  orderReplenishment: observable,
  orderPurchases: observable,
  errors: observable,
  balanceOperations: observable,
  contract: observable,
  dateFromReplenishment: observable,
  dateFromPurchases: observable,
  dateToPurchases: observable,
  dateToReplenishment: observable,
  hasMoreItemsReplenishment: observable,
  hasMoreItemsPurchases: observable,
  paymentStatus: observable,
  balanceData: observable,
  purchases: observable,
  payments: observable,
  act: observable,
});
