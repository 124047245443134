import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import { withBoolState, withFetchUserInDidMount, withUserData } from '../../utils/hocs';
import { Wrapper, Title, Top, Button } from './AccountsStyled';
import Table from './Table/Table';
import { CUSTOMER_LEGAL_ENTITY } from '../../constants/customerTypes';
import AddAccountPopup from './Popups/AddAccountPopup/AddAccountPopup';
import EditAccountPopup from './Popups/EditAccountPopup/EditAccountPopup';

const Accounts = ({
  confirmed,
  toggleConfirmed,
  addAccountPopup,
  toggleAddAccountPopup,
  editAccountPopup,
  toggleEditAccountPopup,
  setItemId,
  itemId,
  userId,
  userType,
  isCustomerAdmin,
  offset,
  setOffset,
}) => (
  <>
    <Wrapper>
      <Top>
        <Title as="h2">Лицевые счета</Title>
        {(userType === CUSTOMER_LEGAL_ENTITY && isCustomerAdmin && (
          <Button data-test-id="TEST_BUTTON_ADD_ACCOUNT" onClick={() => toggleAddAccountPopup()}>
            Добавить счет
          </Button>
        )) ||
          null}
      </Top>
      <Table
        confirmed={confirmed}
        toggleConfirmed={toggleConfirmed}
        editAccountPopup={editAccountPopup}
        toggleEditAccountPopup={toggleEditAccountPopup}
        setItemId={setItemId}
        userId={userId}
        addAccountPopup={addAccountPopup}
        offset={offset}
        setOffset={setOffset}
      />
    </Wrapper>
    {addAccountPopup === true ? (
      <AddAccountPopup toggleAddAccountPopup={toggleAddAccountPopup} offset={offset} />
    ) : null}
    {editAccountPopup === true ? (
      <EditAccountPopup toggleEditAccountPopup={toggleEditAccountPopup} id={itemId} />
    ) : null}
  </>
);

Accounts.propTypes = {
  confirmed: PropTypes.bool,
  addAccountPopup: PropTypes.bool.isRequired,
  editAccountPopup: PropTypes.bool.isRequired,
  isCustomerAdmin: PropTypes.bool.isRequired,
  setItemId: PropTypes.func.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  toggleAddAccountPopup: PropTypes.func.isRequired,
  toggleEditAccountPopup: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  itemId: PropTypes.number,
  userId: PropTypes.number,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
};

Accounts.defaultProps = {
  confirmed: false,
  itemId: undefined,
  userId: undefined,
  offset: undefined,
  setOffset: () => {},
};

const enhance = compose(
  withFetchUserInDidMount,
  withUserData,
  withBoolState('addAccountPopup', false),
  withBoolState('confirmed', false),
  withBoolState('editAccountPopup', false),
  withState('itemId', 'setItemId', undefined),
  withState('offset', 'setOffset', 0),
);

export default enhance(Accounts);
