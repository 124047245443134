import API from './base';
import { getUrlToApi } from '../constants/api';

export default class DashboardApi extends API {
  /* createUser(data, token = null) {
    return this.post(getUrlToLKApi('auth/registration'), data, token, false);
  }

  getCount(token = null) {
    return this.get(getUrlToLKApi(`customers/count`), token, false);
  } */

  getOrganization(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/entity/${innOrOgrn}`), token, false);
  }

  getFinanceDigest(token = null) {
    return this.get(
      getUrlToApi(`static/v1/accounting-field`),
      token,
      false,
      false,
      'https://app.rescore.online',
    );
    // return this.get(`/api/static/v1/accounting-field`, token, false, 'https://app.rescore.online');
  }

  getFinanceBalanceList(innOrOgrn, token = null) {
    return this.get(getUrlToApi(`static/v1/accounting-report/${innOrOgrn}`), token, false);
  }

  getFinanceBalanceExt(innOrOgrn, year, token = null) {
    return this.get(
      getUrlToApi(`static/v1/accounting-report/${innOrOgrn}/${year}/aggregated`),
      token,
      false,
      false,
      'https://app.rescore.online',
    );
  }

  getFinanceBalanceFNS(innOrOgrn, year, token = null) {
    return this.get(
      getUrlToApi(`static/v1/opendata-fns/${innOrOgrn}/${year}`),
      token,
      false,
      false,
      'https://app.rescore.online',
    );
  }
}
