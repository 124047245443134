import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import {
  ItemWrapper,
  Text,
  Middle,
  Img,
  MonitorsLine,
  AllCount,
  AllCountSpan,
  Bottom,
  DateLine,
  DateText,
} from '../ActiveServicesStyled';
import { ICON_MONITORING } from '../../../../constants/icons';
import percent from '../../../../helpers/percent';

const Monitors = ({ monitors, monitorsAll, monitorsToTime, fromTime, monitorsTtl }) => {
  const dayFrom = new Date(fromTime);
  const dayTo = new Date(monitorsToTime);
  const currentDay = new Date();
  const currentDaysCounter = Math.ceil(
    Math.abs(dayTo.getTime() - currentDay.getTime()) / (1000 * 3600 * 24),
  );
  const allDays = Math.ceil(Math.abs(dayTo.getTime() - dayFrom.getTime()) / (1000 * 3600 * 24));
  return (
    <ItemWrapper>
      <Text>Дней мониторинга</Text>
      <Middle>
        <Img src={ICON_MONITORING} />
        <MonitorsLine width={`${percent(monitors, monitorsAll)}%`} />
        <AllCount>
          {monitors}
          <AllCountSpan>{monitorsAll > 0 ? 'из' : null}</AllCountSpan>
          {monitorsAll > 0 ? monitorsAll : null}
        </AllCount>
      </Middle>
      <Bottom>
        {monitorsTtl !== 1000000 ? (
          <DateLine
            width={currentDay > dayTo ? `0%` : `${percent(currentDaysCounter, allDays)}%`}
          />
        ) : null}
        <DateText>
          {monitorsTtl !== 1000000 ? moment(monitorsToTime).format('DD.MM.YYYY') : `бессрочно`}
        </DateText>
      </Bottom>
    </ItemWrapper>
  );
};

Monitors.propTypes = {
  fromTime: PropTypes.string,
  monitorsTtl: PropTypes.number,
  monitorsToTime: PropTypes.string,
  monitors: PropTypes.number,
  monitorsAll: PropTypes.number,
};

Monitors.defaultProps = {
  fromTime: '',
  monitorsTtl: undefined,
  monitorsToTime: '',
  monitors: undefined,
  monitorsAll: undefined,
};

export default Monitors;
