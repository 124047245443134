import { getOrigin } from '../helpers/url';
import {
  PAYMENT_SUCCESS,
  SCROTING_RESULT_WILL_BE_SEND,
  FINE,
  RECOVERY_SENDED,
  NEW_PASSWORD_SETTED,
  SCORING_SUCCESS,
  CONFIRMATION_SUCCESS,
} from './messages';

export const getUrl = () => `${getOrigin() || 'https://service01.rescore.online'}`;

export const SCORING_STATUS_AFTER_PAYMENT = `${getUrl()}/status?status=success&message=${PAYMENT_SUCCESS}&description=${SCROTING_RESULT_WILL_BE_SEND}&buttonText=${FINE}`;

export const STATUS_AFTER_PAYMENT = (actionUrl, page) =>
  `/status?status=success&message=${PAYMENT_SUCCESS}&buttonText=${FINE}&actionUrl=${actionUrl}${page &&
    `&page=${page}`}`;

export const STATUS_CONFIRMATION = (actionUrl, page) =>
  `/status?status=success&message=${CONFIRMATION_SUCCESS}&buttonText=${FINE}&actionUrl=${actionUrl}${page &&
    `&page=${page}`}`;

export const STATUS_AFTER_Confirmation = (actionUrl, page) =>
  `/status?status=success&message=${CONFIRMATION_SUCCESS}&buttonText=${FINE}&actionUrl=${actionUrl}${page &&
    `&page=${page}`}`;

export const STATUS_AFTER_SCORING = actionUrl =>
  `/status?status=success&message=${SCORING_SUCCESS}&description=${SCROTING_RESULT_WILL_BE_SEND}&buttonText=${FINE}&actionUrl=${actionUrl}`;

export const STATUS_AFTER_SCORING_ERROR = (actionUrl, message) =>
  `/status?status=error&message=${message}&buttonText=Назад&actionUrl=${actionUrl}`;

export const STATUS_RECOVERY_TOKEN_SENDED = actionUrl =>
  `/status?status=success&message=${RECOVERY_SENDED}&buttonText=${FINE}&actionUrl=${actionUrl}`;

export const STATUS_NEW_PASSWORD_SETTED = actionUrl =>
  `/status?status=success&message=${NEW_PASSWORD_SETTED}&buttonText=${FINE}&actionUrl=${actionUrl}`;

export const STATUS_PASSWORD_RESET_ALREADY_SUCCEED = (actionUrl, message) =>
  `/status?status=error&message=${message}&buttonText=Назад&actionUrl=${actionUrl}`;

export const STATUS_PAGE = (actionUrl, message, status = 'success') =>
  `/status?status=${status}&message=${message}&buttonText=Назад&actionUrl=${actionUrl}`;
