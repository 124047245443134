import { createQueryString } from '../helpers/url';

export default class InquiriesCombinedApi {
  constructor(inquiriesApi, discretionApi) {
    this.inquiriesApi = inquiriesApi;
    this.discretionApi = discretionApi;
  }

  async getInquiriesDiscretionList({ limit = 15, offset = 0 }, accountToken) {
    const subscriptions = await this.inquiriesApi.get(
      `subscriptions?${createQueryString({ limit, offset })}`,
      accountToken,
      false,
    );

    const discretion = await this.discretionApi.getDiscretion({
      external_subscriptions_ids: (subscriptions.result || []).map(({ id }) => id),
    });

    subscriptions.result = subscriptions.result.map(sub => {
      const discretionItem = discretion.result.find(({ score_id }) => score_id === sub.id);

      if (discretionItem) {
        return { ...sub, discretion: discretionItem, discretionEnabled: discretion.enabled };
      }

      return { ...sub, discretionEnabled: discretion.enabled };
    });

    return subscriptions;
  }
}
