import React from 'react';
import styled from 'styled-components';

import { COLOR_ACTION_BLUE, COLOR_BLACK_TEXT } from '../../../constants/colors';
import {
  ICON_FILE,
  ICON_REPEAT,
  ICON_TABLE_ACTIVITY,
  ICON_TABLE_PAUSE,
} from '../../../constants/icons';
import { tabletMedia } from '../../../constants/styles';

export const Wrapper = styled.div`
  overflow: auto;
`;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  min-width: 710px;
`;
export const TrTop = styled.tr`
  border-bottom: 2px solid #dadada;
`;
export const Tr = styled.tr`
  border-bottom: 1px solid #dadada;
`;

// eslint-disable-next-line react/prop-types
export const Img = ({ src, alt, ...other }) => {
  if (!src) return null;
  return <img {...other} alt={alt} src={src} />;
};

export const ImgData = styled.img`
  margin-left: 10px;
`;

export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const Th = styled.th`
  font-size: 13px;
  line-height: 19px;
  color: #707683;
  text-align: left;
  font-weight: 600;
  font-family: Lato, sans-serif;
  padding-bottom: 11px;
`;

export const ThDate = styled(Th)`
  color: #707683;
`;

export const Td = styled.td`
  color: #828282;
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Lato, sans-serif;
  font-weight: 500;
  padding-right: 15px;
  @media (max-width: ${tabletMedia}) {
    padding-right: 7px;
  }
`;

export const Button = styled.button`
  color: #c2cfe0;
  font-weight: 600;
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 14px;
  border: none;
  background: none;
  outline: none;
`;

export const TdWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableButton = styled.button`
  position: relative;
  color: ${COLOR_ACTION_BLUE};
  border: none;
  background: none;
  font-weight: 600;
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  outline: none;
  padding-left: 30px;
  white-space: nowrap;
  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background: url(${({ icon = ICON_FILE }) => icon}) no-repeat center center;
    left: 0;
  }
  @media (max-width: ${tabletMedia}) {
    font-size: 13px;
    padding-right: 7px;
  }
`;

export const TableButtonRepeat = styled(TableButton)`
  &:before {
    background: url(${ICON_REPEAT});
  }
`;

export const TableButtonActivity = styled(TableButton)`
  &:before {
    background: url(${ICON_TABLE_ACTIVITY});
  }
  &.expired {
    color: #f2994a;
  }
  &.activity {
    &:before {
      background: url(${ICON_TABLE_PAUSE});
    }
  }
`;

export const TableButtonNotExpired = styled(TableButton)`
  color: #f2994a;
  &:before {
    background: url(${ICON_TABLE_ACTIVITY});
  }
`;

export const TdBottom = styled.div`
  color: #90a0b7;
  margin-top: 5px;
`;

export const TdTop = styled.p`
  color: ${COLOR_BLACK_TEXT};
  max-width: 530px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ActivityTd = styled.td`
  position: relative;
  @keyframes tooltips-vert {
    to {
      opacity: 0.9;
      transform: translate(0, -50%);
    }
  }
  &:hover {
    img {
      display: none;
    }
    &:after {
      content: 'В обработке';
      position: absolute;
      top: 50%;
      left: 0;
      background: #fff;
      z-index: 50;
      width: auto;
      height: 15px;
      font-size: 11px;
      border-right-width: 0;
      border-left-color: #333;
      animation: tooltips-vert 300ms ease-out forwards;
    }
  }
  &.expired {
    &:hover {
      &:after {
        content: 'Оценён';
      }
    }
  }
  &.activity {
    &:hover {
      &:after {
        content: 'В работе';
      }
    }
  }
`;
