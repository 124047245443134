import React from 'react';
import PropTypes from 'prop-types';

import { TableButton } from '../Table/TableStyled';

const ExtraDiscretionDashboard = ({ item }) => {
  const { discretion } = item;

  if (!discretion) return null;

  const { html_url } = discretion;

  if (!html_url) return null;

  const extraDiscretionDashboardHandler = () => {
    window.open(`${html_url}`, '_blank');
  };
  return <TableButton onClick={extraDiscretionDashboardHandler}>Дашборд ДО</TableButton>;
};

ExtraDiscretionDashboard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ExtraDiscretionDashboard;
