import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { inject } from 'mobx-react';
import { compose, withProps } from 'recompose';

import subscriptionsStatuses from '../../../constants/subscriptionsStatuses';
import { SCORING_VALIDITY_DAYS_DURATION } from '../../../constants/env';
import storeNames from '../../../stores/storeNames';
import ExtraDiscretionModal from '../ExtraDiscretionModal';

const scoringExpired = endTime => {
  const endDate = new Date(endTime);

  return endDate.setDate(endDate.getDate() + SCORING_VALIDITY_DAYS_DURATION) < Date.now();
};

const ExtraDiscretionButton = ({ addModal, item }) => {
  const { status, end, discretion, discretionEnabled } = item;

  if (
    status !== subscriptionsStatuses.classified ||
    scoringExpired(end) ||
    discretion ||
    !discretionEnabled
  ) {
    return null;
  }

  return (
    <Button variant="outlined" size="small" onClick={() => addModal(ExtraDiscretionModal, item)}>
      Активировать услугу ДО
    </Button>
  );
};

ExtraDiscretionButton.propTypes = {
  addModal: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default compose(
  inject(storeNames.Modals),
  withProps(({ Modals }) => ({ addModal: Modals.addModal })),
)(ExtraDiscretionButton);
