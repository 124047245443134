import { observable, decorate, action } from 'mobx';

export default class IndicatorsStore {
  constructor(context) {
    this.context = context;
  }

  errors = [];

  collapse = false;

  timerId = undefined;

  setCollapse() {
    this.collapse = !this.collapse;
  }

  addErrorIndicators(state) {
    clearTimeout(this.timerId);
    this.errors = [];
    this.errors = [...this.errors, state];
    this.timerId = setTimeout(() => {
      this.deleteErrorIndicators();
    }, 5000);
  }

  deleteErrorIndicators() {
    this.errors = this.errors.filter((item, i) => item[i] === 0);
  }
}

decorate(IndicatorsStore, {
  errors: observable,
  collapse: observable,
  deleteErrorIndicators: action,
});
