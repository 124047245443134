import { observable, decorate } from 'mobx';
import api from '../api';
import logger from '../logger';
import { RESCORE_TOKEN } from '../constants/env';

export default class EntityStore {
  constructor(context) {
    this.context = context;
  }

  entity = null;

  errors = null;

  isBusy = false;

  fetchEntity = async numbers => {
    this.isBusy = true;
    this.entity = null;
    this.errors = null;
    try {
      const response = await api.entity.getEntityById(RESCORE_TOKEN, numbers);
      this.errors = response.alerts;
      this.entity = response.result;
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.entity;
  };
}

decorate(EntityStore, {
  entity: observable,
  errors: observable,
  isBusy: observable,
});
