import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers, withState, lifecycle, withProps } from 'recompose';
import { useMedia } from 'use-media';
import Popup from '../../../components/Popup/Popup';
import storeNames from '../../../stores/storeNames';
import { Wrapper, ButtonWrapperCreate, InputWrapperCreate, FormWrapper } from './PopupsStyled';
import ParagraphError from '../../../components/Typography/ParagraphError';
import Paragraph from '../../../components/Typography/Paragraph';
import InputField from '../../../components/Inputs/InputField';
import { hasValue } from '../../../utils/validators';
import { COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import {
  withNameTariffState,
  withScoresTtlState,
  withScoresCountState,
  withScoresCoastState,
  withInputSetServicesStore,
} from '../../../utils/FormHocs';
import Button from '../../../components/Buttons/Button';

const CreateTariffPopup = ({
  toggleOpenCreatePopup,
  formHandler,
  inputSetServicesStore,
  nameTariff,
  nameTariffValid,
  inputHandlerNameTariff,
  nameTariffError,
  scoresCount,
  scoresCountValid,
  inputHandlerScoresCount,
  scoresCountError,
  scoresTtlError,
  scoresTtl,
  scoresTtlValid,
  inputHandlerScoresTtl,
  scoresCoast,
  scoresCoastValid,
  inputHandlerScoresCoast,
  scoresCoastError,
}) => {
  const mobileMedia = useMedia({ maxWidth: 768 });
  return (
    <Popup width="600px" closePopup={toggleOpenCreatePopup}>
      <Wrapper>
        <Paragraph
          textColor={COLOR_LINK_BLACK}
          fontSize={mobileMedia ? '24px' : '32px'}
          lineHeight="42px"
          fontWeight="300"
          textAlign="center"
        >
          Настройка тарифа
        </Paragraph>
        <FormWrapper onSubmit={formHandler}>
          <InputWrapperCreate>
            <InputField
              placeholder="Название тарифа"
              value={nameTariff}
              name="nameTariff"
              isValid={nameTariffValid}
              onChange={value => {
                inputSetServicesStore(value, inputHandlerNameTariff, 'nameTariff');
              }}
            />
            <ParagraphError>{nameTariffError}</ParagraphError>
          </InputWrapperCreate>
          <InputWrapperCreate>
            <InputField
              placeholder="Количество скоров"
              value={scoresCount}
              name="scoresCount"
              isValid={scoresCountValid}
              onChange={value => {
                inputSetServicesStore(value, inputHandlerScoresCount, 'scoresCount');
              }}
            />
            <ParagraphError>{scoresCountError}</ParagraphError>
          </InputWrapperCreate>
          <InputWrapperCreate>
            <InputField
              placeholder="Срок действия тарифа"
              value={scoresTtl}
              name="scoresTtl"
              isValid={scoresTtlValid}
              onChange={value => {
                inputSetServicesStore(value, inputHandlerScoresTtl, 'scoresTtl');
              }}
            />
            <ParagraphError>{scoresTtlError}</ParagraphError>
          </InputWrapperCreate>
          <InputWrapperCreate>
            <InputField
              placeholder="Стоимость тарифа"
              value={scoresCoast}
              name="scoresCoast"
              isValid={scoresCoastValid}
              onChange={value => {
                inputSetServicesStore(value, inputHandlerScoresCoast, 'scoresCoast');
              }}
            />
            <ParagraphError>{scoresCoastError}</ParagraphError>
          </InputWrapperCreate>
          <ButtonWrapperCreate>
            <Button
              testID="TEST_BUTTON_ADD"
              width="303px"
              type="submit"
              sizes={{ margin: '0 20px' }}
            >
              Сохранить
            </Button>
            <Button
              testID="TEST_BUTTON_CANCEL"
              width="303px"
              type="submit"
              textColor={COLOR_ACTION_BLUE}
              boxShadow="none"
              backgroundColor="#fff"
              sizes={{ margin: '0 20px' }}
              onClick={() => toggleOpenCreatePopup()}
            >
              Отмена
            </Button>
          </ButtonWrapperCreate>
        </FormWrapper>
      </Wrapper>
    </Popup>
  );
};

CreateTariffPopup.propTypes = {
  toggleOpenCreatePopup: PropTypes.func,
  formHandler: PropTypes.func,
  nameTariffValid: PropTypes.bool,
  inputSetServicesStore: PropTypes.func,
  nameTariff: PropTypes.string,
  nameTariffError: PropTypes.string,
  inputHandlerNameTariff: PropTypes.func,
  scoresCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scoresCountValid: PropTypes.bool,
  inputHandlerScoresCount: PropTypes.func,
  scoresCountError: PropTypes.string,
  scoresCoastError: PropTypes.string,
  scoresTtlError: PropTypes.string,
  scoresTtl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scoresTtlValid: PropTypes.bool,
  inputHandlerScoresTtl: PropTypes.func,
  scoresCoast: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scoresCoastValid: PropTypes.bool,
  inputHandlerScoresCoast: PropTypes.func,
};

CreateTariffPopup.defaultProps = {
  toggleOpenCreatePopup: () => {},
  formHandler: () => {},
  inputSetServicesStore: () => {},
  nameTariff: '',
  nameTariffValid: false,
  inputHandlerNameTariff: () => {},
  nameTariffError: '',
  scoresCount: undefined,
  scoresCoastError: '',
  scoresTtlError: '',
  scoresCountValid: false,
  inputHandlerScoresCount: () => {},
  scoresCountError: '',
  scoresTtl: undefined,
  scoresTtlValid: false,
  inputHandlerScoresTtl: () => {},
  scoresCoast: undefined,
  scoresCoastValid: false,
  inputHandlerScoresCoast: () => {},
};

const enhancer = compose(
  inject(storeNames.ServicesStore),
  observer,
  withNameTariffState,
  withScoresCountState,
  withScoresTtlState,
  withScoresCoastState,
  withInputSetServicesStore,
  withState('disabledButton', 'setDisabledButton', false),
  withProps(({ ServicesStore }) => ({
    tariffs: ServicesStore.servicesTariffs,
  })),
  withHandlers(() => ({
    inputSetServicesStore: ({ ServicesStore }) => (value, inputHandler, name) => {
      inputHandler(value);
      ServicesStore.setServicesTariffStore({ [`${name}`]: value });
    },
    formHandler: ({
      ServicesStore,
      nameTariff,
      scoresCount,
      scoresTtl,
      scoresCoast,
      nameTariffValid,
      scoresCountValid,
      scoresTtlValid,
      scoresCoastValid,
      toggleOpenCreatePopup,
      setNameTariffError,
      setNameTariffValid,
      setScoresCountError,
      setScoresCountValid,
      setScoresTtlError,
      setScoresTtlValid,
      setScoresCoastError,
      setScoresCoastValid,
      tariffId,
    }) => async e => {
      e.preventDefault();

      if (!nameTariff) {
        setNameTariffError('Укажите Имя Тарифа.');
        setNameTariffValid(false);
      } else {
        setNameTariffValid(true);
        setNameTariffError('');
      }
      if (!scoresCount) {
        setScoresCountError('Введите количество скоров');
        setScoresCountValid(false);
      } else {
        setScoresCountValid(true);
        setScoresCountError('');
      }
      if (!scoresTtl) {
        setScoresTtlError('Введите срок действия тарифа');
        setScoresTtlValid(false);
      } else {
        setScoresTtlValid(true);
        setScoresTtlError('');
      }
      if (!scoresCoast) {
        setScoresCoastError('Введите стоимость тарифа');
        setScoresCoastValid(false);
      } else {
        setScoresCoastValid(true);
        setScoresCoastError('');
      }

      if (
        hasValue(nameTariff) &&
        hasValue(scoresCount) &&
        hasValue(scoresTtl) &&
        hasValue(scoresCoast) &&
        nameTariffValid &&
        scoresCountValid &&
        scoresTtlValid &&
        scoresCoastValid
      ) {
        if (tariffId) {
          const newTariff = {
            from_time: '1999-12-31T21:00:00.000Z',
            to_time: null,
            full_name: nameTariff,
            scores: Number(scoresCount),
            scores_ttl: Number(scoresTtl),
            price: Number(scoresCoast),
            monitors: 0,
            monitors_ttl: 0,
          };
          await ServicesStore.editTariff(tariffId, newTariff);
        } else {
          const newTariff = {
            from_time: '1999-12-31T21:00:00.000Z',
            to_time: null,
            full_name: `Тариф «${nameTariff}» `,
            scores: Number(scoresCount),
            scores_ttl: Number(scoresTtl),
            price: Number(scoresCoast),
            monitors: 0,
            monitors_ttl: 0,
          };
          await ServicesStore.createNewTariff(newTariff);
        }
        toggleOpenCreatePopup();
      }
    },
  })),
  lifecycle({
    async componentDidMount() {
      const {
        tariffId,
        tariffs,
        inputSetServicesStore,
        inputHandlerNameTariff,
        inputHandlerScoresCount,
        inputHandlerScoresTtl,
        inputHandlerScoresCoast,
      } = this.props;
      if (tariffId) {
        const item = tariffs.find(el => el.id === tariffId);
        if (item) {
          inputSetServicesStore(item.full_name, inputHandlerNameTariff, 'nameTariff' || '');
          inputSetServicesStore(item.scores, inputHandlerScoresCount, 'scoresCount' || '');
          inputSetServicesStore(item.scores_ttl, inputHandlerScoresTtl, 'scoresTtl' || '');
          inputSetServicesStore(item.price, inputHandlerScoresCoast, 'scoresCoast' || '');
        }
      }
    },
  }),
);

export default enhancer(CreateTariffPopup);
