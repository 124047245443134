import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from '../components/Header/Header';
import { VerticalFlexContainer, mobileMedia } from '../constants/styles';
import { COLOR_WHITE } from '../constants/colors';
import Indicator from '../components/Indicator/Indicator';

const Main = styled(VerticalFlexContainer)`
  height: 100%;
  justify-content: ${({ verticalAlign }) => verticalAlign || 'flex-start'};
  align-items: ${({ horizontalAlign }) => horizontalAlign || 'flex-start'};
  background-color: ${({ bgColor }) => bgColor || 'inherit'};
  @media (max-width: ${mobileMedia}) {
    padding-right: 2px;
    padding-left: 2px;
  }
`;

const Footer = styled.footer`
  width: 100%;
`;

const Wrapper = styled(VerticalFlexContainer)`
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background-color: ${COLOR_WHITE};
`;

export const DefaultLayout = ({ layoutProps, children, ...restProps }) => (
  <Wrapper {...layoutProps}>
    <Indicator />
    <VerticalFlexContainer>
      <Header />
      <Main
        verticalAlign={layoutProps.contentVerticalAlign}
        bgColor={layoutProps.mainBackgroundColor}
        horizontalAlign={layoutProps.contentHorizontalAlign}
      >
        {cloneElement(children, restProps)}
      </Main>
    </VerticalFlexContainer>
    <Footer />
  </Wrapper>
);

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  layoutProps: PropTypes.object.isRequired,
};
