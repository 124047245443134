import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers } from 'recompose';
import storeNames from '../../../stores/storeNames';
import {
  Wrapper,
  Item,
  ItemBottom,
  Img,
  DoubleWrapper,
  Top,
  ItemWrapper,
  BottomButtonWrapper,
  Buttons,
} from './StatisticStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import { ICON_SCORING, ICON_RUBLE } from '../../../constants/icons';
import { COLOR_LINK_BLACK, COLOR_ACTION_BLUE } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import { withUserData } from '../../../utils/hocs';

const Statistic = ({
  scoring,
  // monitoring,
  money,
  toggleOpenReplenishmentBalancePopup,
  handlePurchase,
  accountId,
  isUser,
}) => (
  <Wrapper>
    <DoubleWrapper>
      <Top>
        <Item>
          <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
            Скоринги
          </Paragraph>
          <ItemBottom>
            <Img src={ICON_SCORING} />
            <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
              {scoring}
            </Paragraph>
          </ItemBottom>
        </Item>
        {/* <Item>
          <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
            Мониторинги
          </Paragraph>
          <ItemBottom>
            <Img src={ICON_MONITORING} />
            <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
              {monitoring}
            </Paragraph>
          </ItemBottom>
        </Item> */}
      </Top>
      {!isUser && (
        <Buttons>
          <BottomButtonWrapper onClick={handlePurchase}>
            <Button width="233px" height="42px" lineHeight="15px" testID="TEST_BUTTON_PURCHASE">
              Купить услуги
            </Button>
          </BottomButtonWrapper>
        </Buttons>
      )}
    </DoubleWrapper>
    <ItemWrapper>
      <Item>
        <Paragraph textColor="#90A0B7" fontSize="14px" lineHeight="18px">
          Деньги
        </Paragraph>
        <ItemBottom>
          <Img src={ICON_RUBLE} />
          <Paragraph textColor={COLOR_LINK_BLACK} fontSize="39px" lineHeight="44px">
            {!money ? 0 : money}
          </Paragraph>
        </ItemBottom>
      </Item>
      {!isUser && (
        <Buttons>
          <BottomButtonWrapper onClick={() => toggleOpenReplenishmentBalancePopup()}>
            <Button
              boxShadow="none"
              width="233px"
              height="42px"
              lineHeight="15px"
              backgroundColor="#fff"
              textColor={COLOR_ACTION_BLUE}
              testID="TEST_BUTTON_REPLENISHMENT_SERVICES"
              disabled={!accountId}
            >
              Пополнить счет
            </Button>
          </BottomButtonWrapper>
        </Buttons>
      )}
    </ItemWrapper>
  </Wrapper>
);

Statistic.propTypes = {
  scoring: PropTypes.number,
  // monitoring: PropTypes.number,
  money: PropTypes.number,
  handlePurchase: PropTypes.func,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  isUser: PropTypes.bool.isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Statistic.defaultProps = {
  scoring: undefined,
  // monitoring: undefined,
  money: undefined,
  accountId: '',
  handlePurchase: () => {},
};

const enchance = compose(
  inject(storeNames.ServicesStore),
  observer,
  withUserData,
  withHandlers(({ ServicesStore }) => ({
    handlePurchase: () => () => {
      ServicesStore.setServicesTab('Purchase');
    },
  })),
);

export default enchance(Statistic);
