import * as Yup from 'yup';
import api from '../../../api';

const useExtraDiscretionFormHandler = ({
  formValues,
  setErrors,
  id,
  updateInquire,
  removeModal,
}) => {
  return async event => {
    event.preventDefault();

    try {
      await Yup.object({
        text: Yup.string().required('Укажите текст сообщения'),
        emails: Yup.array(Yup.string().email('Укажите валидный email адрес')).min(
          1,
          'Укажите один или несколько валидных email адресов через запятую',
        ),
      }).validate(formValues);

      setErrors({ emails: '', text: '', server: '' });

      const discretion = await api.discretion.setDiscretion({
        score_id: id,
        invitation_text: formValues.text,
        client_email: formValues.emails.join(';'),
      });

      if (discretion.code === 'VALIDATION_ERROR') {
        // eslint-disable-next-line no-throw-literal
        throw { validationErrors: discretion.errors };
      } else if (discretion.statusCode >= 400) {
        // eslint-disable-next-line no-throw-literal
        throw { serverError: discretion.code };
      }

      updateInquire(id, { discretion });

      removeModal();
    } catch ({ message, path, validationErrors, serverError }) {
      if (validationErrors) {
        validationErrors.forEach(({ property }) => {
          if (property === 'client_email') {
            setErrors(p => ({ ...p, emails: 'Почтовые адреса не прошли валидацию на сервере' }));
          }

          if (property === 'invitation_text') {
            setErrors(p => ({ ...p, text: 'Сообщение не прошло валидацию на сервере' }));
          }
        });
      } else if (serverError) {
        setErrors(p => ({ ...p, server: serverError }));
      } else {
        setErrors({ [path]: message });
      }
    }
  };
};

export default useExtraDiscretionFormHandler;
