import { compose, withHandlers } from 'recompose';
import { inject } from 'mobx-react';
import history from '../history';
import {
  removeToken,
  getSuperAdminToken,
  setToken,
  removeSuperAdminToken,
} from '../helpers/session';
import storeNames from '../stores/storeNames';

export const withLogoutButton = compose(
  inject(storeNames.ProfileStore),
  withHandlers({
    onLogout: ({ ProfileStore }) => () => {
      const { onLogout } = ProfileStore;
      onLogout();
      removeToken();
      const super_token = getSuperAdminToken();
      if (super_token) {
        removeSuperAdminToken();
        setToken(super_token);
        history.replace('/companies');
        return;
      }
      history.replace('/');
    },
  }),
);
