import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers } from 'recompose';
import Popup from '../../../components/Popup/Popup';
import { Wrapper, TextWrapper, TextWrap } from '../RepeatPopup/PopupStyled';
import Paragraph from '../../../components/Typography/Paragraph';
import { COLOR_LINK_BLACK, COLOR_BLACK_LOGO, COLOR_RED } from '../../../constants/colors';
import Button from '../../../components/Buttons/Button';
import storeNames from '../../../stores/storeNames';

const ExpiredPopup = ({ toggleExpiredPopup, buttonHandler }) => (
  <Popup width="600px" closePopup={toggleExpiredPopup}>
    <Wrapper>
      <Paragraph textColor={COLOR_LINK_BLACK} fontSize="32px" lineHeight="42px" fontWeight="300">
        Мониторинг
      </Paragraph>
      <TextWrapper>
        <Paragraph textColor={COLOR_RED} fontSize="18px" fontWeight="500">
          Для старта мониторинга необходимо обновить скоринг.
        </Paragraph>
        <TextWrap>
          <Paragraph textColor={COLOR_BLACK_LOGO} fontSize="18px" fontWeight="500">
            С баланса будет списан 1 скоринг и 1 мониторинг.
          </Paragraph>
        </TextWrap>
      </TextWrapper>
      <Button
        height="42px"
        data-test-id="TEST_BUTTON_RUN"
        lineHeight="15px"
        width="245px"
        onClick={() => buttonHandler()}
      >
        Списать и запустить
      </Button>
    </Wrapper>
  </Popup>
);

ExpiredPopup.propTypes = {
  toggleExpiredPopup: PropTypes.func,
  buttonHandler: PropTypes.func,
};

ExpiredPopup.defaultProps = {
  toggleExpiredPopup: () => {},
  buttonHandler: () => {},
};

const enchance = compose(
  inject(storeNames.CustomerStore),
  observer,
  withHandlers(() => ({
    buttonHandler: ({ id, toggleExpiredPopup, CustomerStore }) => () => {
      const client = CustomerStore.inquiries.find(item => item.id === id);
      client.expired = !client.expired;
      CustomerStore.setInquiriesExpired(client);
      toggleExpiredPopup();
    },
  })),
);

export default enchance(ExpiredPopup);
