import { observable, decorate, action } from 'mobx';
import api from '../api';
import logger from '../logger';
import { getToken, setToken, removeToken } from '../helpers';
import { mapErrorsByCode } from '../helpers/errors';
import { setSuperAdminToken } from '../helpers/session';

export default class ProfileStore {
  constructor(context) {
    this.context = context;
    this.token = getToken();
  }

  userData = {};

  superUserData = {};

  userHandlers = {};

  errorCode = null;

  prepareUpdatePasswordErrorCode = null;

  token = null;

  errors = null;

  isBusy = false;

  statusConfirmation = undefined;

  statusConsumerConfirmation = undefined;

  isSaveBusy = false;

  setProfileStore(state) {
    this.userData = { ...this.userData, ...state };
  }

  setProfileCustomerStore(state) {
    this.userData.customer = { ...this.userData.customer, ...state };
  }

  setProfileStoreHandlers(state) {
    this.userHandlers = { ...this.userHandlers, ...state };
  }

  saveUser = async payload => {
    this.isSaveBusy = true;
    this.prepareUpdatePasswordErrorCode = null;
    try {
      const { customer_id: customerId, id: userId } = await this.context.ProfileStore.fetchUser();
      const customer = {
        full_name: payload.full_name,
        ogrn: payload.ogrn,
        inn: payload.inn,
        kpp: payload.kpp,
        post_address: payload.post_address,
        email: payload.customerEmail,
        phone: payload.phone,
        contact_person_fname: payload.fname,
        contact_person_sname: payload.sname,
        contact_person_mname: payload.mname,
      };
      const customerBankInfo = {
        bank_name: payload.bank,
        rs: payload.rs,
        ks: payload.ks,
        bik: payload.bik,
      };
      const passwords = {
        old_password: payload.old_password,
        new_password: payload.new_password,
      };
      const response = await api.customers.editCustomer(customerId, customer, getToken());
      if (payload.old_password && payload.new_password) {
        const prepareUpdatePassword = await api.customers.updateUserPassword(
          { customerId, userId, ...passwords },
          getToken(),
        );
        if (prepareUpdatePassword.code || prepareUpdatePassword.message) {
          this.prepareUpdatePasswordErrorCode = prepareUpdatePassword.code;
          const { setCurrentPasswordError } = this.userHandlers;
          setCurrentPasswordError(mapErrorsByCode(prepareUpdatePassword.message.toLowerCase()));
          this.context.IndicatorsStore.addErrorIndicators({
            message: 'Проверьте правильность полей.',
            type: 'error',
          });
          return false;
        }
        this.resetPasswords();
      }
      const bankInfo = await api.customers.editCustomerBankInfo(
        customerId,
        customerBankInfo,
        getToken(),
      );
      if (!response.message || !response.code) {
        logger.info(response);
      }
      if (!bankInfo.message || !bankInfo.code) {
        logger.info(bankInfo);
      }
      if (!response.message && !response.code && !bankInfo.message && !bankInfo.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'Ваш профиль успешно сохранён.',
          type: 'success',
        });
        await this.hardFetchUser();
      } else {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(
            bankInfo.validationErrors ||
              bankInfo.code ||
              response.validationErrors ||
              response.code,
          ),
          type: 'error',
        });
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isSaveBusy = false;
    }
    return {
      userData: this.userData,
      prepareUpdatePasswordErrorCode: this.prepareUpdatePasswordError,
    };
  };

  saveIndividualUser = async (payload, id) => {
    this.isSaveBusy = true;
    this.prepareUpdatePasswordErrorCode = null;
    try {
      const { customer_id: customerId, id: userId } = await this.context.ProfileStore.fetchUser();
      const customer = {
        full_name: `${payload.sname} ${payload.fname}`,
        post_address: payload.post_address,
        email: payload.email,
        phone: payload.phone,
        contact_person_fname: payload.fname,
        contact_person_sname: payload.sname,
        contact_person_mname: payload.mname,
      };
      const user = {
        login: payload.login,
        sname: payload.sname,
        fname: payload.fname,
        mname: payload.mname,
        phone: payload.phone,
        email: payload.email,
        birth_date: payload.birth_date,
      };
      const passwords = {
        old_password: payload.old_password,
        new_password: payload.new_password,
      };
      const response = await api.customers.editCustomer(customerId, customer, getToken());
      if (payload.old_password && payload.new_password) {
        const prepareUpdatePassword = await api.customers.updateUserPassword(
          { customerId, userId, ...passwords },
          getToken(),
        );
        if (prepareUpdatePassword.code || prepareUpdatePassword.message) {
          this.prepareUpdatePasswordErrorCode = prepareUpdatePassword.code;
          const { setCurrentPasswordError } = this.userHandlers;
          setCurrentPasswordError(mapErrorsByCode(prepareUpdatePassword.message.toLowerCase()));
          this.context.IndicatorsStore.addErrorIndicators({
            message: 'Проверьте правильность полей.',
            type: 'error',
          });
          return false;
        }
        this.resetPasswords();
      }
      const users = await api.users.editCustomerUser(customerId, id, user, getToken());
      if (!response.message || !response.code) {
        logger.info(response);
      }
      if (!users.message || !users.code) {
        logger.info(users);
      }
      if (!response.message && !response.code && !users.message && !users.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: 'Ваш профиль успешно сохранён.',
          type: 'success',
        });
        await this.hardFetchUser();
      } else {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(
            users.validationErrors || users.code || response.validationErrors || response.code,
          ),
          type: 'error',
        });
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isSaveBusy = false;
    }
    return {
      userData: this.userData,
      prepareUpdatePasswordErrorCode: this.prepareUpdatePasswordError,
    };
  };

  onLogout = () => {
    this.userData = {};
    this.superUserData = {};
    this.context.AccountStore.clearAccounts();
    this.context.RegistrationStore.clearRegistrationStore();
    this.context.UserStore.clearUsers();
    this.context.ServicesStore.clearStats();
    this.context.InquiriesStore.deleteInquiries();
  };

  getStatusConfirmation = async (userId, emailConfirmationId, data) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.profile.getStatusConfirmation(
        {
          customerId,
          userId,
          emailConfirmationId,
        },
        data,
        getToken(),
      );
      if (response.message || response.code || response.statusCode) {
        logger.info(response);
        this.statusConfirmation = response.code || response.message || response.statusCode;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.statusConfirmation;
  };

  getStatusCustomerConfirmation = async (emailConfirmationId, data) => {
    this.isBusy = true;
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.profile.getStatusCustomerConfirmation(
        {
          customerId,
          emailConfirmationId,
        },
        data,
        getToken(),
      );
      if (response.message || response.code || response.statusCode) {
        logger.info(response);
        this.statusConsumerConfirmation = response.code || response.message || response.statusCode;
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.statusConsumerConfirmation;
  };

  confirmationEmail = async (data, userId) => {
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.profile.confirmationEmail(customerId, userId, data, getToken());
      if (!response.message && !response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: `Письмо с подтверждением отправлено на Ваш e-mail`,
          type: 'success',
        });
      } else {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(response.code, response.message),
          type: 'error',
        });
      }
    } catch (ex) {
      this.context.IndicatorsStore.addErrorIndicators({
        message: `Произошла ошибка, попробуйте еще раз`,
        type: 'error',
      });
      logger.error(ex);
    }
  };

  confirmationCustomerEmail = async data => {
    try {
      const { customer_id: customerId } = await this.context.ProfileStore.fetchUser();
      const response = await api.profile.confirmationCustomerEmail(customerId, data, getToken());
      if (!response.message && !response.code) {
        this.context.IndicatorsStore.addErrorIndicators({
          message: `Письмо с подтверждением отправлено на Ваш e-mail`,
          type: 'success',
        });
      } else {
        this.context.IndicatorsStore.addErrorIndicators({
          message: mapErrorsByCode(response.code, response.message),
          type: 'error',
        });
      }
    } catch (ex) {
      this.context.IndicatorsStore.addErrorIndicators({
        message: `Произошла ошибка, попробуйте еще раз`,
        type: 'error',
      });
      logger.error(ex);
    }
  };

  fetchUser = async () => {
    if (this.userData && this.userData.id) return this.userData;
    this.isBusy = true;
    try {
      const response = await api.profile.fetchUser(getToken());
      const customer = await api.customers.getCustomer(response.customer_id, getToken());
      const customerBankInfo = await api.customers.getCustomerBankInfo(
        response.customer_id,
        getToken(),
      );
      logger.info(response, customer, customerBankInfo);
      const user = {
        ...response,
        customer,
        customerBankInfo,
      };
      this.userData = user;
    } catch (ex) {
      removeToken();
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.userData;
  };

  hardFetchUser = async () => {
    this.isBusy = true;
    try {
      const response = await api.profile.fetchUser(getToken());
      const customer = await api.customers.getCustomer(response.customer_id, getToken());
      const customerBankInfo = await api.customers.getCustomerBankInfo(
        response.customer_id,
        getToken(),
      );
      logger.info(response, customer, customerBankInfo);
      const user = {
        ...response,
        customer,
        customerBankInfo,
      };
      this.userData = user;
    } catch (ex) {
      removeToken();
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return this.userData;
  };

  getToken = async ({ password, login }) => {
    this.isBusy = true;
    this.errorCode = null;
    try {
      const { access_token, code, statusCode } = await api.auth.getToken({ password, login });
      logger.info(access_token, code, statusCode);
      if (code || !access_token) {
        this.errorCode = code;
        throw new Error(code);
      } else {
        this.token = access_token;
        setToken(this.token);
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return { token: getToken(), code: this.errorCode };
  };

  getTokenBySuperAdmin = async ({ userId, customerName }) => {
    this.isBusy = true;
    try {
      const { access_token } = await api.auth.getTokenBySuperAdmin({ userId }, getToken());
      if (access_token) {
        this.superUserData = this.userData;
        setSuperAdminToken(getToken());
        removeToken();
        this.userData = {};
        this.token = access_token;
        setToken(this.token);
        this.context.IndicatorsStore.addErrorIndicators({
          message: `Вы зашли за пользователя: ${customerName}. Чтобы вернуться, нажмите на ВЫХОД.`,
          type: 'success',
        });
      }
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    return { token: getToken() };
  };

  resetPasswords = () => {
    try {
      const {
        inputHandlerCurrentPassword,
        inputHandlerNewPassword,
        inputHandlerConfirmPassword,
      } = this.userHandlers;
      inputHandlerCurrentPassword('');
      inputHandlerNewPassword('');
      inputHandlerConfirmPassword('');
    } catch (ex) {
      logger.error(ex);
    }
  };
}

decorate(ProfileStore, {
  userData: observable,
  superUserData: observable,
  userHandlers: observable,
  errors: observable,
  isBusy: observable,
  isSaveBusy: observable,
  token: observable,
  statusConfirmation: observable,
  statusConsumerConfirmation: observable,
  errorCode: observable,
  saveUser: action,
});
