import React from 'react';
import PropTypes from 'prop-types';

import { ICON_PDF_FILE } from '../../../constants/icons';
import { TableButton } from '../Table/TableStyled';
import subscriptionsStatuses from '../../../constants/subscriptionsStatuses';

const ReportsLinks = ({ status, id }) => {
  if (status !== subscriptionsStatuses.classified) return null;

  const openReportsHandler = (idx, postfix) => () => {
    window.open(`https://storage.yandexcloud.net/rescore-report/${idx}-${postfix}.pdf`, '_blank');
  };

  return (
    <>
      <TableButton onClick={openReportsHandler(id, 'short')} icon={ICON_PDF_FILE}>
        Отчет 1
      </TableButton>
      <TableButton onClick={openReportsHandler(id, 'full')} icon={ICON_PDF_FILE}>
        Отчет 2
      </TableButton>
    </>
  );
};

ReportsLinks.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ReportsLinks;
