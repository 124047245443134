/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { inject, observer } from 'mobx-react';
import { compose, withHandlers, lifecycle } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import {
  withDateFromState,
  withDateToState,
  withProfileInputSetStore,
} from '../../../utils/FormHocs';
import { withActiveValue } from '../../../utils/hocs';
import {
  Top,
  RightContainer,
  WrapperDate,
  DateInputWrap,
  ButtonWrapper,
  TableWrapper,
  TableContainer,
  Thead,
  TrTop,
  Th,
  Tbody,
  Tr,
  Td,
  TdReplenishment,
  TdDescription,
  TdButtonRepeat,
  TdButtonReport,
  Img,
} from './TableStyled';
import { ICON_PENDING, ICON_CANCELED, ICON_SUCCEEDED } from '../../../constants/icons';
import Button from '../../../components/Buttons/Button';
import Paragraph from '../../../components/Typography/Paragraph';
import storeNames from '../../../stores/storeNames';
import InputDate from '../../../components/Inputs/InputDate';
import { STATUS } from '../../../constants/common';

const TableReplenishment = ({
  dateFrom,
  dateTo,
  inputHandlerDateTo,
  inputHandlerDateFrom,
  inputSetStore,
  handlerButton,
  fetchMoreData,
  payments,
  hasMoreItemsReplenishment,
  addFilter,
}) => {
  return (
    <>
      <Top>
        <RightContainer>
          <Paragraph fontSize="16px" textColor="#2E3238">
            Период
          </Paragraph>
          <WrapperDate>
            <DateInputWrap>
              <InputDate
                padding="12px 19px"
                name="dateFrom"
                margin="0 8px 0 0"
                value={dateFrom}
                onChange={value => {
                  inputSetStore(value, inputHandlerDateFrom, 'dateFrom');
                }}
              />
              —
              <InputDate
                padding="12px 19px"
                name="dateTo"
                value={dateTo}
                margin="0 0 0 8px"
                onChange={value => {
                  inputSetStore(value, inputHandlerDateTo, 'dateTo');
                }}
              />
            </DateInputWrap>
          </WrapperDate>
          <ButtonWrapper>
            <Button
              testID="TEST_BUTTON_LOOK"
              minWidth="127px"
              height="42px"
              lineHeight="15px"
              onClick={() => handlerButton()}
            >
              Показать
            </Button>
          </ButtonWrapper>
        </RightContainer>
      </Top>
      {payments.length > 0 ? (
        <TableWrapper>
          <InfiniteScroll
            style={{ overflow: 'auto' }}
            initialLoad={false}
            loadMore={() => fetchMoreData()}
            hasMore={hasMoreItemsReplenishment}
          >
            <TableContainer>
              <Thead>
                <TrTop>
                  <Th
                    className="active"
                    onClick={() => addFilter('yk_payment_status')}
                    width="70px"
                  >
                    Статус
                  </Th>
                  <Th className="active" onClick={() => addFilter('created_at')} width="200px">
                    Дата - время
                  </Th>
                  <Th className="active" onClick={() => addFilter('amount')} width="150px">
                    Пополнение
                  </Th>
                  <Th>Описание</Th>
                  <Th width="50px" />
                  <Th width="50px" />
                </TrTop>
              </Thead>
              <Tbody>
                {payments.map(item => (
                  <Tr key={item.id}>
                    <Td>
                      {item.yk_payment_status === STATUS.PENDING ? (
                        <Img src={ICON_PENDING} />
                      ) : item.yk_payment_status === STATUS.SUCCEEDED ? (
                        <Img src={ICON_SUCCEEDED} />
                      ) : item.yk_payment_status === STATUS.CANCELED ? (
                        <Img src={ICON_CANCELED} />
                      ) : null}
                    </Td>
                    <Td>{`${moment(item.created_at).format('DD.MM.YYYY - H:mm:ss')}`}</Td>
                    <TdReplenishment
                      className={
                        item.yk_payment_status === STATUS.PENDING
                          ? STATUS.PENDING
                          : item.yk_payment_status === STATUS.CANCELED
                          ? STATUS.CANCELED
                          : null
                      }
                    >
                      {item.amount ? `+ ${item.amount.toLocaleString('ru-RU')}` : null}
                    </TdReplenishment>
                    <TdDescription>{item.title}</TdDescription>
                    <Td>
                      {item.replenishment ? (
                        <TdButtonRepeat data-test-id="TEST_BUTTON_REPEAT" />
                      ) : null}
                    </Td>
                    <Td>
                      {item.replenishment ? (
                        <TdButtonReport data-test-id="TEST_BUTTON_REPORT" />
                      ) : null}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </TableContainer>
          </InfiniteScroll>
        </TableWrapper>
      ) : (
        'Нет истории платежей'
      )}
    </>
  );
};

TableReplenishment.propTypes = {
  dateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  inputHandlerDateTo: PropTypes.func,
  inputHandlerDateFrom: PropTypes.func,
  inputSetStore: PropTypes.func,
  handlerButton: PropTypes.func,
  fetchMoreData: PropTypes.func,
  payments: PropTypes.array,
  addFilter: PropTypes.func,
  hasMoreItemsReplenishment: PropTypes.bool,
};

TableReplenishment.defaultProps = {
  dateFrom: undefined,
  dateTo: undefined,
  hasMoreItemsReplenishment: undefined,
  payments: [],
  inputHandlerDateTo: () => {},
  inputHandlerDateFrom: () => {},
  inputSetStore: () => {},
  handlerButton: () => {},
  fetchMoreData: () => {},
  addFilter: () => {},
};

const enchance = compose(
  inject(storeNames.BalanceStore),
  observer,
  withActiveValue(false, false, false, false, true, false),
  withProfileInputSetStore,
  withDateFromState,
  withDateToState,
  withHandlers(() => ({
    addFilter: ({ offset, setOffset, BalanceStore, accountValue }) => async value => {
      BalanceStore.addSortAndOrderReplenishment(value);
      const qnt = offset >= 15 ? offset : 15;
      const items = await BalanceStore.fetchPayments(
        {
          dateFrom: BalanceStore.dateFromReplenishment,
          dateTo: BalanceStore.dateToReplenishment,
          qnt,
          offset: 0,
        },
        accountValue.id,
      );
      setOffset(items.length);
    },
    handlerButton: ({ dateFrom, dateTo, BalanceStore, accountValue }) => async () => {
      BalanceStore.addDateReplenishment({ dateFrom, dateTo });
      await BalanceStore.fetchPayments(
        { dateFrom: BalanceStore.dateFromReplenishment, dateTo: BalanceStore.dateToReplenishment },
        accountValue.id,
      );
    },
    fetchMoreData: ({ BalanceStore, setOffset, offset, accountValue }) => async () => {
      const items = await BalanceStore.fetchPayments(
        {
          dateFrom: BalanceStore.dateFromReplenishment,
          dateTo: BalanceStore.dateToReplenishment,
          offset,
        },
        accountValue.id,
      );
      setOffset(items.length);
    },
  })),
  lifecycle({
    componentWillUnmount() {
      const { BalanceStore } = this.props;
      BalanceStore.addSortAndOrderReplenishment('');
      BalanceStore.deleteOrderReplenishment();
    },
  }),
);

export default enchance(TableReplenishment);
