import API from './base';
import { SCORING_STATUS_AFTER_PAYMENT } from '../constants/statusPageUrls';

export default class ScoringApi extends API {
  /**
   * @param {String} token
   * @param {Object} data
   * email: '',
   * psrn: ''
   */
  startBillingBeforeScore(data, redirectUrl = SCORING_STATUS_AFTER_PAYMENT, token = null) {
    return this.post(
      `billing/initAcquiringForRescore?redirect_url=${redirectUrl}`,
      data,
      token,
      false,
    );
  }
}
