import { observable, decorate, action } from 'mobx';
import * as moment from 'moment';
import api from '../api';
import logger from '../logger';
import { getToken } from '../helpers';
import { RESCORE_TOKEN } from '../constants/env';

export default class DashboardStore {
  constructor(context, dialog) {
    this.context = context;
    this.dialog = dialog;
  }

  clear = () => {
    this.entity = {};
    this.entityDetail = {};
  };

  entity = {};

  getEntity = async id => {
    const wait = ms => new Promise(a => setTimeout(a, ms));
    await wait(4000);
    // this.entity = await api.entity.getEntityById(RESCORE_TOKEN, id);
    const list = (await api.inquiries.getInquiriesList(
      { limit: 99 },
      this.context.AccountStore.accountValue.rescore_account_token,
    )).result;
    logger.debug('list', list, id);
    // eslint-disable-next-line
    for (const item of list) {
      if (id === item.entity.id) {
        // eslint-disable-next-line no-await-in-loop
        this.entity = (await api.entity.getEntityById(RESCORE_TOKEN, item.initialCode1)).result;
        logger.debug('entity', this.entity);
      }
    }
  };

  entityDetail = {};

  getEntityDetail = async () => {
    this.entityDetail = await api.dashboard.getOrganization(
      this.entity.registration.number,
      getToken(),
    );
    logger.debug(this.entityDetail);
  };

  financeTypesList = [
    { id: '0710001' },
    { id: '0710002' },
    { id: '0710003' },
    { id: '0710004' },
    { id: '0710005' },
  ];

  financeDigestFields = {};

  financeYearList = [];

  syncFinance = async () => {
    const digest = await api.dashboard.getFinanceDigest(getToken());
    // eslint-disable-next-line no-useless-escape
    const subtitleRegexp = /.*\/ *(.*)/;
    digest.fields.forEach(item => {
      let result;
      const index = this.financeTypesList.findIndex(item2 => {
        const regexp = `.*${item2.id}.*?(?= |/)`;
        // logger.debug(regexp);
        result = item.full_title.match(regexp);
        // logger.debug('res', result);
        return result !== null;
      });
      if (index >= 0) {
        if (!('title' in this.financeTypesList[index]))
          // eslint-disable-next-line prefer-destructuring
          this.financeTypesList[index].title = result[0];
        // logger.debug(this.financeTypesList);
        if (!(this.financeTypesList[index].id in this.financeDigestFields))
          this.financeDigestFields[this.financeTypesList[index].id] = {};
        // logger.debug(item.full_title, subtitleRegexp);
        const subtitleResult = item.full_title.match(subtitleRegexp);
        // logger.debug(subtitleResult);
        this.financeDigestFields[this.financeTypesList[index].id][item.accounting_field_id] = {
          title: item.title.trim(),
          path: item.full_title.split('/').map(name => name.trim()),
          subtitle: subtitleResult !== null && index === 3 ? subtitleResult[1] : '',
          accounting_row_num: item.accounting_row_num,
          parent_field_id: item.parent_field_id,
        };
        // logger.debug(this.financeDigestFields);
      }
    });
    logger.debug(this.financeTypesList);
    logger.debug('financeDigestFields', this.financeDigestFields);

    this.financeYearList = (await api.dashboard.getFinanceBalanceList(
      this.entity.registration.number,
      getToken(),
    )).reports.map(item => item.year);
    logger.debug(this.financeYearList);
    return this.financeYearList;
  };

  getFinanceTypes = () => this.financeTypesList;

  // getFinanceYears = () => this.financeYearList;

  getFinance = async (yearFrom, yearTo) => {
    // logger.debug(yearFrom, yearTo);
    if (yearFrom > yearTo) return null;
    // logger.debug(yearFrom, yearTo);
    // const financeArray = [];
    const finance = {};
    for (let year = yearFrom; year <= yearTo; year += 1) {
      // eslint-disable-next-line no-await-in-loop
      const financeRaw = await api.dashboard.getFinanceBalanceExt(
        this.entity.registration.number,
        year,
        getToken(),
      );
      // financeArray.push(financeRaw);

      // eslint-disable-next-line
      for (const typeItem of this.financeTypesList) {
        if (!(typeItem.id in finance)) finance[typeItem.id] = {};
        financeRaw.values.forEach(item => {
          if (item.accounting_field_id in this.financeDigestFields[typeItem.id]) {
            /* if (!(this.financeDigestFields[typeItem.id].path[1] in finance[typeItem.id])) { */
            // finance[typeItem.id][this.financeDigestFields[typeItem.id].path[1]][0];
            /* const inner = {
              title: this.financeDigestFields[typeItem.id][item.accounting_field_id].title,
              rowNum: this.financeDigestFields[typeItem.id][item.accounting_field_id]
                .accounting_row_num,
              values: [],
            }; */
            let chain = finance[typeItem.id]; // [this.financeDigestFields[typeItem.id]];
            for (
              let i = 1;
              i < this.financeDigestFields[typeItem.id][item.accounting_field_id].path.length;
              i += 1
            ) {
              if (
                !(this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i] in chain)
              ) {
                chain[this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i]] = {};
              }
              chain =
                chain[this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i]];
              if (
                this.financeDigestFields[typeItem.id][item.accounting_field_id].path[i] ===
                this.financeDigestFields[typeItem.id][item.accounting_field_id].title
              )
                break;
            }
            // дополнительные ветки из .subtitle
            if (this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle !== '') {
              if (
                !(this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle in chain)
              ) {
                chain[
                  this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle
                ] = {};
              }
              chain =
                chain[this.financeDigestFields[typeItem.id][item.accounting_field_id].subtitle];
            }
            if (!('values' in chain)) {
              // chain.title = this.financeDigestFields[typeItem.id][item.accounting_field_id].title;
              chain.rowNum = this.financeDigestFields[typeItem.id][
                item.accounting_field_id
              ].accounting_row_num;
              chain.values = [];
            }
            if (chain.values.length === 0 || chain.values[chain.values.length - 1].year !== year) {
              chain.values.push({
                year,
                rowNum: item.accounting_row_num,
                value: item.field_value,
              });
            }
            /* } */
            /* if (!(item.accounting_field_id in finance[typeItem.id])) {
              finance[typeItem.id][item.accounting_field_id] = { values: [] };
              finance[typeItem.id][item.accounting_field_id].title = this.financeDigestFields[
                typeItem.id
              ][item.accounting_field_id].title;
              finance[typeItem.id][item.accounting_field_id].subtitle = this.financeDigestFields[
                typeItem.id
              ][item.accounting_field_id].subtitle;
              finance[typeItem.id][item.accounting_field_id].rowNum = this.financeDigestFields[
                typeItem.id
              ][item.accounting_field_id].accounting_row_num;
            }
            finance[typeItem.id][item.accounting_field_id].values.push({
              year,
              rowNum: item.accounting_row_num,
              value: item.field_value,
            }); */
          }
        });
      }
    }
    logger.debug('>FINANCE<', finance);
    return finance;
  };

  getFinanceFNS = async (yearFrom, yearTo) => {
    if (yearFrom > yearTo) return null;
    const finance = {};
    for (let year = yearFrom; year <= yearTo; year += 1) {
      // eslint-disable-next-line no-await-in-loop
      const financeRaw = await api.dashboard.getFinanceBalanceFNS(
        this.entity.registration.number,
        year,
        getToken(),
      );
      logger.debug('FNS:', financeRaw);

      const metaFNS = [
        {
          key: 'revenue_expense',
          title: 'Информация о доходах/расходах',
          type: 'branch',
          childs: [
            { key: 'revenue', title: 'Доходы', type: 'number' },
            { key: 'expense', title: 'Расходы', type: 'number' },
            { key: 'date_doc_fns', title: 'Дата публикации', type: 'date' },
          ],
        },
        {
          key: 'employee_count',
          title: 'Информация о численности сотрудников',
          type: 'branch',
          childs: [
            { key: 'employee_avg_number', title: 'Среднесписочная численность', type: 'number' },
            { key: 'date_doc_fns', title: 'Дата публикации', type: 'date' },
          ],
        },
        {
          key: 'taxes',
          title: 'Уплаченные налоги',
          type: 'array',
          params: {
            title: 'name_of_tax',
            value: 'tax',
            date: 'date_doc_fns',
            dateTitle: 'Дата публикации',
          },
        },
      ];

      // eslint-disable-next-line
      for (const meta of metaFNS) {
        if (!(meta.title in finance)) finance[meta.title] = {};
        if (meta.type === 'branch') {
          // eslint-disable-next-line
          for (const child of meta.childs) {
            // eslint-disable-next-line no-continue
            if (financeRaw[meta.key] !== null) {
              if (!(child.title in finance[meta.title])) {
                finance[meta.title][child.title] = {};
                finance[meta.title][child.title].values = [];
              }
              finance[meta.title][child.title].values.push({
                year: financeRaw.year,
                rowNum: null,
                value:
                  child.type === 'date'
                    ? moment(financeRaw[meta.key][child.key]).format('DD.MM.YYYY')
                    : financeRaw[meta.key][child.key],
              });
            }
          }
        }

        if (meta.type === 'array') {
          if (financeRaw[meta.key] !== null) {
            // eslint-disable-next-line
            for (const item of financeRaw[meta.key]) {
              if (!(meta.params.title in finance[meta.title])) {
                finance[meta.title][item[meta.params.title]] = {};
                finance[meta.title][item[meta.params.title]].values = [];
              }
              finance[meta.title][item[meta.params.title]].values.push({
                year: financeRaw.year,
                rowNum: null,
                value: item[meta.params.value],
              });
            }
          }
        }
      }
    }
    logger.debug(finance);
    return finance;
  };
}

decorate(DashboardStore, {
  clear: action,
  entity: observable,
  getEntity: action,
  entityDetail: observable,
  getEntityDetail: action,
  syncFinance: action,
  getFinance: action,
  getFinanceFNS: action,

  // financeTypesList: observable,
  financeYearList: observable,
  // financeDigestFields: observable,
});
