import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose, withState, withProps, lifecycle } from 'recompose';
import { Wrapper, Title, TopWrapper, SelectWrapper, Tabs, TabsItem } from './BalanceStyled';
import storeNames from '../../stores/storeNames';
import { withBoolState, withActiveValue, withAccounts, withUserData } from '../../utils/hocs';
import { RoleRender } from '../../components/RoleComponents';
import { CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT } from '../../constants/roles';
import Statistic from './Statistic/Statistic';
import TableReplenishment from './Table/TableReplenishment';
import TableDebit from './Table/TableDebit';
import Select from '../../components/Select/Select';
import ReturnRequestPopup from './Popups/ReturnRequestPopup';
import ReplenishmentPopup from '../Popups/ReplenishmentPopup';
import ReplenishmentAgreementPopup from './Popups/ReplenishmentAgreementPopup';
import { BALANCE_TYPES } from '../../constants/common';
import history from '../../history';

const Balance = ({
  accountValue,
  setAccountValue,
  balanceHistory,
  setBalanceHistory,
  toggleOpenReturnRequestPopup,
  openReturnRequestPopup,
  openReplenishmentBalancePopup,
  openReplenishmentAgreementPopup,
  accounts,
  stats,
  payments,
  purchases,
  toggleOpenReplenishmentBalancePopup,
  toggleOpenReplenishmentAgreementPopup,
  hasMoreItemsReplenishment,
  hasMoreItemsPurchases,
  isOnlyCustomerAdmin,
  userData,
  userIsLoading,
}) => (
  <>
    {userData && userData.id && !userIsLoading && !isOnlyCustomerAdmin && (
      <Wrapper>
        <TopWrapper>
          <Title as="h2">Баланс лицевого счета</Title>
          <RoleRender allowedRoles={[CUSTOMER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT]}>
            {accountValue.id && accounts.length > 1 ? (
              <SelectWrapper>
                <Select
                  placeholder="Основной лицевой счет"
                  name="accountValue"
                  value={accountValue}
                  setValue={setAccountValue}
                  options={accounts && accounts}
                />
              </SelectWrapper>
            ) : null}
          </RoleRender>
        </TopWrapper>
        <Statistic
          scoring={stats.scores || 0}
          // monitoring={stats.monitors || 0}
          money={accountValue.money}
          accountId={accountValue.id}
          toggleOpenReturnRequestPopup={toggleOpenReturnRequestPopup}
          toggleOpenReplenishmentBalancePopup={toggleOpenReplenishmentBalancePopup}
          toggleOpenReplenishmentAgreementPopup={toggleOpenReplenishmentAgreementPopup}
        />
        <Tabs>
          <TabsItem
            data-test-id="TEST_TAB_REPLENISHMENT"
            className={balanceHistory === BALANCE_TYPES.REPLENISHMENT ? 'active' : null}
            onClick={() => setBalanceHistory(BALANCE_TYPES.REPLENISHMENT)}
          >
            История платежей
          </TabsItem>
          <TabsItem
            data-test-id="TEST_TAB_DEBIT"
            className={balanceHistory === BALANCE_TYPES.DEBIT ? 'active' : null}
            onClick={() => setBalanceHistory(BALANCE_TYPES.DEBIT)}
          >
            История покупок
          </TabsItem>
        </Tabs>
        {balanceHistory === BALANCE_TYPES.REPLENISHMENT ? (
          <TableReplenishment
            hasMoreItemsReplenishment={hasMoreItemsReplenishment}
            payments={!payments ? [] : payments}
          />
        ) : null}
        {balanceHistory === BALANCE_TYPES.DEBIT ? (
          <TableDebit
            hasMoreItemsPurchases={hasMoreItemsPurchases}
            purchases={!purchases ? [] : purchases}
          />
        ) : null}
      </Wrapper>
    )}
    {userData &&
      userData.id &&
      !userIsLoading &&
      isOnlyCustomerAdmin &&
      history.replace('/profile')}
    {openReturnRequestPopup ? (
      <ReturnRequestPopup toggleOpenReturnRequestPopup={toggleOpenReturnRequestPopup} />
    ) : null}
    {openReplenishmentBalancePopup ? (
      <ReplenishmentPopup
        toggleOpenReplenishmentBalancePopup={toggleOpenReplenishmentBalancePopup}
        accountValue={accountValue}
      />
    ) : null}
    {openReplenishmentAgreementPopup ? (
      <ReplenishmentAgreementPopup
        toggleOpenReplenishmentAgreementPopup={toggleOpenReplenishmentAgreementPopup}
      />
    ) : null}
  </>
);

Balance.propTypes = {
  balanceHistory: PropTypes.string,
  accountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setAccountValue: PropTypes.func.isRequired,
  setBalanceHistory: PropTypes.func.isRequired,
  toggleOpenReturnRequestPopup: PropTypes.func.isRequired,
  toggleOpenReplenishmentBalancePopup: PropTypes.func.isRequired,
  toggleOpenReplenishmentAgreementPopup: PropTypes.func.isRequired,
  openReturnRequestPopup: PropTypes.bool.isRequired,
  openReplenishmentAgreementPopup: PropTypes.bool.isRequired,
  openReplenishmentBalancePopup: PropTypes.bool.isRequired,
  userIsLoading: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  isOnlyCustomerAdmin: PropTypes.bool.isRequired,
  stats: PropTypes.object,
  accounts: PropTypes.array,
  payments: PropTypes.array,
  purchases: PropTypes.array,
  hasMoreItemsReplenishment: PropTypes.bool,
  hasMoreItemsPurchases: PropTypes.bool,
};

Balance.defaultProps = {
  balanceHistory: 'Replenishment',
  accountValue: '',
  stats: {},
  payments: [],
  purchases: [],
  accounts: [],
  userIsLoading: false,
  hasMoreItemsReplenishment: undefined,
  hasMoreItemsPurchases: undefined,
};

const enchance = compose(
  inject(storeNames.ServicesStore, storeNames.AccountStore, storeNames.BalanceStore),
  withActiveValue(true, false, false, true, true, false),
  observer,
  withProps(({ ServicesStore, BalanceStore }) => ({
    purchases: BalanceStore.purchases,
    payments: BalanceStore.payments,
    hasMoreItemsReplenishment: BalanceStore.hasMoreItemsReplenishment,
    hasMoreItemsPurchases: BalanceStore.hasMoreItemsPurchases,
    stats: ServicesStore.stats,
  })),
  withAccounts,
  withUserData,
  withState('balanceHistory', 'setBalanceHistory', 'Replenishment'),
  withBoolState('openReturnRequestPopup', false),
  withBoolState('openReplenishmentAgreementPopup', false),
  withBoolState('openReplenishmentBalancePopup', false),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { AccountStore, accountValue, ServicesStore, BalanceStore } = prevProps;
      const account = AccountStore.accounts.find(item => item.id === accountValue.id);
      if (account) {
        if (this.props.accountValue !== accountValue) {
          const newAccount = AccountStore.accounts.find(
            item => item.id === this.props.accountValue.id,
          );
          await ServicesStore.fetchStats(newAccount.id);
          await BalanceStore.fetchPayments({ offset: 0 }, newAccount.id);
          await BalanceStore.fetchPurchases({ offset: 0 }, newAccount.id);
        }
      }
      if (account) {
        if (accountValue.money !== account.money) {
          AccountStore.addAccountValue({
            ...account,
            text: account.full_name,
            value: account.id,
          });
        }
      }
    },
  }),
);

export default enchance(Balance);
