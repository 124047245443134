import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, lifecycle, withProps, withHandlers } from 'recompose';
import { observer, inject } from 'mobx-react';
import * as moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import history from '../../../history';
import {
  Wrapper,
  TableContainer,
  Tr,
  TrTop,
  Th,
  Td,
  Thead,
  Tbody,
  TdButton,
  ButtonEdit,
  CheckBoxWrapper,
  TableButton,
  Text,
} from './TableStyled';
import { withUserData, withFetchUserInDidMount } from '../../../utils/hocs';
import { RoleRender } from '../../../components/RoleComponents';
import storeNames from '../../../stores/storeNames';
import CheckboxTable from '../../../components/CheckBoxes/CheckBoxTable';
import CheckBox from '../../../components/CheckBoxes/CheckBox';
import { CUSTOMER_LEGAL_ENTITY } from '../../../constants/customerTypes';
import {
  SUPER_ADMIN_ROLE,
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_ACCOUNTANT,
} from '../../../constants/roles';
import { getAccessStatus } from '../../../helpers/roles';

const Table = ({
  accounts,
  toggleEditAccountPopup,
  setItemId,
  fetchAccounts,
  confirmed,
  toggleConfirmed,
  deleteAccount,
  userType,
  hasMoreItems,
  account,
  isCustomerAdmin,
  addFilter,
}) => {
  let accountsActive;
  let items;
  let itemAccount;
  if (account.length > 0) {
    accountsActive = account.filter(item => !item.blocked);
    items = confirmed ? accountsActive : account;
  } else if (accounts.length > 0) {
    accountsActive = accounts.filter(item => !item.blocked);
    items = confirmed ? accountsActive : accounts;
    itemAccount = true;
  } else {
    items = [];
  }
  return (
    <Wrapper>
      {itemAccount && (
        <CheckBoxWrapper>
          <CheckBox testID="TEST_CHECKBOX" checked={confirmed} onChange={() => toggleConfirmed()}>
            <Text>Показывать только активные счета</Text>
          </CheckBox>
        </CheckBoxWrapper>
      )}
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => fetchAccounts()}
        hasMore={hasMoreItems}
        style={{ overflow: 'auto' }}
      >
        <TableContainer>
          <Thead>
            <TrTop>
              <Th
                className={items.length > 1 ? 'active' : ''}
                onClick={() => (items.length > 1 ? addFilter('full_name') : null)}
              >
                Название
              </Th>
              <Th
                className={items.length > 1 ? 'active' : ''}
                width="200px"
                onClick={() => (items.length > 1 ? addFilter('created_at') : null)}
              >
                Дата создания
              </Th>
              <Th>Период действия </Th>
              <Th>Заметка</Th>
              <Th
                className={items.length > 1 ? 'active' : ''}
                onClick={() => (items.length > 1 ? addFilter('money') : null)}
              >
                Сумма, руб
              </Th>
              <Th />
              <Th />
              {(userType === CUSTOMER_LEGAL_ENTITY && isCustomerAdmin && (
                <>
                  <Th />
                  <Th
                    className={items.length > 1 ? 'active' : ''}
                    onClick={() => (items.length > 1 ? addFilter('blocked') : null)}
                  >
                    Статус
                  </Th>
                </>
              )) ||
                null}
            </TrTop>
          </Thead>
          <Tbody>
            {items &&
              items.map(item => (
                <Tr key={item.id}>
                  <Td>{item.full_name}</Td>
                  <Td>{`${moment(item.created_at).format('DD.MM.YYYY - H:mm:ss')}`}</Td>
                  <Td>
                    {item.from_time && item.to_time && (
                      <>
                        {moment(item.from_time).format('DD.MM.YYYY')} &#8212;{' '}
                        {moment(item.to_time).format('DD.MM.YYYY')}
                      </>
                    )}
                    {!item.from_time && item.to_time && <>&#8212; {item.to_time}</>}
                    {!item.to_time && item.from_time && <>{item.from_time} &#8212;</>}
                    {!item.to_time && !item.from_time && <>&#8212;</>}
                  </Td>
                  <Td>{item.notes}</Td>
                  <Td>{item.money ? item.money.toLocaleString('ru-Ru') : 0}</Td>
                  <Td width="60px">
                    <TableButton
                      data-test-id="TEST_BUTTON_BALANCE"
                      onClick={() => {
                        setItemId(item.id);
                        history.push('/balance');
                      }}
                    >
                      Баланс
                    </TableButton>
                  </Td>
                  <Td width="60px">
                    <TableButton
                      data-test-id="TEST_BUTTON_INQUIRIES"
                      onClick={() => {
                        setItemId(item.id);
                        history.push('/services');
                      }}
                    >
                      Услуги
                    </TableButton>
                  </Td>
                  {(userType === CUSTOMER_LEGAL_ENTITY && isCustomerAdmin && (
                    <>
                      <RoleRender allowedRoles={[SUPER_ADMIN_ROLE, CUSTOMER_ADMIN_ROLE]}>
                        <TdButton>
                          <ButtonEdit
                            data-test-id="TEST_BUTTON_EDIT"
                            onClick={() => {
                              setItemId(item.id);
                              toggleEditAccountPopup();
                            }}
                          >
                            edit
                          </ButtonEdit>
                        </TdButton>
                      </RoleRender>
                      <Td width="130px">
                        <CheckboxTable checked={!item.blocked} onChange={() => deleteAccount(item)}>
                          {!item.blocked ? 'Активный' : 'Неактивный'}
                        </CheckboxTable>
                      </Td>
                    </>
                  )) ||
                    null}
                </Tr>
              ))}
          </Tbody>
        </TableContainer>
      </InfiniteScroll>
    </Wrapper>
  );
};

Table.propTypes = {
  accounts: PropTypes.any,
  confirmed: PropTypes.bool.isRequired,
  toggleConfirmed: PropTypes.func.isRequired,
  toggleEditAccountPopup: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  setItemId: PropTypes.func.isRequired,
  hasMoreItems: PropTypes.bool,
  isCustomerAdmin: PropTypes.bool.isRequired,
  fetchAccounts: PropTypes.func,
  addFilter: PropTypes.func,
  deleteAccount: PropTypes.func,
  account: PropTypes.any,
};

Table.defaultProps = {
  accounts: undefined,
  account: undefined,
  hasMoreItems: true,
  fetchAccounts: () => {},
  deleteAccount: () => {},
  addFilter: () => {},
};

const enhance = compose(
  inject(storeNames.AccountStore, storeNames.ProfileStore),
  withFetchUserInDidMount,
  withUserData,
  observer,
  withState('init', 'setInit', false),
  withState('load', 'setLoad', false),
  withState('oneOffset', 'setOneOffset', 0),
  withProps(({ AccountStore }) => ({
    account: AccountStore.account,
    accounts: AccountStore.scrollAccounts,
    isLoading: AccountStore.isBusyScroll,
    addSortAndOrder: AccountStore.addSortAndOrder,
    fetchScrollMyAccounts: AccountStore.fetchScrollMyAccounts,
    blockAccount: AccountStore.blockAccount,
    hasMoreItems: AccountStore.hasMoreItems,
  })),
  withHandlers(({ blockAccount, fetchScrollMyAccounts }) => ({
    addFilter: ({ addSortAndOrder, offset, setOffset, setLoad }) => async value => {
      addSortAndOrder(value);
      setLoad(false);
      const qnt = offset >= 15 ? offset : 15;
      const accounts = await fetchScrollMyAccounts({ qnt, offset: 0 });
      setOffset(accounts.length);
      setLoad(true);
    },
    fetchAccounts: ({ setOffset, offset, oneOffset, init }) => async () => {
      if (!init || oneOffset) return;
      const accounts = await fetchScrollMyAccounts({ offset });
      setOffset(accounts.length);
    },
    deleteAccount: ({ setOffset, offset, setLoad }) => async item => {
      setLoad(false);
      await blockAccount(item.id, !item.blocked);
      const qnt = offset >= 15 ? offset : 15;
      const accounts = await fetchScrollMyAccounts({ qnt, offset: 0 });
      setOffset(accounts.length);
      setLoad(true);
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { AccountStore, offset, setOffset, ProfileStore, setOneOffset, setInit } = this.props;
      const { fetchScrollMyAccounts, fetchMyAccount } = AccountStore;
      const user = await ProfileStore.fetchUser();
      const userRoles = (user && user.roles && user.roles.map(item => item.role_name)) || undefined;

      const hasUser = !getAccessStatus({
        userRoles,
        allowedRoles: [CUSTOMER_ADMIN_ROLE, SUPER_ADMIN_ROLE, CUSTOMER_ACCOUNTANT],
      });

      if ((user.customer_id === 0 && hasUser) || hasUser) {
        if (user.account_id !== null) {
          await fetchMyAccount(user.account_id);
          setOneOffset(1);
        }
      } else {
        const accounts = await fetchScrollMyAccounts({ qnt: 15, offset });
        setOffset(accounts.length);
      }
      setInit(true);
    },
    componentWillUnmount() {
      const { AccountStore } = this.props;
      AccountStore.addSortAndOrder('');
      AccountStore.deleteOrder();
    },
  }),
);

export default enhance(Table);
