export default {
  // SessionStore: 'SessionStore',
  AccountStore: 'AccountStore',
  BalanceStore: 'BalanceStore',
  CustomerStore: 'CustomerStore',
  DialogStore: 'DialogStore',
  DocumentsStore: 'DocumentsStore',
  EntityStore: 'EntityStore',
  IndicatorsStore: 'IndicatorsStore',
  InquiriesStore: 'InquiriesStore',
  Modals: 'Modals',
  ProfileStore: 'ProfileStore',
  RecoveryStore: 'RecoveryStore',
  RegistrationStore: 'RegistrationStore',
  ScoringStore: 'ScoringStore',
  ServicesStore: 'ServicesStore',
  SharedInfoStore: 'SharedInfoStore',
  UserStore: 'UserStore',
  DashboardStore: 'DashboardStore',
};
