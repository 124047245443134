import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withProps } from 'recompose';
import { observer, inject } from 'mobx-react';
import { useMedia } from 'use-media';
import storeNames from '../../../stores/storeNames';
import { getAccessStatus } from '../../../helpers/roles';
import {
  Wrapper,
  Item,
  Top,
  Name,
  ScoringWrapper,
  Scoring,
  ScoringScore,
  ScoringRight,
  ScoringTop,
  ScoringTime,
  MonitoringWrapper,
  Monitoring,
  MonitoringScore,
  MonitoringRight,
  MonitoringTop,
  MonitoringTime,
  Bottom,
  Price,
  Span,
  ButtonWrapper,
  Buttons,
  ButtonDelete,
  ButtonEdit,
  BottomAdmins,
} from './PurchaseServicesStyled';
import Button from '../../../components/Buttons/Button';
import { SUPER_ADMIN_ROLE } from '../../../constants/roles';
import { withUserData } from '../../../utils/hocs';

const PurchaseServices = ({
  toggleOpenPurchasePopup,
  setTariffId,
  tariffs,
  userData,
  userRoles,
  toggleOpenCreatePopup,
  toggleOpenDeletePopup,
  toggleOpenPurshaseAgreementPopup,
}) => {
  const mobMedia = useMedia({ maxWidth: 1100 });
  return (
    <Wrapper>
      {userData.customer_id === 0 &&
        getAccessStatus({
          userRoles,
          allowedRoles: [SUPER_ADMIN_ROLE],
        }) && (
          <ButtonWrapper>
            <Button
              width="auto"
              onClick={() => {
                toggleOpenCreatePopup();
                setTariffId(undefined);
              }}
              sizes={{ margin: '0 10px 10px 0' }}
              minWidth={!mobMedia ? '233px' : '160px'}
              testID="TEST_BUTTON_CREATE_TARIF"
            >
              Добавить тариф
            </Button>
            <Button
              width="auto"
              onClick={() => {
                toggleOpenPurshaseAgreementPopup();
              }}
              sizes={{ margin: '0 0 10px 0' }}
              minWidth={!mobMedia ? '233px' : '160px'}
              testID="TEST_BUTTON_AGREEMENT_TARIF"
            >
              Купить тариф по договору
            </Button>
          </ButtonWrapper>
        )}
      {tariffs &&
        tariffs.map(item => (
          <Item key={item.id}>
            <Top>
              <Name>{item.full_name}</Name>
              <ScoringWrapper>
                {item.scores > 0 ? (
                  <Scoring>
                    <ScoringScore>{item.scores}</ScoringScore>
                    <ScoringRight>
                      <ScoringTop>{item.scores === 1 ? 'скоринг' : 'скорингов'}</ScoringTop>
                      <ScoringTime>
                        {item.scores_ttl >= 1000000 ? 'бессрочно' : `на ${item.scores_ttl} дней`}
                      </ScoringTime>
                    </ScoringRight>
                  </Scoring>
                ) : (
                  'Скоринг не входит в тариф'
                )}
              </ScoringWrapper>
              <MonitoringWrapper>
                {item.monitors > 0 ? (
                  <Monitoring>
                    <MonitoringScore>{item.monitors}</MonitoringScore>
                    <MonitoringRight>
                      <MonitoringTop>дней мониторинга</MonitoringTop>
                      <MonitoringTime>
                        {item.monitors_ttl >= 1000000
                          ? 'бессрочно'
                          : `на ${item.monitors_ttl} дней`}
                      </MonitoringTime>
                    </MonitoringRight>
                  </Monitoring>
                ) : (
                  'Мониторинг не входит в тариф'
                )}
              </MonitoringWrapper>
            </Top>
            {userData.customer_id === 0 &&
            getAccessStatus({
              userRoles,
              allowedRoles: [SUPER_ADMIN_ROLE],
            }) ? (
              <BottomAdmins>
                <Price>
                  Стоимость <Span>{item.price} ₽</Span>
                </Price>
                <Buttons>
                  <ButtonDelete
                    data-test-id="TEST_BUTTON_DELETE"
                    onClick={() => {
                      toggleOpenDeletePopup();
                      setTariffId(item.id);
                    }}
                  >
                    удалить
                  </ButtonDelete>
                  <ButtonEdit
                    tariffs={tariffs}
                    data-test-id="TEST_BUTTON_EDIT"
                    onClick={() => {
                      toggleOpenCreatePopup();
                      setTariffId(item.id);
                    }}
                  >
                    edit
                  </ButtonEdit>
                  <Button
                    height="48px"
                    width="auto"
                    onClick={() => {
                      toggleOpenPurchasePopup();
                      setTariffId(item.id);
                    }}
                    minWidth="121px"
                    testID="TEST_BUTTON_BUY_SERVICES"
                  >
                    Купить
                  </Button>
                </Buttons>
              </BottomAdmins>
            ) : (
              <Bottom>
                <Price>
                  Стоимость <Span>{item.price} ₽</Span>
                </Price>
                <Button
                  width="auto"
                  onClick={() => {
                    toggleOpenPurchasePopup();
                    setTariffId(item.id);
                  }}
                  minWidth={!mobMedia ? '233px' : '160px'}
                  testID="TEST_BUTTON_BUY_SERVICES"
                >
                  Купить
                </Button>
              </Bottom>
            )}
          </Item>
        ))}
    </Wrapper>
  );
};

PurchaseServices.propTypes = {
  toggleOpenPurchasePopup: PropTypes.func.isRequired,
  toggleOpenCreatePopup: PropTypes.func.isRequired,
  toggleOpenPurshaseAgreementPopup: PropTypes.func.isRequired,
  toggleOpenDeletePopup: PropTypes.func.isRequired,
  setTariffId: PropTypes.func.isRequired,
  tariffs: PropTypes.array,
  userRoles: PropTypes.array,
  userData: PropTypes.object,
};

PurchaseServices.defaultProps = {
  tariffs: [],
  userRoles: [],
  userData: {},
};

const enhancer = compose(
  inject(storeNames.ServicesStore, storeNames.ProfileStore),
  withUserData,
  observer,
  withProps(({ ServicesStore }) => ({
    tariffs: ServicesStore.servicesTariffs,
  })),
  lifecycle({
    async componentDidMount() {
      const { ServicesStore, ProfileStore } = this.props;
      const { fetchMyTariffs } = ServicesStore;
      await fetchMyTariffs();
      await ProfileStore.fetchUser();
    },
  }),
);

export default enhancer(PurchaseServices);
