import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import {
  ItemWrapper,
  Text,
  Middle,
  Img,
  ScoresLine,
  AllCount,
  AllCountSpan,
  Bottom,
  DateLine,
  DateText,
} from '../ActiveServicesStyled';
import { ICON_SCORING } from '../../../../constants/icons';
import percent from '../../../../helpers/percent';

const Scores = ({ scores, scoresAll, scoresToTime, fromTime, scoresTtl }) => {
  const dayFrom = new Date(fromTime);
  const dayTo = new Date(scoresToTime);
  const currentDay = new Date();
  const currentDaysCounter = Math.ceil(
    Math.abs(dayTo.getTime() - currentDay.getTime()) / (1000 * 3600 * 24),
  );
  const allDays = Math.ceil(Math.abs(dayTo.getTime() - dayFrom.getTime()) / (1000 * 3600 * 24));
  return (
    <ItemWrapper>
      <Text>Скорингов</Text>
      <Middle>
        <Img src={ICON_SCORING} />
        <ScoresLine width={`${percent(scores, scoresAll)}%`} />
        <AllCount>
          {scores}
          <AllCountSpan>{scoresAll > 0 ? 'из' : null}</AllCountSpan>
          {scoresAll > 0 ? scoresAll : null}
        </AllCount>
      </Middle>
      <Bottom>
        {scoresTtl !== 1000000 ? (
          <DateLine
            width={currentDay > dayTo ? `0%` : `${percent(currentDaysCounter, allDays)}%`}
          />
        ) : null}
        <DateText>
          {scoresTtl !== 1000000 ? moment(scoresToTime).format('DD.MM.YYYY') : `бессрочно`}
        </DateText>
      </Bottom>
    </ItemWrapper>
  );
};

Scores.propTypes = {
  fromTime: PropTypes.string,
  scoresToTime: PropTypes.string,
  scores: PropTypes.number,
  scoresAll: PropTypes.number,
  scoresTtl: PropTypes.number,
};

Scores.defaultProps = {
  fromTime: '',
  scoresToTime: '',
  scores: undefined,
  scoresAll: undefined,
  scoresTtl: undefined,
};

export default Scores;
