import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Scores from './Scores';
import Monitors from './Monitors';
import { Wrapper, Title, DateActive, Content } from '../ActiveServicesStyled';

const Tariff = ({
  fromTime,
  monitorsTtl,
  scoresTtl,
  fullName,
  qnt,
  scoresAll,
  monitorsAll,
  fuels,
}) => {
  const score = fuels.find(item => item.type === 'scores');
  const monitor = fuels.find(item => item.type === 'monitors');
  return (
    <Wrapper>
      <Title>
        {`${fullName}`}
        {qnt > 1 ? ` x${qnt}` : null}
      </Title>
      <DateActive>{`активный с ${moment(fromTime).format('DD.MM.YYYY')}`}</DateActive>
      <Content>
        {score ? (
          <Scores
            fromTime={fromTime}
            scores={score.scores}
            scoresToTime={score.to_time}
            scoresAll={scoresAll}
            scoresTtl={scoresTtl}
          />
        ) : null}
        {monitor ? (
          <Monitors
            fromTime={fromTime}
            monitors={monitor.monitors}
            monitorsToTime={monitor.to_time}
            monitorsAll={monitorsAll}
            monitorsTtl={monitorsTtl}
          />
        ) : null}
      </Content>
    </Wrapper>
  );
};

Tariff.propTypes = {
  fromTime: PropTypes.string,
  monitorsTtl: PropTypes.number,
  scoresTtl: PropTypes.number,
  fullName: PropTypes.string,
  fuels: PropTypes.array,
  qnt: PropTypes.number,
  scoresAll: PropTypes.number,
  monitorsAll: PropTypes.number,
};

Tariff.defaultProps = {
  fromTime: '',
  monitorsTtl: undefined,
  scoresTtl: undefined,
  fullName: '',
  fuels: [],
  qnt: undefined,
  scoresAll: undefined,
  monitorsAll: undefined,
};

export default Tariff;
