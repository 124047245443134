import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import { inject, observer } from 'mobx-react';

import ActionButtons from '../ActionButtons';
import storeNames from '../../../stores/storeNames';
import ActivityTd from '../ActivityTd';
import DateTd from '../DateTd';
import {
  Wrapper,
  TableContainer,
  Tr,
  TrTop,
  Th,
  Td,
  TdWrapper,
  TdTop,
  TdBottom,
  Thead,
  Tbody,
  ThDate,
} from './TableStyled';
import { withActiveValue } from '../../../utils/hocs';

const Table = ({
  inquiries,
  fetchMoreData,
  hasMoreItems,
  accountValue,
  inquiriesLoading,
  setItemId,
  setNewInquiries,
  toggleInquiriesPopup,
}) => {
  return (
    <Wrapper>
      {inquiries.length > 0 && (
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => fetchMoreData()}
          hasMore={hasMoreItems}
          style={{ overflow: 'auto' }}
        >
          <TableContainer>
            <Thead>
              <TrTop>
                <Th width="80px">Статус</Th>
                <ThDate width="200px">Дата</ThDate>
                <Th>Организация</Th>
              </TrTop>
            </Thead>
            <Tbody>
              {inquiries.map(item => (
                <Tr key={item.id}>
                  <ActivityTd item={item} />
                  <DateTd item={item} />
                  {item.entity ? (
                    <Td>
                      <TdWrapper>
                        <TdTop>
                          {item.entity.name.local.full ? item.entity.name.local.full : ''}{' '}
                        </TdTop>
                        <TdBottom>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Открыть профиль организации на rbc.ru"
                            href={`https://companies.rbc.ru/id/${
                              item.entity.local.ru['огрн'] !== ''
                                ? item.entity.local.ru['огрн']
                                : item.initialCode1
                            }-slug`}
                          >
                            {item.entity.local.ru['инн']} /
                            {item.entity.local.ru['огрн'] !== ''
                              ? item.entity.local.ru['огрн']
                              : item.initialCode1}
                          </a>
                        </TdBottom>
                      </TdWrapper>
                    </Td>
                  ) : (
                    <Td>{item.initialCode1}</Td>
                  )}
                  <ActionButtons
                    item={item}
                    accountValue={accountValue}
                    setItemId={setItemId}
                    setNewInquiries={setNewInquiries}
                    toggleInquiriesPopup={toggleInquiriesPopup}
                  />
                </Tr>
              ))}
            </Tbody>
          </TableContainer>
        </InfiniteScroll>
      )}
      {(inquiriesLoading && 'Идёт обновление данных') ||
        (!inquiriesLoading && inquiries.length === 0 && 'Нет истории запросов')}
    </Wrapper>
  );
};

Table.propTypes = {
  inquiries: PropTypes.array,
  accountValue: PropTypes.object,
  fetchMoreData: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  inquiriesLoading: PropTypes.bool,
  setItemId: PropTypes.func.isRequired,
  setNewInquiries: PropTypes.func.isRequired,
  toggleInquiriesPopup: PropTypes.func.isRequired,
};

Table.defaultProps = {
  inquiries: [],
  fetchMoreData: () => {},
  hasMoreItems: undefined,
  inquiriesLoading: false,
  accountValue: {},
};

const enhance = compose(
  inject(storeNames.InquiriesStore),
  observer,
  withActiveValue(false, false, false, false, false, true),
  withHandlers(() => ({
    fetchMoreData: ({ InquiriesStore, setOffset, offset, accountValue }) => async () => {
      const items = await InquiriesStore.fetchInquiries(
        { offset },
        accountValue.rescore_account_token,
      );
      setOffset(items.length);
    },
  })),
);

export default enhance(Table);
