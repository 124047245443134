import { observable, decorate, action } from 'mobx';
import {
  getSystemInfo,
  isValidSystemSetup,
  getUserCertificates,
  // getCertificate,
  createHash,
  createDetachedSignature,
  /* Certificate */
} from 'crypto-pro';
// import { DialogStore } from './Dialog';
import api from '../api';
// import history from '../history';
import logger from '../logger';
import { getToken } from '../helpers';

function readFileAsArray(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsArrayBuffer(file);
  });
}

export default class SharedInfoStore {
  constructor(context, dialog, profile) {
    this.context = context;
    this.dialog = dialog;
    this.profile = profile;
  }

  questionaries = [
    /* {
      id: 'STORAGE_FORM',
      title: 'Складские помещения, необходимые для оказания работ/услуг по договору',
      form: {},
      files: [],
      changeTime: 0,
      signed: true,
      isValid: true,
      validTo: 0
    },
    {
      id: 'VEHICLES_FORM',
      title: 'Транспортные средства, необходимые для выполнения оказываемых работ услуг',
      form: {},
      files: [],
      changeTime: 0,
      signed: true,
      isValid: true,
      validTo: 0
    },
    {
      id: 'FACTORY_FORM',
      title: 'Производственные активы, необходимые для выполнения оказываемых работ/услуг',
      form: {},
      files: [],
      changeTime: 0,
      signed: false,
      isValid: false,
      validTo: 0
    },
    {
      id: 'DEALER_FORM',
      title: 'Посредник при реализации строительных материалов/оказании услуг',
      form: {},
      files: [],
      changeTime: 0,
      signed: false,
      isValid: false,
      validTo: 0
    },
    {
      id: 'DEALER_FORM2',
      title: 'Финансово-хозяйственная деятельность на основе заключения договоров с контрагентами-перекупщиками или посредниками',
      form: {},
      files: [],
      changeTime: 0,
      signed: false,
      isValid: false,
      validTo: 0
    },
    {
      id: '_FORM',
      title: 'Анкета 6',
      form: {},
      files: [],
      changeTime: 0,
      signed: false,
      isValid: false,
      validTo: 0
    } */
  ];

  questionariesShadow = [];

  /* questionary_temp = {
    id: 'VEHICLES_FORM',
    title: 'Транспортные средства, необходимые для выполнения оказываемых работ/услуг',
    description:
      'Для подтверждения владением транспортными средствами, необходимо разместить скан ПТС/Договора на покупку/Договор аренды или лизинга/список ТС в табличной форме (Excel/Word или скан, подписанный и с печатью)',
    signed: true,
    relevancePeriod: 35,
    relevanceDate: '2015-07-20',
    elements: [
      {
        id: 'VEHICLE_DOCUMENT',
        title: 'Документ, подтверждающий владение транспортным средством',
        type: 'COMPOSITE',
        minimumQnt: 1,
        maximumQnt: 100,
        elements: [
          {
            id: 'VEHICLE_DOCUMENT_FILE',
            title: 'Документ',
            type: 'FILE',
            allowedMediaTypes: ['image/jpg', 'image/jpeg', 'image/tif', 'application/pdf'],
            minimumQnt: 1,
            maximumQnt: 1,
            maxSize: 10485760,
            fileData: [
              {
                fileName: 'dkp-jiguli.jpg',
                fileType: 'image/jpg',
                fileSize: 43434343,
                fileId: '1234567',
                signed: true,
                saved: false,
              },
            ],
          },
          {
            id: 'VEHICLE_DOCUMENT_QNT',
            title: 'Количество транспортных средств, владение которыми подтверждено в документе',
            type: 'INT',
            minimumQnt: 1,
            maximumQnt: 1,
            min: 1,
            value: [2],
          },
          {
            id: 'VEHICLE_DOCUMENT_COMMENT',
            title: 'Комментарии',
            type: 'TEXT',
            minimumQnt: 1,
            maximumQnt: 1,
            maxSize: 1024,
            lines: 3,
            value: ['Приложил все новенькие тачки'],
          },
          {
            id: 'DOCUMENT_PERSONAL_DATA',
            title:
              'Согласны ли вы передать свои персональные данные для обработки в компании Rescore?',
            type: 'CHECKBOX',
            options: ['Согласен', 'Нет'],
            value: [true],
            minimumQnt: 1,
            maximumQnt: 1,
          },
          {
            id: 'DOCUMENT_LICENSE',
            title: 'Выберите тип лицензии',
            type: 'SELECT',
            value: [''],
            options: ['Простая', 'Сложная', 'Очень сложная'],
            minimumQnt: 1,
            maximumQnt: 1,
          },
          {
            id: 'DOCUMENT_COUNTRY_RESIDENT',
            title: 'Являетесь ли вы резидентом другой страны?',
            type: 'RADIO',
            options: ['Да', 'Нет'],
            value: ['Да'],
            minimumQnt: 1,
            maximumQnt: 1,
          },
          {
            id: 'VEHICLE_DOCUMENT_VEHICLE_PHOTO',
            title: 'Фотографии транспортных средств, указанных в документе',
            type: 'FILE',
            allowedMediaTypes: ['image/jpg', 'image/jpeg', 'image/png'],
            minimumQnt: 1,
            maximumQnt: 100,
            maxSize: 5242880,
            fileData: [
              {
                fileName: 'vaz2105_001.jpg',
                fileType: 'image/jpg',
                fileId: '123',
                fileSize: 1231231,
                signed: true,
                saved: false,
              },
              {
                fileName: 'vaz2105_002.jpg',
                fileType: 'image/jpg',
                fileId: '333',
                fileSize: 3231231,
                signed: false,
                saved: false,
              },
            ],
          },
        ],
      },
      {
        id: 'VEHICLE_DOCUMENT',
        title: 'Документ, подтверждающий владение транспортным средством',
        type: 'COMPOSITE',
        minimumQnt: 1,
        maximumQnt: 100,
        elements: [
          {
            id: 'VEHICLE_DOCUMENT_FILE',
            title: 'Документ',
            type: 'FILE',
            allowedMediaTypes: ['image/jpg', 'image/jpeg', 'image/tif', 'application/pdf'],
            minimumQnt: 1,
            maximumQnt: 1,
            maxSize: 10485760,
            fileData: [
              {
                fileName: 'pts-ford1976.jpg',
                fileType: 'image/jpg',
                fileSize: 343233,
                fileId: '12222',
                signed: true,
                saved: false,
              },
            ],
          },
          {
            id: 'VEHICLE_DOCUMENT_QNT',
            title: 'Количество транспортных средств, владение которыми подтверждено в документе',
            type: 'INT',
            minimumQnt: 1,
            maximumQnt: 1,
            min: 1,
            value: [1],
          },
          {
            id: 'VEHICLE_DOCUMENT_COMMENT',
            title: 'Комментарии',
            type: 'TEXT',
            minimumQnt: 1,
            maximumQnt: 1,
            maxSize: 1024,
            lines: 3,
            value: ['А тут - видавший виды Ford 1976 г.в.'],
          },
          {
            id: 'VEHICLE_DOCUMENT_VEHICLE_PHOTO',
            title: 'Фотографии транспортных средств, указанных в документе',
            type: 'FILE',
            allowedMediaTypes: ['image/jpg', 'image/jpeg', 'image/png'],
            minimumQnt: 1,
            maximumQnt: 100,
            maxSize: 5242880,
            fileData: [
              {
                fileName: 'ford1976_001.jpg',
                fileType: 'image/jpg',
                fileId: '123',
                fileSize: 23423423,
                signed: true,
                saved: false,
              },
            ],
          },
        ],
      },
    ],
  }; */

  // questionary = {};

  formId = '';

  formTitle = '';

  isBusy = false;

  editMode = false;

  files = observable.array([]);

  questionary = observable.map({});
  // autorun(() => console.log(observableMap.toJS()));

  /* setDocumentsActs(state) {
    this.acts = [...this.acts, ...state];
  }

  removeDocumentsActs() {
    this.acts = [];
  } */

  setEditMode = state => {
    this.editMode = state;
    // if (state === true) this.publishedViewLock = false;
  };

  publishedViewLock = false;

  setFormId = (id, publishedViewLock = null) => {
    this.formId = id;
    if (publishedViewLock !== null) this.publishedViewLock = publishedViewLock;
    // eslint-disable-next-line
    for (let item of this.questionaries) {
      if (item.id === id) {
        this.questionariesShadow = [item];
        this.formTitle = item.title;
        return;
      }
    }
    this.formTitle = '';
    this.questionariesShadow = this.questionaries;
  };

  /* setFormTitle = title => {
    this.formTitle = title;
  }; */

  getBranch = (questionaryTemp, indexPath) => {
    // const questionaryTemp = /* JSON.parse(JSON.stringify( */ this.questionary.get(this.formId); // ))
    let { elements } = questionaryTemp;

    let branch = elements;
    // eslint-disable-next-line
    for (let i = 0; i < indexPath.length; i++) {
      const index = indexPath[i];
      if (elements[index].type === 'COMPOSITE') {
        elements = elements[index].elements;
        // eslint-disable-next-line
        continue;
      }
      branch = elements[index];
      break;
    }

    return branch;
  };

  editStartedFlag = false;

  editSavedFlag = true;

  changeField = (indexPath, fieldKey, value, valueIndex = null) => {
    const questionaryTemp = /* JSON.parse(JSON.stringify( */ this.questionary.get(this.formId); // ))
    const element = this.getBranch(questionaryTemp, indexPath);

    logger.debug(value);

    if (fieldKey === 'fileData') {
      // eslint-disable-next-line
      for (let fileItem of element.fileData) {
        if (fileItem.fileName === value.fileName && fileItem.fileSize === value.fileSize) return;
      }
    }

    if (valueIndex === null) element[fieldKey] = value;
    else if (valueIndex < 0) element[fieldKey].push(value);
    else if (fieldKey === 'fileData') {
      if (value === 'delete') element[fieldKey].splice(valueIndex, 1);
      else element[fieldKey][valueIndex].selected = value;
    } else element[fieldKey][valueIndex] = value;

    this.questionary.delete(this.formId);
    this.questionary.set(this.formId, /* JSON.parse(JSON.stringify( */ questionaryTemp); // ))
    this.editStartedFlag = true;
    this.editSavedFlag = false;
  };

  addToField = (indexPath, fieldKey, value) => {
    return this.changeField(indexPath, fieldKey, value, -1);
  };

  deleteFiles = indexPath => {
    logger.debug('deleteFiles started');
    const questionaryTemp = /* JSON.parse(JSON.stringify( */ this.questionary.get(this.formId); // ))
    const branch = this.getBranch(questionaryTemp, indexPath);
    logger.debug(branch);
    for (let i = branch.fileData.length - 1; i >= 0; i -= 1) {
      const fileItem = branch.fileData[i];
      if ('selected' in fileItem && fileItem.selected) branch.fileData.splice(i, 1);
    }
    this.questionary.delete(this.formId);
    this.questionary.set(this.formId, /* JSON.parse(JSON.stringify( */ questionaryTemp); // ))
  };

  addComposite = compositeItem => {
    // eslint-disable-next-line
    const id = compositeItem.id;
    const questionaryTemp = /* JSON.parse(JSON.stringify( */ this.questionary.get(this.formId); // ))
    const branch = this.getBranch(questionaryTemp, compositeItem.indexPath.slice(0, -1));

    const newElement = JSON.parse(JSON.stringify(compositeItem));
    // eslint-disable-next-line
    for (const item of newElement.elements) {
      if (item.type === 'FILE') item.fileData = [];
      else item.value = [];
    }
    branch.push(newElement);
    this.paragraphEnumerator(questionaryTemp.elements, []);
    this.compositeBtnUpdate(branch, id);

    this.questionary.delete(this.formId);
    this.questionary.set(this.formId, questionaryTemp);
  };

  deleteComposite = compositeItem => {
    // eslint-disable-next-line
    const id = compositeItem.id;
    const questionaryTemp = /* JSON.parse(JSON.stringify( */ this.questionary.get(this.formId);
    const branch = this.getBranch(questionaryTemp, compositeItem.indexPath.slice(0, -1));

    branch.splice(compositeItem.indexPath.slice(-1), 1);
    this.paragraphEnumerator(questionaryTemp.elements, []);
    this.compositeBtnUpdate(branch, id);

    this.questionary.delete(this.formId);
    this.questionary.set(this.formId, questionaryTemp);
  };

  compositeBtnUpdate = (branch, id) => {
    let num = 0;
    // eslint-disable-next-line
    for (const item of branch) {
      if (item.id === id) {
        num += 1;
        item.canDelBtn = true;
        item.canAddBtn = true;
      }
    }
    // console.log('num', num);
    // eslint-disable-next-line
    for (const item of branch) {
      if (item.id === id) {
        if (num <= 1) item.canDelBtn = false;
        if (num >= item.maximumQnt) item.canAddBtn = false;
      }
    }
    // console.log(branch);
  };

  /*  filesAdd = file => {
    this.files.push(file);
  }; */

  gatherData = () => {
    const data = {};

    /* const deepdiver = items => {
      const collect = {};
      // eslint-disable-next-line
      for (let i = 0; i < items.length; i++) {
        let data2;
        if (items[i].type === 'COMPOSITE') data2 = deepdiver(items[i].elements);
        else if (items[i].type === 'FILE') {
          data2 = [];
          // eslint-disable-next-line
          for (const item of items[i].fileData) {
            const file = {
              fileId: item.fileId,
              fileName: item.fileName,
              fileType: item.fileType,
              fileSize: item.fileSize,
              fileHash: item.fileHash,
            };
            data2.push(file);
          }
        } else data2 = items[i].value;
        collect[items[i].id] = data2;
      }
      return collect;
    }; */
    const deepdiver = items => {
      const collect = [];
      // eslint-disable-next-line
      for (let i = 0; i < items.length; i++) {
        let data2;
        if (items[i].type === 'COMPOSITE') data2 = deepdiver(items[i].elements);
        else if (items[i].type === 'FILE') {
          data2 = [];
          // eslint-disable-next-line
          for (const item of items[i].fileData) {
            const file = {
              fileId: item.fileId,
              fileName: item.fileName,
              fileType: item.fileType,
              fileSize: item.fileSize,
              fileHash: item.fileHash,
            };
            data2.push(file);
          }
        } else data2 = items[i].value;
        collect.push({
          id: items[i].id,
          title: items[i].title,
          value: data2,
        });
      }
      return collect;
    };
    data[this.formId /* this.questionary.get(this.formId).id */] = deepdiver(
      this.questionary.get(this.formId).elements,
    );

    logger.debug('COLLECT DATA: ', data);
    return data;
  };

  gatherFiles = async (toggleProgressPopup, progressCallback, statusCallback = () => {}) => {
    this.files.clear();

    const deepdiver = (items, list) => {
      // eslint-disable-next-line
      for (let i = 0; i < items.length; i++) {
        // if (items[i].type === 'COMPOSITE') deepdiver(items[i].elements);
        if ('elements' in items[i]) deepdiver(items[i].elements, list);
        if ('fileData' in items[i]) {
          // eslint-disable-next-line
          for (const item of items[i].fileData) {
            list.push(item);
          }
        }
      }
    };
    // deepdiver(this.questionary.get(this.formId).elements);
    const formFiles = [];
    deepdiver(this.publishData.elements, this.files);
    deepdiver(this.questionary.get(this.formId).elements, formFiles);

    if (formFiles.length) {
      progressCallback(0);
      statusCallback('');
      toggleProgressPopup();
      for (let i = 0; i < formFiles.length; i += 1) {
        statusCallback(`Загрузка файла ${formFiles[i].fileName}`);
        if ('file' in formFiles[i]) {
          logger.debug('Read file ', formFiles[i].file.name);
          // eslint-disable-next-line
          this.files[i].fileData = new Blob([await readFileAsArray(formFiles[i].file)], {
            type: formFiles[i].file.type,
          });
        } else {
          // eslint-disable-next-line
          this.files[i].fileData = await api.disclosure.downloadFiles(
            formFiles[i].fileId,
            getToken(),
          );
        }
        progressCallback((100 / formFiles.length) * i);
      }
      toggleProgressPopup();
    }

    // const questionaryJson = JSON.stringify(this.gatherData());
    const questionaryJson = JSON.stringify(this.publishData);

    this.files.unshift({
      fileId: this.formId,
      fileName: `Форма "${this.questionary.get(this.formId).title}"`,
      fileType: 'application/json',
      fileSize: questionaryJson.length,
      fileData: questionaryJson,
      signed: false,
    });

    logger.debug('COLLECT FILES FROM PUBLISHING DATA: ', this.files);
    // return this.files;
  };

  getGatherFiles = () => {
    return this.files;
  };

  gatherNewFiles = async (statusCallback = () => {}) => {
    // this.files.clear() // = []
    const files = [];
    let fileIndex = -1;

    const deepdiver = async items => {
      // eslint-disable-next-line
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        if (items[i].type === 'COMPOSITE') await deepdiver(items[i].elements);
        if (items[i].type === 'FILE') {
          // eslint-disable-next-line
          for (const item of items[i].fileData) {
            /* if ('file' in item && 'saved' in item.file && item.file.saved == true) {
              item.fileId = '';
            } */
            if ('file' in item && !('saved' in item.file)) {
              statusCallback(`Обработка файла ${item.file.name}`);
              // eslint-disable-next-line
              // const buffer = await readFileAsArray(item.file);
              // item.fileHash = sha256.hex(buffer);
              item.file.saved = false;
              item.fileId = fileIndex;
              item.file.id = fileIndex;
              fileIndex -= 1;
              files.push(item.file);
            }
          }
        }
      }
    };
    await deepdiver(this.questionary.get(this.formId).elements);

    logger.debug('COLLECT NEW FILES: ', files);
    return files;
  };

  /* addFile = (file, indexPath) => {
    //console.log('ADD FILE')
    let t_questionary = JSON.parse(JSON.stringify(this.questionary[this.formId]))
    let elements = t_questionary.elements
    //let elements = this.questionary.elements
    let element
    for (let index of indexPath) {
      if (elements[index].type === 'COMPOSITE') {
        elements = elements[index].elements
        continue
      }
      element = elements[index]
      break
    }

    element.fileData.push({
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
      fileId: '',
      signed: false
    })

    this.questionary[this.formId] = JSON.parse(JSON.stringify(t_questionary))
    //console.log(this.questionary, element)
  } */

  fetchQuestionaries = async () => {
    this.isBusy = true;
    let response;
    try {
      response = await api.disclosure.getQuestionaries(getToken());
      logger.info(response);
    } catch (ex) {
      logger.error(ex);
    } finally {
      this.isBusy = false;
    }
    this.questionaries = response;
    return response || undefined;
  };

  clearQuestionaries = () => {
    this.questionaries = [];
  };

  clearRecurseFunc = (items, branchCallback = () => {}) => {
    // eslint-disable-next-line
    for (let i = 0; i < items.length; i++) {
      // eslint-disable-next-line
      if ('value' in items[i]) items[i].value[0] = '';
      // eslint-disable-next-line
      if ('fileData' in items[i]) items[i].fileData = [];
      if (items[i].type === 'COMPOSITE') {
        // branchCallback(items, items[i].id);
        this.clearRecurseFunc(items[i].elements, branchCallback);
      }
    }
  };

  clearQuestionary = () => {
    const questionaryTemp = /* JSON.parse(JSON.stringify( */ this.questionary.get(this.formId);
    this.clearRecurseFunc(questionaryTemp.elements); // , this.compositeBtnUpdate);
    logger.debug(questionaryTemp);
    this.questionary.delete(this.formId);
    this.questionary.set(this.formId, questionaryTemp);
  };

  paragraphEnumerator = (items, path, branchCallback = () => {}) => {
    // eslint-disable-next-line
    for (let i = 0; i < items.length; i++) {
      const indexPath = [...path, i];
      // eslint-disable-next-line
      items[i].index = i;
      // eslint-disable-next-line
      items[i].indexPath = indexPath;
      if (items[i].type === 'COMPOSITE') {
        branchCallback(items, items[i].id);
        this.paragraphEnumerator(items[i].elements, indexPath, branchCallback);
      }
      if (items[i].type === 'DATE') {
        if (typeof items[i].value === 'object') {
          if (items[i].value.length === 0 || items[i].value[0].length === 0) {
            // eslint-disable-next-line
            items[i].value[0] = '';
          } else {
            // eslint-disable-next-line
            items[i].value[0] = new Date(items[i].value[0]);
          }
        } else {
          // eslint-disable-next-line
          items[i].value = [];
          // eslint-disable-next-line
          items[i].value[0] = '';
        }
      }
    }
  };

  fetchQuestionary = async () => {
    // this.questionary[this.formId] = JSON.parse(JSON.stringify(this.questionary_temp))
    // this.questionary.set(this.formId, JSON.parse(JSON.stringify(this.questionary_temp)))
    this.questionary.clear();

    this.isBusy = true;
    let response;
    try {
      response = await api.disclosure.getQuestionary(
        this.formId,
        getToken(),
        this.publishedViewLock,
      );
      logger.info(response);
      this.setEditMode(!response.isPublic);
      this.paragraphEnumerator(response.elements, [], this.compositeBtnUpdate);
      this.questionary.set(this.formId, response);
      this.editStartedFlag = !response.isPublic && this.validateFunc().length === 0; // &&  response.hasPublic;
      this.editSavedFlag = true;
    } catch (ex) {
      logger.error(ex);
      return false;
    } finally {
      this.isBusy = false;
    }
    // / this.questionary = response
    // / this.questionary.set(this.formId, response)
    // const response = JSON.parse(JSON.stringify(this.questionary_temp));

    // console.log('this.formId:', this.formId)
    // console.log(this.questionary)
    /// this.paragraphEnumerator(response.elements, [], this.compositeBtnUpdate);
    // this.compositeBtnUpdate(branch, id);

    /// this.questionary.set(this.formId, response);

    logger.debug(this.questionary.get(this.formId));
    return true;
  };

  publishData = null;

  paragraphString = path => {
    let parnum = '';
    // eslint-disable-next-line
    for (let index = 0; index < path.length; index++) {
      const item = path[index];
      parnum += item + 1 + (index < path.length - 1 ? '.' : '');
    }
    return parnum;
  };

  validateFunc = () => {
    // let err = false;
    let fields = '';
    const deepdiver = items => {
      // eslint-disable-next-line
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line
        if (items[i].type === 'COMPOSITE') deepdiver(items[i].elements);
        else if (
          (items[i].type === 'FILE' && items[i].fileData.length < items[i].minimumQnt) ||
          ((items[i].type === 'INT' ||
            items[i].type === 'DATE' ||
            items[i].type === 'TEXT' ||
            /* items[i].type === 'CHECKBOX' || */
            items[i].type === 'RADIO' ||
            items[i].type === 'SELECT') &&
            (items[i].value.length < items[i].minimumQnt ||
              (items[i].minimumQnt > 0 &&
                items[i].value.length > 0 &&
                items[i].value[0].trim().length === 0)))
        ) {
          // err = true;
          fields += `${fields.length ? ',' : ''} ${this.paragraphString(items[i].indexPath)}`;
        }
      }
    };
    deepdiver(this.questionary.get(this.formId).elements);
    return fields;
  };

  validateQuestionary = () => {
    const fields = this.validateFunc();
    if (fields.length !== 0) {
      this.dialog.alert(`Обязательные поля не заполнены: ${fields}`);
    }
    return fields.length === 0;
  };

  saveQuestionary = async (toggleProgressPopup, progressCallback, statusCallback = () => {}) => {
    let files = [];
    this.isBusy = true;
    let response;
    try {
      progressCallback(0);
      statusCallback('');
      toggleProgressPopup();
      files = await this.gatherNewFiles(statusCallback);
      const data = this.gatherData();
      statusCallback('Сохранение документов');
      response = await api.disclosure.sendData(this.formId, data, files, getToken(), percent => {
        progressCallback(percent);
      });

      this.publishData = response;

      const questionaryTemp = this.questionary.get(this.formId);
      questionaryTemp.isPublic = false;
      this.questionary.set(this.formId, questionaryTemp);

      /* if (this.files.length !== 0) {
        this.files[0].fileSize = response.length;
        this.files[0].fileData = response;
      } */

      this.editSavedFlag = true;
      await this.fetchQuestionary();

      logger.info('Data for sign: ', response);
      // eslint-disable-next-line
      for (const file of files) {
        file.saved = true;
      }
    } catch (ex) {
      logger.error(ex);
      return false;
    } finally {
      this.isBusy = false;
      toggleProgressPopup();
    }
    return true;
  };

  deleteQuestionary = async () => {
    try {
      const response = await api.disclosure.deleteQuestionary(this.formId, getToken());
      logger.debug(response);
    } catch (err) {
      logger.error(err);
    }
  };

  checkIsSaved = async () => {
    logger.debug('If document not saved, open warning dialog');
    if (this.editSavedFlag) return true;
    const ret = await this.dialog.show('Документ не сохранен! Уверены, что хотите выйти?', {
      acceptDangerColor: true,
      acceptText: 'Да',
      cancelText: 'Нет',
    });
    return ret;
  };

  saveSignatures = async (
    thumbprint,
    toggleProgressPopup,
    progressCallback,
    statusCallback = () => {},
  ) => {
    // logger.debug('THUMBPRINT: ', thumbprint);
    const signFiles = [];
    this.isBusy = true;
    let response;
    try {
      progressCallback(0);
      statusCallback('');
      toggleProgressPopup();

      // eslint-disable-next-line
      for (let i = 0; i < this.files.length; i += 1) {
        const file = this.files[i];
        // eslint-disable-next-line
        if (file.fileType === 'application/json') continue;

        // eslint-disable-next-line
        const filename = file.fileName + '.sig';

        if (!('fileData' in file)) {
          statusCallback(`Загрузка файла ${file.fileName}`);
          // eslint-disable-next-line
          file.fileData = await api.disclosure.downloadFiles(file.fileId, getToken());
        }

        logger.debug('File: ', filename);
        logger.debug('Type: ', file.fileType);
        logger.debug('file: ', file.fileData);

        const buffer =
          // eslint-disable-next-line
          file.fileType === 'application/json' ? file.fileData : await file.fileData.arrayBuffer();
        logger.debug('buffer: ', buffer);

        statusCallback(`Подписывание файла ${file.fileName}`);
        // eslint-disable-next-line
        const hash = await createHash(buffer /* ? file.fileData : 'TEST' */);
        file.fileHash = hash;
        logger.debug('HASH: ', hash);
        // eslint-disable-next-line
        const sign = await createDetachedSignature(thumbprint, hash);
        logger.debug('SIGN: ', sign);
        progressCallback((100 / this.files.length) * i);
        // let buffer = new ArrayBuffer(file.fileData.length * 2);
        // let view   = new Int32Array(buffer);
        const blob = new Blob(
          /* file.fileType === 'application/json' ? [file.fileData] : */ [sign],
          { type: /* file.fileType */ 'text/plain' },
        );
        blob.id = file.fileId === this.formId ? 'sign' : file.fileId;
        blob.name = filename;
        signFiles.push(blob);
      }

      // записать хэши в publishData
      const deepdiver = items => {
        // eslint-disable-next-line
        for (let i = 0; i < items.length; i += 1) {
          // eslint-disable-next-line
          if ('elements' in items[i]) deepdiver(items[i].elements);
          if ('fileData' in items[i]) {
            // eslint-disable-next-line
            for (const item of items[i].fileData) {
              for (let o = 0; o < this.files.length; o += 1) {
                const file = this.files[o];

                if (item.fileName === file.fileName && item.fileSize === file.fileSize) {
                  // logger.debug(item, file);
                  item.fileHash = file.fileHash;
                }
              }
            }
          }
        }
      };
      deepdiver(this.publishData.elements);
      // logger.debug(this.publishData.elements);

      // Превращаем publishData в строку для дайнейшего подписания и выгрузки
      const dataString = JSON.stringify(this.publishData);
      const datablob = new Blob([dataString], { type: 'text/plain' });
      datablob.id = 'data';
      datablob.name = this.formId;

      // расчитать подпись для publishData
      statusCallback('Подписывание формы');
      const hash = await createHash(await datablob.arrayBuffer());
      const sign = await createDetachedSignature(thumbprint, hash);
      const blob = new Blob([sign], { type: 'text/plain' });
      blob.id = 'sign';
      // eslint-disable-next-line
      blob.name = this.formId + '.sig';
      signFiles.unshift(blob);
      signFiles.unshift(datablob);

      statusCallback('Сохранение подписей, публикация');
      response = await api.disclosure.sendSignFiles(
        this.formId,
        null,
        signFiles,
        getToken(),
        percent => {
          progressCallback(percent);
        },
      );
      logger.info(response);

      this.dialog.show('Форма опубликована успешно', {
        persistent: true,
        acceptText: 'Ок',
        accept: () => window.location.reload(),
      });
    } catch (err) {
      logger.debug(err);
      if (err.message === 'Указан неправильный алгоритм') {
        this.dialog.alert('Сертификат не подходит');
      }
    } finally {
      this.isBusy = false;
      toggleProgressPopup();
    }
  };

  /* sharedList = [
    {
      customer: {
        inn: '0123456567',
        ogrn: '',
        name: 'ЗАО «Рога и копыта»',
      },
      request: null,
      access: ['COSTS_FORM'],
    },
    {
      customer: {
        inn: '1456789345',
        ogrn: '',
        name: 'ООО «Заготовительный комбинат № 6»',
      },
      request: null,
      access: ['VEHICLES_FORM', 'RESELLER_FORM'],
    },
    {
      customer: {
        inn: '2675427897',
        ogrn: '',
        name: 'АО «Молоко и Мясо»',
      },
      request: ['VEHICLES_FORM', 'PRICE_DEVIATION_FORM', 'COMPANY_ROLE_FORM'],
      access: null,
    },
  ]; */

  accessList = [];

  findedOrganization = {};

  findOrganization = async innOrOgrn => {
    try {
      this.dialog.loader(true);
      this.findedOrganization = {};
      const finded = await api.disclosure.getOrganization(innOrOgrn, getToken());
      if (finded === null) {
        this.dialog.show('Организация не найдена', {
          acceptText: 'Ок',
        });
      } else {
        this.findedOrganization = finded;
        logger.debug('=>', this.findedOrganization);
      }
    } catch (err) {
      logger.error(err.toString());
      this.findedOrganization = {};
      /* this.dialog.show(err.toString(), {
        acceptDangerColor: true,
        acceptText: 'Ок',
      }); */
    } finally {
      this.dialog.loader(false);
    }
    return this.findedOrganization;
  };

  clearOrganization = () => {
    this.findedOrganization = {};
  };

  fetchAccess = async () => {
    try {
      this.dialog.loader(true);
      this.accessList = await api.disclosure.fetchAccessList(this.formId, getToken());
      // eslint-disable-next-line
      for (let item of this.accessList) {
        item.changed = false;
        item.disable = false;
        if (item.accesses.length === 0) item.accesses = null;
        if (item.requests.length === 0) item.requests = null;
      }
      logger.debug('=>', this.accessList);
      return this.accessList;
    } catch (err) {
      logger.error(err);
      /* this.dialog.show(err.toString(), {
        acceptDangerColor: true,
        acceptText: 'Ок',
      }); */
    } finally {
      this.dialog.loader(false);

      /* const ret = await this.dialog.show('Test', {
        model: [
          { type: 'LABEL', label: 'ИНН: 12345678' },
          { type: 'LABEL', label: 'КПП: 098765432' },
          { type: 'LABEL', label: 'ОГРН: 75938573957' },
          { type: 'CHECKBOX', label: 'чекбокс 1', name: 'checkBox1' },
          { type: 'CHECKBOX', label: 'чекбокс 2' },
          { type: 'CHECKBOX', label: 'чекбокс 3' },
          { type: 'TEXT', label: 'Введите адрес' },
          { type: 'TEXT', label: 'Введите сообщение', lines: 10 },
        ],
        buttons: [
          { label: 'Удалить', color: 'WARNING', action: () => {} },
          { label: 'Назад', event: 'CANCEL3', action: () => {} },
        ],
        acceptText: 'Да',
        cancelText: 'Нет',
      });
      logger.debug(ret);
      logger.debug(this.dialog.getResultObj());
      logger.debug(this.dialog.getResultArr());
      logger.debug(this.dialog.getSelectedNameArr()); */
    }
    return [];
  };

  setAccess = async company => {
    logger.debug('set access', company);
    if (company.accesses.length === 0) {
      await this.delAccess(company);
    } else {
      try {
        await api.disclosure.saveAccess(
          company.customer.ogrn,
          company.customer.inn,
          company.accesses,
          getToken(),
        );
      } catch (err) {
        logger.error(err);
        /* this.dialog.show(err.toString(), {
          acceptDangerColor: true,
          acceptText: 'Ок',
        }); */
      }
    }
  };

  delAccess = async company => {
    logger.debug('delete access', company);
    try {
      if (
        await this.dialog.show('Удалить организацию из доступа ?', {
          persistent: true,
          acceptDangerColor: true,
          acceptText: 'Да',
          cancelText: 'Нет',
        })
      ) {
        await api.disclosure.deleteAccess(company.customer.ogrn, company.customer.inn, getToken());
      }
    } catch (err) {
      logger.error(err);
      /* this.dialog.show(err.toString(), {
        acceptDangerColor: true,
        acceptText: 'Ок',
      }); */
    }
  };

  selectForms = async (array, type) => {
    const model = [];
    let index = 0;
    // eslint-disable-next-line
    for (const item of this.questionaries) {
      index += 1;
      model.push({
        type: 'CHECKBOX',
        label: `${index} - ${item.title}`,
        name: item.id,
        value: array.indexOf(item.id) > -1,
      });
    }
    const toggleInitValue = this.questionaries.length === array.length ? 1 : 0;
    const buttons = [
      {
        width: '25%',
        label: ['Выделить все', 'Снять все'],
        toggle: toggleInitValue,
        color: 'CANCEL',
        action: [self => self.setAll('CHECKBOX', true), self => self.setAll('CHECKBOX', false)],
      },
    ];
    if (type === 'finded') {
      buttons.push({ width: '25%', label: 'Предоставить', event: 'ACCEPT' });
    }
    if (type === 'requests') {
      buttons.push({ width: '25%', label: 'Предоставить', event: 'ACCEPT' });
      buttons.push({ width: '25%', label: 'Отклонить', event: 'REJECT', color: 'WARNING' });
    }
    if (type === 'accesses') {
      buttons.push({ width: '25%', label: 'Применить', event: 'APPLY' });
    }
    buttons.push({ width: '25%', label: 'Назад', event: 'CANCEL', color: 'CANCEL' });
    const result = await this.dialog.show('Выберите предоставляемые формы', {
      width: '800px',
      model,
      buttons,
    });
    return {
      result,
      array: this.dialog.getSelectedNameArr(),
    };
  };

  cryptoReady = false;

  cryptoIsPluginInstalled = false;

  cryptoIsProviderInstalled = false;

  cryptoGetInfo = async () => {
    this.cryptoIsPluginInstalled = false;
    this.cryptoIsProviderInstalled = false;
    this.cryptoReady = false;
    try {
      logger.debug('getSystemInfo');
      logger.debug('=> ', await getSystemInfo());
      logger.debug('isValidSystemSetup');
      logger.debug('=> ', await isValidSystemSetup());
      this.cryptoIsPluginInstalled = true;
      this.cryptoIsProviderInstalled = true;
      this.cryptoReady = true;
      return true;
    } catch (err) {
      logger.debug('CRYPTO: ', typeof err, err);
      // { message: "Тип поставщика не определен. (0x80090017)", requestid: 6, type: "error" }
      if ('requestid' in err) this.cryptoIsPluginInstalled = true;
    }
    // this.cryptoIsProviderInstalled = false;
    this.cryptoReady = true;
    return false;
  };

  cryptoCertificates = [];

  cryptoGetCertificates = async () => {
    try {
      // eslint-disable-next-line
      const customer = this.profile.userData.customer;
      logger.debug(customer);

      const certificates = await getUserCertificates();
      logger.debug(this.cryptoCertificates);
      const regexpName = /(CN)=(.*$)/;
      const regexpInn = /(ИНН|INN)=(.*$)/;
      const regexpOgrn = /(ОГРН|OGRN)=(.*$)/;
      // eslint-disable-next-line
      for (let item of certificates) {
        // logger.debug(item.issuerName.match(regexp)[0]);
        // logger.debug(item.subjectName.match(regexp)[0]);
        item.organization = {};
        try {
          // eslint-disable-next-line
          item.issuerNamePretty = item.issuerName.match(regexpName)[2];
          // eslint-disable-next-line
          item.subjectNamePretty = item.subjectName.match(regexpName)[2];
          // eslint-disable-next-line
          item.organization.inn = Number(item.subjectName.match(regexpInn)[2]);
          // eslint-disable-next-line
          item.organization.ogrn = Number(item.subjectName.match(regexpOgrn)[2]);

          const customerInn = Number(customer.inn);
          const customerOgrn = Number(customer.ogrn);

          item.organization.valid =
            customerInn === item.organization.inn && customerOgrn === item.organization.ogrn;
        } catch (err) {
          logger.error(err.toString());
          if (!('issuerNamePretty' in item)) item.issuerNamePretty = 'error';
          if (!('subjectNamePretty' in item)) item.subjectNamePretty = 'error';
          item.organization.inn = null;
          item.organization.ogrn = null;
          item.organization.valid = false;
        }
      }
      // logger.debug(certificates);
      this.cryptoCertificates = certificates;
      logger.debug(this.cryptoCertificates);
    } catch (error) {
      logger.error('getUserCertificates error: ', error);
    }
  };

  accessRequestCount = 0;

  fetchAccessRequestCount = async () => {
    this.accessRequestCount = (await api.disclosure.fetchAccessRequestCount(
      getToken(),
    )).requestCount;
  };

  companyItem = {};

  companyFieldName = '';

  setCompanyItem = companyItem => {
    this.companyItem = companyItem;
  };

  setCompanyFieldName = companyFieldName => {
    this.companyFieldName = companyFieldName;
  };
}

decorate(SharedInfoStore, {
  // progress: observable,
  questionaries: observable,
  questionariesShadow: observable,
  questionary: observable,
  formId: observable,
  formTitle: observable,
  publishedViewLock: observable,
  editMode: observable,
  setEditMode: action,
  setFormId: action,
  // setFormTitle: action,
  editStartedFlag: observable,
  editSavedFlag: observable,
  checkIsSaved: action,
  changeField: action,
  addToField: action,
  // addFile: action,
  fetchQuestionaries: action,
  fetchQuestionary: action,
  clearQuestionary: action,
  gatherFiles: action,
  getGatherFiles: action,
  saveQuestionary: action,
  validateQuestionary: action,
  deleteQuestionary: action,
  saveSignatures: action,

  addComposite: action,
  deleteComposite: action,
  deleteFiles: action,
  fetchAccess: action,
  // getAccessList: action,
  setAccess: action,
  delAccess: action,
  findedOrganization: observable,
  findOrganization: action,
  clearOrganization: action,
  selectForms: action,

  cryptoReady: observable,
  cryptoIsPluginInstalled: observable,
  cryptoIsProviderInstalled: observable,
  cryptoGetInfo: action,
  cryptoCertificates: observable,
  cryptoGetCertificates: action,

  companyItem: observable,
  companyFieldName: observable,
  setCompanyItem: action,
  setCompanyFieldName: action,
});
