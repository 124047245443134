import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLOR_LINK_BLACK } from '../../constants/colors';

export const Button = styled.button`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${COLOR_LINK_BLACK};
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const TextButton = ({ onClick, children, text, testID }) => (
  <Button onClick={onClick} data-test-id={testID}>
    {text || children}
  </Button>
);

TextButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  testID: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

TextButton.defaultProps = {
  text: undefined,
  children: undefined,
  testID: undefined,
  onClick: () => {},
};

export default TextButton;
