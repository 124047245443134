import React, { Suspense } from 'react';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import history from './history';
import { AUTH_URL, STATUS_POPUP_URL } from './constants/routes';
import { SUPER_ADMIN_ROLE, CUSTOMER_USER } from './constants/roles';
import PrivateRoute from './components/PrivateRoute';
import { DefaultLayout, MainLayout } from './layouts';
import RescoreResponse from './pages/RescoreResponse/RescoreResponse';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Registration from './pages/Registration/Registration';
import Profile from './pages/Profile/Profile';
import Customers from './pages/Customers/Customers';
import SuperAccounts from './pages/SuperAccounts';
import Users from './pages/Users/Users';
import Inquiries from './pages/Inquiries/Inquiries';
import Dashboard from './pages/Dashboard/Dashboard';
import Balance from './pages/Balance/Balance';
import Accounts from './pages/Accounts/Accounts';
import SharedInfo from './pages/SharedInfo/SharedInfo';
import Services from './pages/Services/Services';
import Documents from './pages/Documents/Documents';
import { StatusPage } from './pages/Status';
import { ChangePassword } from './pages/ChangePassword';
import { RecoveryPage } from './pages/Recovery';
import { hasDisclosure } from './constants/env';

const withLayout = (Layout, Page, props) => (
  <Layout {...props}>
    <Page />
  </Layout>
);

const withSimpleLayout = (Page, layoutProps = {}) => props =>
  withLayout(DefaultLayout, Page, { layoutProps, ...props });

const withMainLayout = (Page, layoutProps = {}) => props =>
  withLayout(MainLayout, Page, { layoutProps, ...props });

const Routes = () => (
  <Router history={history}>
    <Suspense fallback="loading">
      <Switch>
        <Route path="/sbr" exact component={withSimpleLayout(Home)} />
        <Route path="/result" component={withSimpleLayout(RescoreResponse)} />
        <PrivateRoute path={AUTH_URL} isForUnauth component={withSimpleLayout(Login)} />
        <PrivateRoute path="/signup" isForUnauth component={withSimpleLayout(Registration)} />
        <PrivateRoute path="/users" component={withMainLayout(Users)} />
        <PrivateRoute path="/profile" component={withMainLayout(Profile)} />
        <PrivateRoute
          path="/requests"
          allowedRoles={[CUSTOMER_USER]}
          userRoles={[CUSTOMER_USER]}
          component={withMainLayout(Inquiries)}
        />
        <PrivateRoute path="/dashboard/:id" component={withMainLayout(Dashboard)} />
        <PrivateRoute path="/balance" component={withMainLayout(Balance)} />
        <PrivateRoute path="/accounts" component={withMainLayout(Accounts)} />
        {hasDisclosure && (
          <PrivateRoute path="/disclosure" component={withMainLayout(SharedInfo)} />
        )}
        <PrivateRoute path="/services" component={withMainLayout(Services)} />
        <PrivateRoute path="/documents" component={withMainLayout(Documents)} />
        <PrivateRoute
          path="/admin"
          allowedRoles={[SUPER_ADMIN_ROLE]}
          userRoles={[SUPER_ADMIN_ROLE]}
          component={withMainLayout(Profile)}
        />
        <PrivateRoute
          path="/companies"
          allowedRoles={[SUPER_ADMIN_ROLE]}
          userRoles={[SUPER_ADMIN_ROLE]}
          component={withMainLayout(Customers)}
        />
        <PrivateRoute
          path="/superaccounts"
          allowedRoles={[SUPER_ADMIN_ROLE]}
          userRoles={[SUPER_ADMIN_ROLE]}
          component={withMainLayout(SuperAccounts)}
        />
        <Route
          path={STATUS_POPUP_URL}
          component={withSimpleLayout(StatusPage, {
            contentVerticalAlign: 'center',
            mainBackgroundColor: '#e5e5e5',
            contentHorizontalAlign: 'center',
          })}
        />
        <Route
          path="/change"
          component={withSimpleLayout(ChangePassword, {
            contentVerticalAlign: 'center',
            mainBackgroundColor: '#e5e5e5',
            contentHorizontalAlign: 'center',
          })}
        />
        <Route
          path="/recovery"
          component={withSimpleLayout(RecoveryPage, {
            contentVerticalAlign: 'center',
            mainBackgroundColor: '#e5e5e5',
            contentHorizontalAlign: 'center',
          })}
        />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  </Router>
);

export default Routes;
